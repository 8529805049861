import {AppFile} from '../models';
import {AppAPI, asIs, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {BasicQueryException} from './exceptions';
import {Resource} from './Resource';

export interface SigntatureRequest {
  objectName?: string;
  fileName?: string;
  contentType?: string;
}

export async function getUploadSignature({
  objectName,
  fileName,
  contentType,
  apiBase,
  api,
}: SigntatureRequest & {apiBase: string; api: AppAPI}): Promise<string> {
  const url = new URL(`${apiBase}/signature`);
  url.searchParams.append('objectName', objectName || '');
  url.searchParams.append('fileName', fileName || '');
  url.searchParams.append('contentType', contentType || '');
  try {
    return api
      .fetch(new Request(url.toString()), {
        headers: {
          Authorization: `Bearer ${await api.access_token}`,
        },
      })
      .then(response => response.json());
  } catch (error) {
    throw new BasicQueryException(
      `Something went wrong trying to get a signature to upload a file: ${error}`,
    );
  }
}

class FileAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'files');
  }

  @requiresAuth
  async getUploadSignature({
    objectName,
    fileName,
    contentType,
  }: SigntatureRequest): Promise<string> {
    const apiBase = await this.apiBase;
    return getUploadSignature({
      objectName,
      fileName,
      contentType,
      apiBase,
      api: this.api,
    });
  }

  @requiresId
  @requiresAuth
  getPreview(): Promise<{filename: string; key: string; signedUrl: string}> {
    return this.do<{filename: string; key: string; signedUrl: string}>(
      'get',
      `/${this.id}/preview`,
      null,
      error => `Something went wrong trying to get preview of File: ${error}`,
    ).then(asIs);
  }

  /**
   * Get download details for a file
   * @returns {Promise.<*>}
   */
  @requiresId
  @requiresAuth
  getDownload(): Promise<{filename: string; key: string; signedUrl: string}> {
    return this.do<{filename: string; key: string; signedUrl: string}>(
      'get',
      `/${this.id}/download`,
      null,
      error =>
        `Something went wrong trying to get download error for File: ${error}`,
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  setComment(comment: string): Promise<AppFile> {
    return this.do<AppFile>('patch', `/${this.id}`, {comment}).then(
      single(AppFile.fromJSON),
    );
  }

  @requiresId
  @requiresAuth
  delete(): Promise<AppFile> {
    return this.do<void>('delete', `/${this.id}`).then(asIs);
  }
}

export default FileAPI;
