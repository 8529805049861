import {AppAPI, coll, single} from './AppAPI';
import {requiresAuth} from './decorators';
import {
  ApplicationException,
  HttpResponseException,
  ClientConfigurationCreationException,
  ResourceConflictException,
} from './exceptions';
import {Resource} from './Resource';
import {ClientConfiguration, $NewClientConfiguration} from '@src/models';

class ClientConfigurationAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'clientConfigurations');
  }

  @requiresAuth
  getForCallPool(callPoolId: number) {
    return this.do<ClientConfiguration[]>(
      'get',
      `/callPool/${callPoolId}`,
      null,
      `Something went wrong trying to get all ClientConfigurations for Call Pool with id ${callPoolId}`,
    ).then(coll(ClientConfiguration.fromJSON));
  }

  /**
   * Create an `ClientConfiguration`
   * @param data The `ClientConfiguration` data
   * @returns {Promise.<*>} Promise that resolves to the newly created `ClientConfiguration`
   */
  @requiresAuth
  create(data: $NewClientConfiguration): Promise<ClientConfiguration> {
    return this.do<ClientConfiguration>('post', '/', data)
      .then(single(ClientConfiguration.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new ClientConfigurationCreationException(
            `Something went wrong trying to create a Client Configuration: ${error.message}`,
            error.details,
          );
        }

        if (error instanceof ResourceConflictException) {
          throw new ResourceConflictException(
            'The Customer Code already exists',
          );
        }

        throw new ApplicationException(error);
      });
  }
}

export default ClientConfigurationAPI;
