import {Room} from 'twilio-video';
import {
  muteLocalParticipant,
  unmuteLocalParticipant,
  enableLocalParticipantVideo,
  disableLocalParticipantVideo,
  isAudioMuted,
  detectIsVideoDisabled,
  Track,
} from '@src/util/videoChat';
import {isNothing} from '@src/util/typeTests';

type SyncComponentTrackStateWithTwilioProps = {
  setComponentTrackState: (v: boolean) => unknown;
  componentTrackState: boolean;
  track: Track;
};

// Remote participants need to look at Twilio tracks to sync the ParticipantVideo
// isMuted/isVideoDisabled state with Twilio.

// Local participant needs to do the opposite:
// they need to look at their ParticipantVideo component's isMuted/isVideoDisabled state to
// sync the Twilio tracks so the mute/disabled selections are stored in the case where
// they are the only one left in the room and someone comes back. When streaming resumes,
// then their selections will remain the same.

export const syncComponentTrackStateWithTwilio = ({
  setComponentTrackState,
  componentTrackState,
  track,
}: SyncComponentTrackStateWithTwilioProps) => {
  if (isNothing(track)) {
    return;
  }

  const type = track.kind;
  let isTwilioTrackDisabled = false;

  if (type === 'audio') {
    isTwilioTrackDisabled = isAudioMuted({audioTrack: track});
  } else {
    isTwilioTrackDisabled = detectIsVideoDisabled({videoTrack: track});
  }

  const needsSync = isTwilioTrackDisabled !== componentTrackState;

  if (needsSync) {
    setComponentTrackState(isTwilioTrackDisabled);
  }
};

type PreventUnnecessaryStreaming = {
  room: Room | null;
};

export const preventUnnecessaryStreaming = ({
  room,
}: PreventUnnecessaryStreaming) => {
  muteLocalParticipant({localParticipant: room?.localParticipant});
  disableLocalParticipantVideo({localParticipant: room?.localParticipant});
};

type SyncTwilioTrackWithComponentState = {
  isMuted: boolean;
  room: Room | null;
  isVideoDisabled: boolean;
};

/**
 * This function looks at the ParticipantVideo's isMuted
 * and isVideoDisabled variables to determine what to do with the Twilio tracks
 * when streaming resumes. This way, when the only remote participant leaves a room
 * and streaming is stopped and then a remote participant re-enters the room and streaming resumes,
 * the local participant's previous settings are restored.
 */

export const syncTwilioTrackWithComponentState = ({
  isMuted,
  room,
  isVideoDisabled,
}: SyncTwilioTrackWithComponentState) => {
  if (!isMuted) {
    unmuteLocalParticipant({localParticipant: room?.localParticipant});
  }

  if (!isVideoDisabled) {
    enableLocalParticipantVideo({localParticipant: room?.localParticipant});
  }
};
