const idempotentMethods = ['options', 'get', 'head', 'put'];
const isIdempotent = (rq?: RequestInit) =>
  idempotentMethods.includes((rq?.method ?? 'get').toLowerCase());

const fetchWithRetry = async (
  input: RequestInfo,
  init?: RequestInit,
  attemptsRemaining: number = 0,
): Promise<Response> => {
  /*
   * Don't remove these awaits. They are necessary for the try/catch to work
   */
  try {
    return await fetch(input, init);
  } catch (err) {
    if (attemptsRemaining === 0) {
      throw err;
    }
    return await fetchWithRetry(input, init, attemptsRemaining - 1);
  }
};

export const getFetch = (
  setHeaders: (headers: Headers) => Promise<Headers>,
): typeof fetch => async (input: RequestInfo, init?: RequestInit) => {
  let headers = init ? new Headers(init.headers) : new Headers();

  headers = await setHeaders(headers);

  return fetchWithRetry(input, {...init, headers}, isIdempotent(init) ? 2 : 0);
};
