import {getEndpointUrl} from './endpoints';
import {SimpleVisit} from '@src/models';

export const getVisitUrl = (
  visit: SimpleVisit,
  hairChecked: boolean,
  canConnect: boolean = true,
) => {
  const {pathname} = window.location;
  const {id} = visit;

  if (getEndpointUrl(visit).isPresent()) {
    return `/external-visit/${visit.id}?return=${pathname}`;
  } else {
    return `/${
      !hairChecked && canConnect ? 'waiting' : 'exam'
    }-room/${id}?return=${pathname}`;
  }
};
