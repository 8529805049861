import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    marginTop: '2em',
    marginBottom: '3em',
    paddingRight: '1em',
    paddingLeft: '1rem',
    color: theme.palette.secondary.main,
  },
  gridItem: {
    marginBottom: 0,
  },
}));
