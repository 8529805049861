import {useState, useEffect} from 'react';
import {useHistory} from 'react-router';

const shouldCollapse = (pathname: string) =>
  [
    'public',
    'exam-room',
    'waiting-room',
    'documentation',
    'verifyAccount',
    'first-time',
  ].some(p => pathname.startsWith(`/${p}`));

export function useCollapse() {
  const history = useHistory();
  const [collapseNavigation, setCollapseNavigation] = useState(
    shouldCollapse(window.location.pathname),
  );

  useEffect(() => {
    return history.listen(location => {
      const {pathname} = location ?? window.location;
      setCollapseNavigation(shouldCollapse(pathname));
    });
  }, [history, setCollapseNavigation]);

  return collapseNavigation;
}
