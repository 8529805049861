import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import * as Sentry from '@sentry/browser';
import {useAuth0} from '@auth0/auth0-react';
import ConfigService from '@src/ConfigService';
import {ReduxState} from '@src/store';
import {removeHipaaAcknowledgmentCookie} from '@src/util/files/hipaaAcknowledgmentCookie';

/**
 *
 * @param options.queryParams params to be added to the redirect URL
 * @param options.localOnly skips the request to the logout endpoint on the authorization server,
 * effectively performing a "local" logout of the application. Useful if you need local auth session
 * to be cleared immediately without an asynchronous request. A use-case could be logging user out on tab/browser
 * close.
 */

type Options = {
  queryParams?: URLSearchParams;
  localOnly?: boolean;
};

export const useLogout = ({queryParams, localOnly}: Options = {}) => {
  const {logout} = useAuth0();
  const configuration = useSelector((state: ReduxState) => state.configuration);

  return useCallback(() => {
    if (ConfigService.environmentIs('local')) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: undefined,
        });
      });
    }

    const logoutDomain = ConfigService.getDomain();
    const logoutDestination = configuration
      .getOptional()
      .map(c => c.customerConfig?.logoutDestination)
      .orElse(logoutDomain);

    window.localStorage.removeItem('authState');
    removeHipaaAcknowledgmentCookie();
    ConfigService.setLogoutDestination(logoutDestination);
    ConfigService.clearCustomerCode();

    let query = queryParams?.toString() ?? '';

    return logout({
      localOnly,
      returnTo: `${logoutDomain}/logout${query ? '?' : ''}${query}`,
    });
  }, [configuration, logout, queryParams, localOnly]);
};
