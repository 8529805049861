import {JSONType} from '../app-types';
import {ProviderDetails} from './ProviderDetails';
import {TrackedModel} from './TrackedModel';
import {$num, getParser} from './ResponseParser';

export class GroupManager extends TrackedModel {
  callPoolId: number;
  providerDetails: ProviderDetails;

  static template = () => ({
    callPoolId: $num,
    providerDetails: ProviderDetails.fromJSON,
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<GroupManager>) {
    return new GroupManager(getParser(GroupManager.template)(json));
  }

  constructor(data: GroupManager) {
    super(data);
    this.callPoolId = data.callPoolId;
    this.providerDetails = data.providerDetails;
  }
}
