import {useCallback} from 'react';

export const useGetSignedUrl = ({
  onAddNewFile,
  getSignature,
}: {
  onAddNewFile: (f: File) => void;
  getSignature: (f: File) => Promise<string>;
}): ((file: File, cb: () => void) => void) => {
  return useCallback(
    (file: File, cb: () => void) => {
      onAddNewFile(file);
      getSignature(file).then(cb);
    },
    [getSignature, onAddNewFile],
  );
};
