import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contained: {
      textTransform: 'none',
      borderRadius: theme.spacing(0.5),

      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    outlined: {
      textTransform: 'none',
      borderRadius: theme.spacing(0.5),

      borderWidth: '2px',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.dark,
        borderWidth: '2px',
      },
    },
    text: {
      color: theme.palette.grey[600],
      textTransform: 'none',
      '&:hover': {
        color: theme.palette.grey[800],
      },
    },
    small: {
      marginTop: '1rem',
      fontSize: '0.75rem',
      padding: theme.spacing(0.25, 0.5),
    },
    disabledButton: {
      color: theme.palette.grey[500],
      backgroundColor: theme.palette.grey[200],
    },
    isInRow: {
      marginRight: '1rem',
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    success: {
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    danger: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }),
);

export const useAsyncStyles = makeStyles((theme: Theme) =>
  createStyles({
    inProgress: {
      color: theme.palette.grey[400],
      fontWeight: 500,
      marginLeft: '1rem',
      display: 'inline-block',
    },
    notInProgress: {display: 'none'},
  }),
);
