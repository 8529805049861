const GERUNDS = [
  {
    regex: /^accept/,
    inflection: 'Accepting',
  },
  {
    regex: /^activate/,
    inflection: 'Activating',
  },
  {
    regex: /^approve/,
    inflection: 'Approving',
  },
  {
    regex: /^confirm/,
    inflection: 'Confirming',
  },
  {
    regex: /^create/,
    inflection: 'Creating',
  },
  {
    regex: /^download/,
    inflection: 'Downloading',
  },
  {
    regex: /^finish/,
    inflection: 'Finishing',
  },
  {
    regex: /^leave/,
    inflection: 'Leaving',
  },
  {
    regex: /^hand back/,
    inflection: 'Handing back',
  },
  {
    regex: /^link/,
    inflection: 'Linking',
  },
  {
    regex: /^load/,
    inflection: 'Loading',
  },
  {
    regex: /^log in/,
    inflection: 'Logging in',
  },
  {
    regex: /^reconnect/,
    inflection: 'Reconnecting',
  },
  {
    regex: /^request/,
    inflection: 'Requesting',
  },
  {
    regex: /^reset/,
    inflection: 'Resetting',
  },
  {
    regex: /^return/,
    inflection: 'Returning',
  },
  {
    regex: /^save/,
    inflection: 'Saving',
  },
  {
    regex: /^send/,
    inflection: 'Sending',
  },
  {
    regex: /^sign up/,
    inflection: 'Signing up',
  },
  {
    regex: /^submit/,
    inflection: 'Submitting',
  },
  {
    regex: /^unlock/,
    inflection: 'Unlocking',
  },
  {
    regex: /^update/,
    inflection: 'Updating',
  },
  {
    regex: /^validate/,
    inflection: 'Validating',
  },
];

export default (word: string = ''): string => {
  const lcWord = word.toLowerCase();
  const gerund = GERUNDS.find(g => g.regex.test(lcWord));
  if (gerund) {
    return gerund.inflection;
  }
  return word;
};
