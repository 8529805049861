import MuiPhoneInput from 'material-ui-phone-number';
import React, {forwardRef} from 'react';
import {InputProps} from './Input';

type Props = InputProps<string> & {
  onChange?: (num: string) => unknown;
};

export const PhoneInput = forwardRef(
  (
    {
      disabled = false,
      error,
      inputProps = {},
      instructions = '',
      name,
      placeholder = '555-555-555',
      required = false,
      title = '',
      value = '',
      onChange = () => {},
      endAdornment = null,
    }: Props,
    ref,
  ) => (
    <MuiPhoneInput
      {...inputProps}
      ref={ref}
      disableDropdown={true}
      name={name}
      error={!!error}
      defaultCountry={'us'}
      onlyCountries={['us']}
      disableCountryCode={true}
      onChange={onChange}
      disabled={disabled}
      variant="outlined"
      value={value}
      fullWidth
      margin="normal"
      label={title}
      required={required}
      placeholder={placeholder || instructions}
      helperText={typeof error === 'string' ? error : ''}
      InputProps={{
        endAdornment,
      }}
    />
  ),
);
