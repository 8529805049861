import jstz from 'jstz';
import {Participant} from 'twilio-video';
import {
  AppFile,
  VisitFile,
  ExternalVisitLog,
  LonelyVisit,
  Patient,
  Professional,
  SafeVisit,
  VisitAction,
  VisitDocumentation,
} from '../models';
import {AppAPI, asIs, Paged, paged, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {
  BasicQueryException,
  DownloadException,
  ResourceConflictException,
  VisitStateException,
} from './exceptions';
import {Resource} from './Resource';
import {PayloadForAnonymousVisitor} from '@src/util/videoChat/twilio/anonymousVisitor';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

interface SurveyRequest {
  npsScore?: number | undefined;
  comments?: string | undefined;
}

interface UpdateVisitRequest {
  scpId?: number;
  fallbackNumber?: string;
  dontUseInsurance?: boolean;
  dontUseCreditCard?: boolean;
}

export type VisitDocumentationRequest = Omit<
  VisitDocumentation,
  | 'id'
  | 'updatedAt'
  | 'signer'
  | 'addendums'
  | 'signerName'
  | 'signature'
  | 'signing'
>;

class VisitAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'visits');
  }

  @requiresAuth
  @requiresId
  get(): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'get',
      `${this.id}`,
      null,
      `Something went wrong trying find practice with id ${this.id}`,
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  getSafe(): Promise<SafeVisit> {
    return this.do<SafeVisit>(
      'get',
      `${this.id}/safe-visit`,
      null,
      `Something went wrong trying get visit with id ${this.id}`,
    ).then(single(SafeVisit.fromJSON));
  }

  @requiresAuth
  getAll(start: number, pageSize: number): Promise<Paged<LonelyVisit>> {
    const params = new URLSearchParams();
    params.set('start', `${start}`);
    params.set('pageSize', `${pageSize}`);
    return this.do<Paged<LonelyVisit>>(
      'get',
      ``,
      params,
      `Something went wrong trying get all Visits`,
    ).then(paged(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  getPatient(): Promise<Patient> {
    return this.do<Patient>(
      'get',
      `${this.id}/patient`,
      null,
      'Something went wrong trying find patient of a Visit',
    ).then(single(Patient.fromJSON));
  }

  @requiresId
  @requiresAuth
  getProvider(): Promise<Professional> {
    return this.do<Professional>(
      'get',
      `${this.id}/provider`,
      null,
      'Something went wrong trying find provider of a Visit',
    ).then(single(Professional.fromJSON));
  }

  @requiresId
  @requiresAuth
  getAccessToken(): Promise<{token: string}> {
    return this.do<{token: string}>(
      'get',
      `${this.id}/access-token`,
      null,
      'Something went wrong trying get a Twilio access key',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  anonymousAccessRequest(
    payload: PayloadForAnonymousVisitor,
  ): Promise<{token: string}> {
    return this.do<{token: string}>(
      'post',
      `${this.id}/anonymous-access-request`,
      payload,
      'Something went wrong trying get a Twilio access key',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  getTwilioParticipants(): Promise<Participant[]> {
    return this.do<void>(
      'get',
      `${this.id}/participants`,
      null,
      'Something went wrong trying get Visit participants from Twilio',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  update(data: UpdateVisitRequest): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'put',
      `/${this.id}`,
      data,
      'Something went wrong trying update a Visit',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  setProvider(providerDetailsId: ProviderDetailsId): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'patch',
      `/${this.id}`,
      {providerDetailsId},
      'Something went wrong trying update a Visit',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  assignProfessional(
    providerDetailsId: ProviderDetailsId,
  ): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'put',
      `/${this.id}/professional/${providerDetailsId}`,
      null,
      'Something went wrong trying assign a professional to a visit',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  unassignProfessional(): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'delete',
      `/${this.id}/professional`,
      null,
      'Something went wrong trying unassign a professional from a visit',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  act(action: VisitAction): Promise<LonelyVisit> {
    return this.do<LonelyVisit>('post', `/${this.id}/action/${action}`)
      .then(single(LonelyVisit.fromJSON))
      .catch(error => {
        if (error instanceof ResourceConflictException) {
          throw new VisitStateException();
        }

        throw new BasicQueryException(
          `Could not update visit state: ${error.message}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  cancel(reason: string): Promise<LonelyVisit> {
    return this.do<LonelyVisit>('post', `/${this.id}/cancel`, {reason})
      .then(single(LonelyVisit.fromJSON))
      .catch(error => {
        if (error instanceof ResourceConflictException) {
          throw new VisitStateException();
        }
        throw new BasicQueryException(
          `Could not update visit state: ${error.message}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  complete(): Promise<LonelyVisit> {
    return this.act(VisitAction.COMPLETE);
  }

  @requiresId
  @requiresAuth
  reportArrival(): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'put',
      `${this.id}/arrival`,
      null,
      'Something went wrong trying find update a Visit',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  getDocumentation(): Promise<VisitDocumentation> {
    return this.do<VisitDocumentation>(
      'get',
      `${this.id}/documentation`,
      null,
      'Something went wrong trying find get visit documentation',
    ).then(single(VisitDocumentation.fromJSON));
  }

  @requiresId
  @requiresAuth
  updateDocumentation(
    data: VisitDocumentationRequest,
  ): Promise<VisitDocumentation> {
    return this.do<VisitDocumentation>(
      'put',
      `${this.id}/documentation`,
      data,
      'Something went wrong trying find update visit documentation',
    ).then(single(VisitDocumentation.fromJSON));
  }

  @requiresId
  @requiresAuth
  sign(): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'put',
      `${this.id}/documentation/signature`,
      null,
      'Something went wrong trying sign visit documentation',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  markAsReviewed(): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'put',
      `${this.id}/review`,
      null,
      "Something went wrong trying mark this visit's notes as reviewed",
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  updateExternalVisitLog(): Promise<ExternalVisitLog> {
    return this.do<ExternalVisitLog>(
      'post',
      `${this.id}/externalVisitLog`,
      null,
      "Something went wrong trying update this external visit's logs",
    ).then(single(ExternalVisitLog.fromJSON));
  }

  @requiresId
  @requiresAuth
  getDocumentationDownloadURL(): Promise<string> {
    return this.apiBase.then(
      apiBase => `${apiBase}/${this.id}/documentation.pdf`,
    );
  }

  @requiresId
  @requiresAuth
  getReportDownloadURL(): Promise<string> {
    return this.apiBase.then(apiBase => `${apiBase}/${this.id}/report.pdf`);
  }

  @requiresAuth
  async getDocumentationPackage(ids: Array<string | number>): Promise<Blob> {
    const tz = jstz.determine();
    const apiBase = await this.apiBase;
    const url = `${apiBase}/documentation/zip?ids=${ids.join(
      ',',
    )}&tz=${tz.name()}`;

    try {
      return this.api
        .fetch(url, {
          headers: {
            Authorization: `Bearer ${await this.api.access_token}`,
            Accept: 'application/zip',
          },
        })

        .then(response => response.blob());
    } catch (response) {
      throw new DownloadException(
        `Problem downloading documentation package: ${response.message}`,
      );
    }
  }

  @requiresId
  @requiresAuth
  addAddendum(content: string): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'post',
      `${this.id}/addendum`,
      {content},
      'Something went wrong trying find add an addendum',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  addFile(fileInfo: AppFile): Promise<VisitFile> {
    return this.do<VisitFile>(
      'post',
      `/${this.id}/files`,
      fileInfo,
      'Something went wrong trying add a file',
    ).then(single(VisitFile.fromJSON));
  }

  @requiresAuth
  @requiresId
  submitSurvey(surveyRequest: SurveyRequest): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'post',
      `/${this.id}/survey`,
      surveyRequest,
      'Something went wrong trying add a file',
    ).then(single(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  addAssignedForm(assignedFormId: number): Promise<LonelyVisit> {
    return this.do<LonelyVisit>(
      'post',
      `/${this.id}/forms`,
      assignedFormId,
      'Something went wrong trying add a form',
    ).then(single(LonelyVisit.fromJSON));
  }
}

export default VisitAPI;
