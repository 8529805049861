import {
  RemoteAudioTrackPublication,
  RemoteVideoTrackPublication,
  LocalAudioTrackPublication,
  LocalVideoTrackPublication,
} from 'twilio-video';

export function trackpubsToTracks(
  trackMap: Map<
    string,
    | RemoteVideoTrackPublication
    | RemoteAudioTrackPublication
    | LocalAudioTrackPublication
    | LocalVideoTrackPublication
  >,
) {
  const trackMapValues = Array.from(trackMap.values());
  const mappedPublications = trackMapValues?.map(
    publication => publication.track,
  );
  const filtered = mappedPublications.filter(track => track !== null);

  return filtered;
}
