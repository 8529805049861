import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$num, $str, $arr, $bool, $date, getParser} from './ResponseParser';
import {AppFile, Patient, Professional, Model} from '.';

interface IPastSignout {
  createdAt: string;
  expiry: Date;
  details: string;
  provider: {};
}

interface $NewSignout {
  readonly id?: number;
  deleted?: boolean;
  expiry?: Date;
  details: string;
  files?: Array<AppFile>;
  history?: IPastSignout[];
}

export class SignoutItem extends Model implements $NewSignout {
  provider: Professional;
  versionNumber: number;
  details: string = '';
  files: Array<AppFile> = [];
  deleted: boolean = false;
  expiry: Date;
  createdAt: Date;
  updatedAt: Date;

  static template = () => ({
    provider: Professional.fromJSON,
    versionNumber: $num,
    details: $str,
    files: $arr(AppFile.fromJSON),
    deleted: $bool,
    expiry: $date,
    createdAt: $date,

    ...Model.template(),
  });

  static fromJSON(json: JSONType<SignoutItem>) {
    return new SignoutItem(getParser(SignoutItem.template)(json));
  }

  constructor(props: SignoutItem) {
    super(props);

    this.provider = props.provider;
    this.details = props.details || '';
    this.files = (props.files || []).map(f => new AppFile(f));
    this.expiry = new Date(props.expiry);
    this.deleted = props.deleted || false;
    this.createdAt = new Date(props.createdAt);
    this.updatedAt = new Date(props.updatedAt);
    this.versionNumber = props.versionNumber;
  }
}

export interface SignoutInfo {
  acute: SignoutItem | null;
}

interface ISignout extends SignoutInfo {
  patient: Optional<Patient>;
}

export class Signout implements ISignout {
  acute: SignoutItem | null;
  patient: Optional<Patient>;

  static fromJSON(json: JSONType<Signout>) {
    return new Signout({
      acute: json.acute ? SignoutItem.fromJSON(json.acute) : null,
      patient: Optional.of(
        json.patient ? Patient.fromJSON(json.patient) : undefined,
      ),
    });
  }

  constructor(props: ISignout) {
    this.acute = props.acute && new SignoutItem(props.acute);
    this.patient = props.patient;
  }
}
