import {startSound} from '../../../../../components/shared/VideoChat/CheckHairRoom/SessionConfigForm/functions';
import {AudioSampleState} from '../../../../../components/shared/VideoChat/CheckHairRoom/SessionConfigForm/AudioOutConfigSection';

import {bell} from '../../../../../components/shared/VideoChat/CheckHairRoom/SessionConfigForm/bell';
import {
  onParticipantConnected,
  onParticipantDisconnected,
  AttachRoomEventListenersProps,
} from '@src/util/videoChat';

export function attachParticipantConnected({
  room,
  setParticipants,
  setNotification,
  setWaitingParticipants,
}: Omit<AttachRoomEventListenersProps, 'setShowReloadingModal'>) {
  room.on('participantConnected', p => {
    startSound(bell, (v: AudioSampleState) => {});

    onParticipantConnected({
      setParticipants,
      setWaitingParticipants,
      participant: p,
      setNotification,
    });
  });
}

export function attachParticipantDisconnected({
  room,
  setParticipants,
  setWaitingParticipants,
}: Omit<
  AttachRoomEventListenersProps,
  'setShowReloadingModal' | 'setNotification'
>) {
  room.on('participantDisconnected', p =>
    onParticipantDisconnected({
      setParticipants,
      setWaitingParticipants,
      participant: p,
    }),
  );
}
