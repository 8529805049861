function isSharedCarePlanPath() {
  return window.location.pathname.includes('my-scp');
}

export function sharingSCPInVisit() {
  const searchParams = new URLSearchParams(window.location.search);
  const inVisit = searchParams.has('inVisit');

  return isSharedCarePlanPath() && inVisit;
}
