import React from 'react';
import {addBreadcrumb, captureException, Severity} from '@sentry/browser';
import {TwilioError} from 'twilio-video';
import {AttachRoomEventListenersProps} from '@src/util/videoChat/twilio/anonymousVisitor';

export function attachParticipantReconnecting({
  room,
  setNotification,
  setShowReloadingModal,
}: Omit<AttachRoomEventListenersProps, 'setParticipants'>) {
  room.on('reconnecting', (err: TwilioError) => {
    addBreadcrumb({
      category: 'exam-room',
      message: 'Reconnection error',
      data: {err},
      level: Severity.Error,
    });

    captureException(new Error(`Twilio Reconnection: ${err.code}`));

    if (err.code === 53405) {
      setNotification(
        <>No longer receiving video from the other end. Reconnecting...</>,
      );
    } else {
      setShowReloadingModal(true);
    }
  });
}
