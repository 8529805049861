import {RemoteParticipant} from 'twilio-video';
import {
  SetParticipants,
  doesParticipantRequirePermission,
} from '@src/util/videoChat';

export type OnParticipantConnectedProps = {
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  setNotification?: (v: JSX.Element | null) => void;
  participant: RemoteParticipant;
};

export function onParticipantConnected({
  setParticipants,
  setNotification,
  participant,
  setWaitingParticipants,
}: OnParticipantConnectedProps): void {
  if (doesParticipantRequirePermission({participant})) {
    setWaitingParticipants(prevParticipants => [
      ...prevParticipants.filter(p => p.sid !== participant.sid),
      participant,
    ]);
  } else {
    setParticipants(prevParticipants => [
      ...prevParticipants.filter(p => p.sid !== participant.sid),
      participant,
    ]);
  }

  if (setNotification) {
    setNotification(null);
  }
}

export type OnParticipantDisconnectedProps = {
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  participant: RemoteParticipant;
};

export function onParticipantDisconnected({
  setParticipants,
  participant,
  setWaitingParticipants,
}: OnParticipantDisconnectedProps): void {
  if (doesParticipantRequirePermission({participant})) {
    setWaitingParticipants(prevParticipants =>
      prevParticipants.filter(p => p !== participant),
    );
  }

  setParticipants(prevParticipants =>
    prevParticipants.filter(p => p !== participant),
  );
}
