import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {HATEOAS} from '../api/HATEOAS';
import {Modifications, modificationsFromJson} from './ModificationRecord';
import {
  $opt,
  $str,
  $arr,
  $enum,
  $nullable,
  $date,
  getParser,
} from './ResponseParser';
import {LonelyPatient} from './Patient';
import {AppFile, Contact, Model, Patient, ProviderDetails} from '.';

export enum SCPStatus {
  // noinspection JSUnusedGlobalSymbols
  Draft = 'draft',
  OwnershipExpired = 'ownershipExpired',
  Approved = 'approved',
  ApprovalExpired = 'approvalExpired',
  Retracted = 'retracted',
}

export const SCPStatusNames: Record<SCPStatus, string> = {
  [SCPStatus.Draft]: 'Draft',
  [SCPStatus.OwnershipExpired]: 'Ownership Expired',
  [SCPStatus.Approved]: 'Approved',
  [SCPStatus.ApprovalExpired]: 'Approval Expired',
  [SCPStatus.Retracted]: 'Retracted',
};

export class LonelySCP extends Model {
  approvalExpiration: Date | null;
  ownershipExpiration: Date | null;
  acuteManagementSteps: string;
  personalManagementSteps: string;
  communicationPreferences: string;
  contacts: Contact[];
  diagnosis: string;
  emergencySummary: string;
  status: SCPStatus;
  files: AppFile[];
  labsOrImaging: string;
  modifications: Modifications<SharedCarePlan>;
  technology: string;
  patient: Optional<LonelyPatient>;
  providerDetails: Optional<ProviderDetails>;

  static template = () => ({
    approvalExpiration: $nullable($date),
    ownershipExpiration: $nullable($date),
    acuteManagementSteps: $str,
    personalManagementSteps: $str,
    communicationPreferences: $str,
    contacts: $arr(Contact.fromJSON),
    diagnosis: $str,
    status: $enum(SCPStatus),
    emergencySummary: $str,
    files: $arr(AppFile.fromJSON),
    labsOrImaging: $str,
    modifications: modificationsFromJson<SharedCarePlan>(),
    technology: $str,
    patient: $opt(LonelyPatient.fromJSON),
    providerDetails: $opt(ProviderDetails.fromJSON),
    ...Model.template(),
  });

  static fromHATEOAS(hateoas: HATEOAS<LonelySCP>) {
    const scp = LonelySCP.fromJSON(hateoas);
    scp.links = hateoas._links ?? {};
    return scp;
  }

  static fromJSON(json: JSONType<LonelySCP>) {
    return new LonelySCP(getParser(LonelySCP.template)(json));
  }

  constructor(props: LonelySCP) {
    super(props);

    this.acuteManagementSteps = props.acuteManagementSteps;
    this.approvalExpiration = props.approvalExpiration;
    this.ownershipExpiration = props.ownershipExpiration;
    this.communicationPreferences = props.communicationPreferences;
    this.contacts = props.contacts;
    this.diagnosis = props.diagnosis;
    this.emergencySummary = props.emergencySummary;
    this.files = props.files;
    this.labsOrImaging = props.labsOrImaging;
    this.modifications = props.modifications;
    this.patient = props.patient;
    this.providerDetails = props.providerDetails;
    this.personalManagementSteps = props.personalManagementSteps;
    this.status = props.status;
    this.technology = props.technology;
  }
}

export class SharedCarePlan extends LonelySCP {
  patient: Optional<Patient>;

  static template = () => ({
    ...LonelySCP.template(),
    patient: $opt(Patient.fromJSON),
  });

  static fromHATEOAS(hateoas: HATEOAS<SharedCarePlan>) {
    const scp = SharedCarePlan.fromJSON(hateoas);
    scp.links = hateoas._links ?? {};
    return scp;
  }

  static fromJSON(json: JSONType<SharedCarePlan>) {
    return new SharedCarePlan(getParser(SharedCarePlan.template)(json));
  }

  constructor(props: SharedCarePlan) {
    super(props);

    this.patient = props.patient;
  }
}
