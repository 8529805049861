import {DeepPartial} from 'ts-essentials';
import {AppAPI, coll, PaginationOptions, single, Paged} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {
  ApplicationException,
  BadUserInput,
  BasicQueryException,
  BasicUpdateException,
  ResourceConflictException,
} from './exceptions';
import {Resource} from './Resource';
import {search} from './standardRequests';
import {DetailsRequest} from './ProviderDetailsAPI';
import {APIWithSearch} from './APIWithSearch';
import {
  CallPool,
  Certification,
  OnCallPeriod,
  Professional,
  ProviderPartner,
  Signout,
  LonelyVisit,
  LonelySCP,
  ProviderDetails,
  VisitState,
} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {VisitsFilter} from '@src/components/providerSide/oncall/OnCallDashboard';
import {UserId} from '@src/models/User';

export type AvailableVisitsOptions = {
  callPoolId?: number | null;
  filter?: VisitsFilter | null;
};

type VisitFilter = {
  state: VisitState[];
};

const paginationOptionsToQuery = (
  options: PaginationOptions<VisitFilter>,
): string => {
  const {pageSize = 10, start = 0, filter} = options;
  const {state} = filter ?? {state: []};
  const filterQuery = state.map(s => `filter.state=${s}`).join('&');
  return `pageSize=${pageSize}&start=${start}&${filterQuery}`;
};

class ProviderAPI extends Resource implements APIWithSearch<ProviderDetails> {
  constructor(id: UserId | undefined, api: AppAPI) {
    super(id, api, 'providers');
  }

  /**
   * Get Provider's information
   */
  @requiresId
  @requiresAuth
  get(): Promise<Professional> {
    return this.do<Professional>(
      'get',
      `${this.id}`,
      null,
      'Something went wrong trying to get Provider',
    ).then(single(Professional.fromJSON));
  }

  /**
   * Get Provider's details
   * @returns
   */
  @requiresId
  @requiresAuth
  getDetails(): Promise<ProviderDetails> | never {
    return this.do<ProviderDetails>(
      'get',
      `${this.id}/details`,
      null,
      'Something went wrong trying to get Provider Details',
    ).then(single(ProviderDetails.fromJSON));
  }

  /**
   * Get Provider's certifications
   * @returns {Promise.<Array.<*>>}
   */
  @requiresId
  @requiresAuth
  getCertifications(): Promise<Certification[]> | never {
    return this.basicGetter<Certification>(
      'certifications',
      Certification.fromJSON,
    ).catch(response => {
      throw new BasicQueryException(
        `Something went wrong trying to get a Provider's certifications: ${response.message}`,
      );
    });
  }

  /**
   * Get Provider's signouts
   */
  @requiresId
  @requiresAuth
  getSignouts(): Promise<Signout[]> {
    return this.basicGetter<Signout>('signouts', Signout.fromJSON).catch(
      response => {
        throw new BasicQueryException(
          `Something went wrong trying to get a Provider's signouts: ${response.status}`,
        );
      },
    );
  }

  /**
   * Get unreviewed notes
   */
  @requiresId
  @requiresAuth
  getUnreviewedNotes(options: PaginationOptions): Promise<LonelyVisit[]> {
    const {pageSize = 10, start = 0} = options;
    return this.basicGetter<LonelyVisit>(
      `visits/unreviewed?pageSize=${pageSize}&start=${start}`,
      LonelyVisit.fromJSON,
    ).catch(response => {
      throw new BasicQueryException(
        `Something went wrong trying to get a Provider's unreviewed notes: ${response.status}`,
      );
    });
  }

  /**
   * Get incomplete Visits
   */
  @requiresId
  @requiresAuth
  getIncompleteVisits(): Promise<LonelyVisit[]> {
    return this.basicGetter<LonelyVisit>(
      'visits/incomplete',
      LonelyVisit.fromJSON,
    ).catch(response => {
      throw new BasicQueryException(
        `Something went wrong trying to get a Provider's incomplete visits: ${response.status}`,
      );
    });
  }

  /**
   * Adds a license to a Provider
   */
  @requiresId
  @requiresAuth
  addLicense(data: {}) {
    return this.do<Certification>(
      'post',
      `/${this.id}/certifications`,
      data,
      'Invalid data for creating certification',
    )
      .then(single(Certification.fromJSON))
      .catch(error => {
        if (error instanceof BadUserInput) {
          throw error;
        } else {
          throw new BasicUpdateException(
            `Something went wrong trying to add a license to a Provider: ${error.message}`,
          );
        }
      });
  }

  /**
   * Removes a license from a Provider
   */
  @requiresId
  @requiresAuth
  removeLicense(licenseId: number) {
    return this.do(
      'delete',
      `${this.id}/certifications/${licenseId}`,
      null,
      response =>
        `Something went wrong trying to add a license to a Provider: ${response.status}`,
    );
  }

  @requiresId
  @requiresAuth
  getVisits(
    options: PaginationOptions<VisitFilter>,
  ): Promise<Paged<LonelyVisit>> {
    const query = paginationOptionsToQuery(options);

    return this.pagedGet<LonelyVisit>(
      `visits?${query}`,
      LonelyVisit.fromJSON,
      "Something went wrong trying find Provider's visits",
    );
  }

  @requiresId
  @requiresAuth
  getManagedGroupVisits(
    options: PaginationOptions<VisitFilter>,
  ): Promise<Paged<LonelyVisit>> {
    const query = paginationOptionsToQuery(options);
    return this.pagedGet<LonelyVisit>(
      `managedGroups/visits?${query}`,
      LonelyVisit.fromJSON,
      "Something went wrong trying find Provider's Managed Group's visits",
    );
  }

  @requiresId
  @requiresAuth
  getOnCallPeriods(options: PaginationOptions = {}): Promise<OnCallPeriod[]> {
    const {pageSize = 10, start = 0} = options;
    return this.do<OnCallPeriod[]>(
      'get',
      `/${this.id}/onCallPeriods?pageSize=${pageSize}&start=${start}`,
      null,
      "Something went wrong trying find Provider's on call periods",
    ).then(coll(OnCallPeriod.fromJSON));
  }

  @requiresId
  @requiresAuth
  deleteOnCallPeriod(periodId: number): Promise<void> {
    return this.do(
      'delete',
      `/${this.id}/onCallPeriods/${periodId}`,
      null,
      'Something went wrong trying delete an on call period',
    ).then(() => {});
  }

  @requiresId
  @requiresAuth
  getAvailableVisits(
    paginationOptions: PaginationOptions,
    options?: AvailableVisitsOptions,
  ): Promise<Array<LonelyVisit>> {
    const params = new URLSearchParams();

    const {pageSize = 10, start = 0} = paginationOptions;

    params.set('pageSize', pageSize.toString());
    params.set('start', start.toString());

    if (!isNothing(options)) {
      const {callPoolId = 0, filter} = options;

      if (callPoolId) {
        params.set('callPoolId', `${callPoolId}`);
      }

      if (filter) {
        params.set('filter', `${filter}`);
      }
    }

    return this.do<LonelyVisit[]>(
      'get',
      `/${this.id}/availableVisits`,
      params,
      "Something went wrong trying find Provider's Available Visits",
    ).then(coll(LonelyVisit.fromJSON));
  }

  @requiresId
  @requiresAuth
  getPanelVisits(
    options: PaginationOptions<VisitFilter>,
  ): Promise<Paged<LonelyVisit>> {
    const query = paginationOptionsToQuery(options);
    return this.pagedGet<LonelyVisit>(
      `patients/visits?${query}`,
      LonelyVisit.fromJSON,
      "Something went wrong trying find my panel's visits",
    );
  }

  @requiresId
  @requiresAuth
  updateOnCallNumber(onCallNumber: string): Promise<Professional> {
    return this.do<Professional>(
      'put',
      `/${this.id}/onCallNumber`,
      {onCallNumber},
      'Invalid data for updating on call number',
    )
      .then(single(Professional.fromJSON))
      .catch(error => {
        if (error instanceof BadUserInput) {
          throw error;
        } else {
          throw new BasicUpdateException(
            `Something went wrong trying to update on call number: ${error.message}`,
          );
        }
      });
  }

  @requiresAuth
  search(
    searchString: string,
    includeNonSearchable?: boolean,
  ): Promise<ProviderDetails[]> {
    const params = new URLSearchParams();
    if (includeNonSearchable) {
      params.set('includeNonSearchable', '1');
    }

    return search<ProviderDetails>(this, searchString, params).then(
      coll(ProviderDetails.fromJSON),
    );
  }

  @requiresId
  @requiresAuth
  updateDetails(request: DeepPartial<DetailsRequest>): Promise<Professional> {
    return this.do<Professional>('patch', `/${this.id}/details`, request).then(
      single(Professional.fromJSON),
    );
  }

  @requiresId
  @requiresAuth
  getOwnedSCPs(): Promise<LonelySCP[]> {
    return this.do<LonelySCP[]>('get', `/${this.id}/scps/owned`).then(
      coll(LonelySCP.fromJSON),
    );
  }

  @requiresId
  @requiresAuth
  getCareTeamSCPs(): Promise<LonelySCP[]> {
    return this.do<LonelySCP[]>('get', `/${this.id}/scps/careTeam`).then(
      coll(LonelySCP.fromJSON),
    );
  }

  /**
   * Get Groups for a Professional.
   *
   * It's possible for a Professional to only have a `GroupManager` association
   * with a `CallPool` without having a `ProviderCallPoolManagement` association with the `CallPool`.
   * `memberOnly` and `managedOnly` help filter the results.
   */
  @requiresId
  @requiresAuth
  getGroups({
    managedOnly,
    memberOnly,
    includeVisitsUnsupported,
  }: {
    managedOnly?: boolean;
    memberOnly?: boolean;
    includeVisitsUnsupported?: boolean;
  }): Promise<CallPool[]> {
    const params = new URLSearchParams();

    if (managedOnly) {
      params.set('managedOnly', 'true');
    } else {
      params.set('managedOnly', 'false');
    }

    if (memberOnly) {
      params.set('memberOnly', 'true');
    } else {
      params.set('memberOnly', 'false');
    }

    if (includeVisitsUnsupported) {
      params.set('includeVisitsUnsupported', 'true');
    } else {
      params.set('includeVisitsUnsupported', 'false');
    }

    return this.do<CallPool[]>('get', `/${this.id}/groups`, params)
      .then(coll(CallPool.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching call pools: ${response}`,
        );
      });
  }

  /**
   * Add Partner to a Provider.
   * Partners are other Providers that can act on behalf of the Provider.
   */
  @requiresId
  @requiresAuth
  addPartner(partnerId: UserId): Promise<ProviderPartner[]> {
    return this.do<ProviderPartner[]>(
      'post',
      `${this.id}/partners/${partnerId}`,
      null,
    )
      .then(coll(ProviderPartner.fromJSON))
      .catch(error => {
        if (error instanceof ResourceConflictException) {
          throw new ResourceConflictException(
            'That user is already a partner. Please choose another user and try again.',
          );
        } else {
          throw new ApplicationException(
            `Something went wrong trying to add Partner with id ${partnerId} to Provider with id ${this.id}`,
          );
        }
      });
  }

  /**
   * Deletes Partner from a Provider.
   * Partners are other Providers that can act on behalf of the Provider.
   */
  @requiresId
  @requiresAuth
  deletePartnership(partnershipId: number): Promise<ProviderPartner[]> {
    return this.do<ProviderPartner[]>(
      'delete',
      `${this.id}/partners/${partnershipId}`,
      null,
      `Something went wrong trying to delete Partner with id ${partnershipId} from Provider with id ${this.id}`,
    ).then(coll(ProviderPartner.fromJSON));
  }

  /**
   * Get Provider's partners.
   * Partners are other Providers that can act on behalf of the Provider.
   */
  @requiresAuth
  @requiresId
  getPartners(): Promise<ProviderPartner[]> {
    return this.do<ProviderPartner[]>(
      'get',
      `${this.id}/partners`,
      null,
      'Something went wrong trying to get the Partners for the Provider',
    ).then(coll(ProviderPartner.fromJSON));
  }
}

export default ProviderAPI;
