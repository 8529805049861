export class AppBaseException extends Error {
  details: {
    code?: string;
    data?: string;
  };
  message: string = '';
  name: string;

  constructor(message: string = '', details: {} = {}) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    Object.setPrototypeOf(this, AppBaseException.prototype);
    this.message = message;
    this.details = details;
  }
}

export class VisitCreationException extends AppBaseException {
  public name = 'VisitCreationException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, VisitCreationException.prototype);
  }
}

export class ApplicationException extends AppBaseException {
  public name = 'ApplicationException';
  constructor(message: string | AppBaseException = '', details: {} = {}) {
    super(
      typeof message === 'string'
        ? message
        : `${message.message}: ${message.details}`,
      details,
    );
    Object.setPrototypeOf(this, ApplicationException.prototype);
  }
}

export class ClinicClosedException extends AppBaseException {
  public name = 'ClinicClosedException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, ClinicClosedException.prototype);
  }
}
export class VisitStateException extends AppBaseException {
  public name = 'VisitStateException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, VisitStateException.prototype);
  }
}

export class BadUserInput extends AppBaseException {
  public name = 'BadUserInput';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, BadUserInput.prototype);
  }
}

export class BasicQueryException extends AppBaseException {
  public name = 'BasicQueryException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, BasicQueryException.prototype);
  }
}

export class BasicUpdateException extends AppBaseException {
  public name = 'BasicUpdateException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, BasicUpdateException.prototype);
  }
}

export class DownloadException extends AppBaseException {
  public name = 'DownloadException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, DownloadException.prototype);
  }
}

export class ConnectGuardianException extends AppBaseException {
  public name = 'ConnectGuardianException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, ConnectGuardianException.prototype);
  }
}

export class LegalSignatureException extends AppBaseException {
  public name = 'LegalSignatureException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, LegalSignatureException.prototype);
  }
}

export class HttpResponseException extends AppBaseException {
  public name = 'HttpResponseException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, HttpResponseException.prototype);
  }
}

export class InvalidMethodCall extends AppBaseException {
  public name = 'InvalidMethodCall';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, InvalidMethodCall.prototype);
  }
}

export class InvalidPhoneNumberException extends AppBaseException {
  public name = 'InvalidPhoneNumberException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, InvalidPhoneNumberException.prototype);
  }
}

export class NotAuthorizedError extends AppBaseException {
  public name = 'NotAuthorizedError';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, NotAuthorizedError.prototype);
  }
}

export class PatientCreationException extends AppBaseException {
  public name = 'PatientCreationException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, PatientCreationException.prototype);
  }
}

export class PatientUpdateException extends AppBaseException {
  public name = 'PatientUpdateException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, PatientUpdateException.prototype);
  }
}

export class PatientMergeException extends AppBaseException {
  public name = 'PatientMergeException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, PatientMergeException.prototype);
  }
}
export class PasswordResetException extends AppBaseException {
  public name = 'PasswordResetException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, PasswordResetException.prototype);
  }
}

export class PaymentInformationUpdateException extends AppBaseException {
  public name = 'PaymentInformationUpdateException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, PaymentInformationUpdateException.prototype);
  }
}

export class ResourceConflictException extends AppBaseException {
  public name = 'ResourceConflictException';
  details!: {
    code?: string;
    data?: string;
    location: string;
  };
  constructor(
    message: string = '',
    details: {location: string} = {location: ''},
  ) {
    super(message, details);
    Object.setPrototypeOf(this, ResourceConflictException.prototype);
  }
}

export class ResourceNotFoundException extends AppBaseException {
  public name = 'ResourceNotFoundException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
  }
}

export class UpdateSignoutException extends AppBaseException {
  public name = 'UpdateSignoutException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, UpdateSignoutException.prototype);
  }
}

export class UserWithoutPhoneException extends AppBaseException {
  public name = 'UserWithoutPhoneException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, UserWithoutPhoneException.prototype);
  }
}
export class ScreenShareException extends AppBaseException {
  public name = 'ScreenShareException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, ScreenShareException.prototype);
  }
}

export class TargetInvoiceCreationException extends AppBaseException {
  public name = 'TargetInvoiceCreationException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, TargetInvoiceCreationException.prototype);
  }
}

export class EndpointCreationException extends AppBaseException {
  public name = 'EndpointCreationException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, EndpointCreationException.prototype);
  }
}

export class LegalDocumentCreationEndpoint extends AppBaseException {
  public name = 'LegalDocumentCreationException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, LegalDocumentCreationEndpoint.prototype);
  }
}

export class ClientConfigurationCreationException extends AppBaseException {
  public name = 'ClientConfigurationCreationException';
  constructor(message: string = '', details: {} = {}) {
    super(message, details);
    Object.setPrototypeOf(this, ClientConfigurationCreationException.prototype);
  }
}
