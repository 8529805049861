import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import {primaryDark, sm, xs, primaryText} from '../theme';
import {TextInput} from '.';

const StyledOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptionItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: ${xs};
  margin: ${xs} 0;
  width: 100%;
  &:hover {
    background-color: ${primaryDark};
    color: ${primaryText};
  }
`;

const OptionLabel = styled.div`
  margin-left: ${sm};
`;

type Props = {
  makeSelection: (selection: string) => void;
  makeOtherSelection: (selection: string) => void;
  options: {label: string; value: string}[];
  otherText: string;
  otherSelection: string;
  selection: string;
};

export const OtherValue = 'other';

export const OptionInput: React.FunctionComponent<Props> = ({
  makeSelection,
  makeOtherSelection,
  options,
  otherSelection,
  otherText,
  selection,
}) => {
  return (
    <>
      <StyledOptions>
        {options.map(option => (
          <OptionItem
            key={option.value}
            onClick={e => {
              makeSelection(option.value);
            }}
          >
            <FontAwesomeIcon
              icon={[
                'far',
                selection === option.value ? 'check-square' : 'square',
              ]}
            />
            <OptionLabel>{option.label}</OptionLabel>
          </OptionItem>
        ))}
      </StyledOptions>
      {selection === OtherValue && (
        <TextInput
          lines={3}
          autoExpand={true}
          value={otherSelection}
          onChange={e => makeOtherSelection(e.target.value)}
          name={'otherSelection'}
          title={otherText}
        ></TextInput>
      )}
    </>
  );
};
