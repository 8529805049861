import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useEffect, useCallback} from 'react';
import {useAsync} from '.';

type Options = {
  requestGate?: () => boolean;
};

export type Resources<T> = [
  AsyncData<T>,
  () => Promise<unknown>,
  (v?: T | readonly T[] | Error) => void,
  () => void,
];

const defaultOptions = Object.freeze({});

/**
 * This hook streamlines access to resources from the backend
 *
 * Using this hook will ensure that the resources are loaded on the component's first
 * mount
 *
 * The returned array is:
 *
 *  - the resources collection: an AsyncData<T>
 *  - a "reload" function that requests the resources; does not reset the resources to `Loading"
 *  - a setter function to manually set the resource
 *  - a reset function that resets the resources collection
 *
 * @param getter a function that requests resources from the backend
 * @param deps the dependencies of the `getter` function
 * @param options.requestGate a function that returns false to prevent a request. It should be a stable callback
 *
 */
export function useResources<T>(
  getter: () => Promise<T | T[]>,
  deps: ReadonlyArray<unknown>,
  options: Options = defaultOptions,
): Resources<T> {
  const [resources, setResources, resetResources] = useAsync<T>();
  const requestGate = options.requestGate ?? (() => true);

  const getAndSet = useCallback(
    () =>
      getter()
        .then(setResources)
        .catch(e => {
          console.error(e);
          setResources(e);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  useEffect(() => {
    if (resources.isAsked() || !requestGate()) {
      return;
    }
    setResources();
    getAndSet();
  }, [getAndSet, requestGate, resources, setResources]);

  return [resources, getAndSet, setResources, resetResources];
}
