import {RemoteTrack, Room, RemoteParticipant, Participant} from 'twilio-video';
import {Tracks} from '../../shared/tracks';
import {attachDataTrackEventListener} from './eventListeners';
import {SetParticipants, isParticipantAnonymous} from '@src/util/videoChat';

export type OnTrackSubscribedProps = {
  track: RemoteTrack;
  room: Room | null;
  setVideoTracks?: (fn: (vts: Tracks) => Tracks) => void;
  setAudioTracks?: (fn: (ats: Tracks) => Tracks) => void;
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  participant: Participant;
};

export function onTrackSubscribedOrPublished({
  track,
  setVideoTracks,
  setAudioTracks,
  setWaitingParticipants,
  participant,
}: OnTrackSubscribedProps) {
  if (track?.kind === 'video' && setVideoTracks) {
    setVideoTracks(vts => [track, ...vts].filter(t => !!t));
  } else if (track?.kind === 'audio' && setAudioTracks) {
    setAudioTracks(ats => [track, ...ats].filter(t => !!t));
  } else if (track?.kind === 'data') {
    attachDataTrackEventListener({
      dataTrack: track,
      participant,
      setWaitingParticipants,
    });
  }
}

type TrackUnsubscribedProps = OnTrackSubscribedProps;

export function onTrackUnsubscribedOrUnpublished({
  track,
  setVideoTracks,
  setAudioTracks,
  participant,
  setWaitingParticipants,
  setParticipants,
}: TrackUnsubscribedProps) {
  if (track?.kind === 'video' && setVideoTracks) {
    setVideoTracks(vts => vts.filter(v => v !== track && !!v));
  } else if (track?.kind === 'audio' && setAudioTracks) {
    setAudioTracks(ats => ats.filter(a => a !== track && !!a));
  } else if (track?.kind === 'data') {
    if (isParticipantAnonymous({participant})) {
      setWaitingParticipants(prevParticipants => [
        ...prevParticipants.filter(p => p.sid !== participant.sid),
      ]);

      setParticipants(prevParticipants => [
        ...prevParticipants.filter(p => p.sid !== participant.sid),
        participant as RemoteParticipant,
      ]);
    }
  }
}
