type VideoMessages = {
  disabled?: string;
  unsupported?: string;
  noPermission?: string;
  awaitingVideo?: string;
};

export const defaultMessages: VideoMessages = {
  disabled: 'Video is currently disabled',
  unsupported:
    'Video is not supported on this browser. Please use Chrome or Firefox',
  noPermission: 'Your browser has denied access to the camera and microphone.',
  awaitingVideo:
    'Your video will start once someone else arrives to this visit',
};
