import {Opaque} from 'ts-essentials';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {StreetAddress} from '../components/ui/form/AddressInput';
import {
  $str,
  $obj,
  $phone,
  $opt,
  $date,
  $num,
  getParser,
  $nullable,
  $arr,
  $opaque,
  $ifMissing,
} from './ResponseParser';
import {Demographics} from './Demographics';
import {PatientRelationship} from './PatientRelationship';
import {Identifier, TrackedModel} from '.';
import {rqString, yDate, yObject} from '@src/schema/types';
import {streetAddressSchema} from '@src/components/ui/form/AddressInput/functions';

export type ResponsiblePersonRequest = JSONType<
  Omit<
    ResponsiblePerson,
    | 'fullName'
    | 'guardianId'
    | 'updatedAt'
    | 'lastUpdatedBy'
    | 'relationships'
    | 'identifiers'
  >
>;

export type NonProfessionalId = Opaque<number, 'NonProfessionalId'>;

export class ResponsiblePerson extends TrackedModel implements Demographics {
  id!: NonProfessionalId;
  firstName: string;
  lastName: string;
  fullName: string;
  email: Optional<string>;
  address: StreetAddress;
  phone: string | null;
  dob: Optional<Date>;
  guardianId: number | null;
  relationships: Optional<PatientRelationship[]>;
  identifiers: Identifier[];

  static template = () => ({
    firstName: $str,
    lastName: $str,
    fullName: $str,
    email: $opt($str),
    address: $obj<StreetAddress>(),
    phone: $nullable($phone),
    dob: $opt($date),
    guardianId: $nullable($num),
    relationships: $opt($arr(PatientRelationship.fromJSON)),
    identifiers: $ifMissing($arr(Identifier.fromJSON), []),
    ...TrackedModel.template(),
    id: $opaque<NonProfessionalId>(),
  });

  static fromJSON(json: JSONType<ResponsiblePerson>): ResponsiblePerson {
    return new ResponsiblePerson(getParser(ResponsiblePerson.template)(json));
  }

  static default(): ResponsiblePerson {
    return new ResponsiblePerson({
      id: 0 as NonProfessionalId,
      firstName: '',
      lastName: '',
      fullName: '',
      email: Optional.empty(),
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipcode: '',
      },
      phone: '',
      dob: Optional.empty(),
      guardianId: 0,
      identifiers: [],
      updatedAt: new Date(),
      lastUpdatedBy: Optional.empty(),
      relationships: Optional.empty(),
    });
  }

  constructor(props: ResponsiblePerson) {
    super(props);

    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.fullName = props.fullName || '';
    this.address = props.address || {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipcode: '',
    };
    this.phone = props.phone || '';
    this.dob = props.dob;
    this.email = props.email || '';
    this.guardianId = props.guardianId || 0;
    this.relationships = props.relationships;
    this.identifiers = props.identifiers;
  }
}

export const plainGuardianSchema = yObject({
  firstName: rqString("Please provide the Responsible Person's first name"),
  lastName: rqString("Please provide the Responsible Person's last name"),
  address: streetAddressSchema,
  phone: rqString("Please provide the Responsible Person's phone number"),
  dob: yDate('Please provide a valid date of birth').required(
    "Please provide the Responsible Person's date of birth",
  ),
  email: rqString("Please provide the Responsible Person's email"),
});

export const responsiblePersonSchema = plainGuardianSchema.shape({
  relationship: rqString(
    "Please tell us the Responsible Person's relationship to this patient",
  ),
});
