import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$opt, $str, $bool, getParser} from './ResponseParser';
import {
  SharedCarePlan,
  LonelyPatient,
  TrackedModel,
  User,
  LonelyVisit,
} from '.';
import {getKeys} from '@src/util/objectManipulation/getKeys';

const toKeep = (prop: string): boolean =>
  [
    'id',
    'filename',
    'key',
    'contentType',
    'comment',
    'deleted',
    'uploader',
  ].includes(prop);

export const convertToAppFile = (
  file: VisitFile | SCPFile | GeneralFile | AppFile,
): AppFile => {
  return getKeys(file)
    .filter(toKeep)
    .reduce((body, prop) => {
      const value = file[prop];

      return {
        ...body,
        [prop]: value,
      };
    }, ({} as unknown) as AppFile);
};

export class AppFile extends TrackedModel {
  readonly filename: string;
  readonly key: string;
  readonly contentType: string;
  deleted: boolean;
  comment: Optional<string>;
  uploader: User;

  static afterUpload({
    filename,
    key,
    contentType,
    comment,
  }: {
    filename: string;
    key: string;
    contentType: string;
    comment: Optional<string>;
  }) {
    const file: AppFile = {
      filename,
      key,
      contentType,
      deleted: false,
      comment,
    } as AppFile;
    return new AppFile(file);
  }

  static template = () => ({
    filename: $str,
    key: $str,
    contentType: $str,
    deleted: $bool,
    comment: $opt($str),
    uploader: User.fromJSON,
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<AppFile>) {
    return new AppFile(getParser(AppFile.template)(json));
  }

  constructor(props: AppFile) {
    super(props);
    this.filename = props.filename;
    this.key = props.key;
    this.contentType = props.contentType;
    this.deleted = props.deleted;
    this.comment = props.comment;
    this.uploader = props.uploader;
  }
}

export class VisitFile extends AppFile {
  visit: LonelyVisit;

  static template = () => ({
    visit: LonelyVisit.fromJSON,
    ...AppFile.template(),
  });

  static fromJSON(json: JSONType<VisitFile>) {
    return new VisitFile(getParser(VisitFile.template)(json));
  }

  constructor(props: VisitFile) {
    super(props);
    this.visit = props.visit;
  }
}

export class SCPFile extends AppFile {
  scp: SharedCarePlan;

  static template = () => ({
    scp: SharedCarePlan.fromJSON,
    ...AppFile.template(),
  });

  static fromJSON(json: JSONType<SCPFile>) {
    return new SCPFile(getParser(SCPFile.template)(json));
  }

  constructor(props: SCPFile) {
    super(props);
    this.scp = props.scp;
  }
}

export class GeneralFile extends AppFile {
  scp: Optional<SharedCarePlan>;
  visit: Optional<LonelyVisit>;
  patient: LonelyPatient;

  static template = () => ({
    scp: $opt(SharedCarePlan.fromJSON),
    patient: LonelyPatient.fromJSON,
    visit: $opt(LonelyVisit.fromJSON),
    ...AppFile.template(),
  });

  static fromJSON(json: JSONType<GeneralFile>) {
    return new GeneralFile(getParser(GeneralFile.template)(json));
  }

  constructor(props: GeneralFile) {
    super(props);
    this.scp = props.scp;
    this.visit = props.visit;
    this.patient = props.patient;
  }
}
