import {LocalTrack, LocalAudioTrack, LocalVideoTrack} from 'twilio-video';

export function isStoppableTrack(
  track: LocalTrack,
): track is LocalAudioTrack | LocalVideoTrack {
  return (
    track && (track as LocalAudioTrack | LocalVideoTrack).stop !== undefined
  );
}

export function isVideoTrack(track: LocalTrack): track is LocalVideoTrack {
  return track && (track as LocalAudioTrack | LocalVideoTrack).kind === 'video';
}
