import React from 'react';
import {InputProps, MenuItem, TextField} from '@material-ui/core';
import {isEmpty} from '@src/util/typeTests';

export type SelectOption = {
  label: string;
  disabled?: boolean;
  value: string | number;
};

type Props = {
  id?: string;
  title?: string;
  name: string;
  options: SelectOption[];
  placeholder?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  touched?: boolean;
  onChange?: InputProps['onChange'];
  onBlur?: InputProps['onBlur'];
  fontSize?: string;
};

export const SelectInput = ({
  id,
  disabled = false,
  error = '',
  name,
  options = [],
  placeholder = '',
  required = false,
  touched = true,
  title = '',
  value = '',
  fontSize = 'inherit',
  onChange = () => {},
  onBlur = () => {},
}: Props) => {
  return (
    <TextField
      id={id}
      select
      disabled={disabled}
      error={!!error && touched}
      required={required}
      variant="outlined"
      margin="normal"
      label={title}
      name={name}
      value={value}
      fullWidth
      onChange={onChange}
      onBlur={onBlur}
      helperText={error}
      InputProps={{style: {fontSize}}}
      InputLabelProps={{style: {fontSize}}}
    >
      {!isEmpty(placeholder) && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map(o => (
        <MenuItem
          key={`${o.label}:${o.value}`}
          value={o.value}
          disabled={o.disabled}
        >
          {o.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
