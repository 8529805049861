import {
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  createLocalVideoTrack,
} from 'twilio-video';
import {Tracks} from './types';
import {VideoChatEvents, sendEvent} from '@src/events';

export type OnToggleLocalAudioProps = {
  audioTracks: Tracks;
  shouldAudioBeMuted: boolean;
};

export function onToggleLocalAudio({
  audioTracks,
  shouldAudioBeMuted,
}: OnToggleLocalAudioProps): void {
  audioTracks.forEach(publication => {
    if (shouldAudioBeMuted) {
      (publication as LocalAudioTrack).disable();
    } else {
      (publication as LocalAudioTrack).enable();
    }
  });
}

export type OnToggleLocalVideoProps = {
  videoTracks: Tracks;
  shouldVideoBeDisabled: boolean;
};

export function onToggleLocalVideo({
  videoTracks,
  shouldVideoBeDisabled,
}: OnToggleLocalVideoProps): void {
  videoTracks.forEach(publication => {
    if (shouldVideoBeDisabled) {
      (publication as LocalVideoTrack).disable();
    } else {
      (publication as LocalVideoTrack).enable();
    }
  });
}

type OnEndScreenShareProps = {
  screenTrack: LocalVideoTrack;
  room: Room;
};

export async function onEndScreenshare({
  screenTrack,
  room,
}: OnEndScreenShareProps) {
  screenTrack.stop();
  room.localParticipant.unpublishTrack(screenTrack);
  screenTrack.removeAllListeners();

  const videoTrack = await createLocalVideoTrack();
  room.localParticipant.publishTrack(videoTrack);
  sendEvent(VideoChatEvents.StopPresentingSCP);
}
