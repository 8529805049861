import {useMemo} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {useUser} from './useUser';
import {userIsPatient} from '@src/util/users/userIsPatient';
import {Patient} from '@src/models';
import {reference} from '@src/util/stringManipulation/languageHelpers';

export const useReference = (patient: Patient | Optional<Patient>) => {
  const op = Optional.of(patient);
  const [user] = useUser();
  const isSelf = userIsPatient(op, user.getOptional());

  const r = useMemo(
    () => reference(op.map(p => p.preferredName).orElse(''), isSelf),
    [isSelf, op],
  );

  return r;
};
