import {Optional} from '@ahanapediatrics/ahana-fp';
import {isNothing} from '../typeTests';
import {not} from '../predicateHelpers/not';
import {UserType, User, Professional, Guardian} from '@src/models';
import {Demographics} from '@src/models/Demographics';

const getDemographics = (u: User | Optional<User>): Demographics => {
  const oUser = Optional.of(u);
  let demogs = Optional.empty<Demographics>();
  if (oUser.isPresent()) {
    const user = oUser.get();
    if (user.userType === UserType.Professional) {
      demogs = Optional.of(user)
        .cast<Professional>(us => 'providerDetails' in us)
        .flatMap(us => us.providerDetails)
        .map(d => d as Demographics)
        .cast<Demographics>(not(isNothing));
    }

    if (user.userType === UserType.Guardian) {
      demogs = Optional.of(user)
        .cast<Guardian>(us => 'responsiblePersonDetails' in us)
        .map(us => us.responsiblePersonDetails)
        .map(d => d as Demographics)
        .cast<Demographics>(not(isNothing));
    }
  }

  return demogs.orElse({
    firstName: '',
    lastName: '',
    fullName: '',
    email: Optional.empty(),
  } as Demographics);
};

export const firstName = (u: User | Optional<User>) => {
  const d = getDemographics(u);
  return d.firstName;
};
export const lastName = (u: User | Optional<User>) => {
  const d = getDemographics(u);
  return d.lastName;
};
export const fullName = (u: User | Optional<User>) => {
  const d = getDemographics(u);
  return d.fullName;
};
export const email = (u: User | Optional<User>) => {
  const d = getDemographics(u);
  return d.email;
};
