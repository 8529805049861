import {calculateAvailableWidth} from './layoutWidth';
import {calculateAvailableHeight} from './layoutHeight';
import {setGalleryDimensions} from './setGalleryDimensions';

/**
 * This function makes the video layout responsive regardless of number of
 * participants or device size.
 *
 * It calculates the available screen size and accounts for number of participants
 * to determine the dimensions of each video container.
 *
 * It also sets up an event listener to listen to screen resizing.
 *
 * An event listener is also set up in ExamRoom.tsx to run `recalculateVideoLayout`
 * when  participants connect or disconnect from room.
 */

export function recalculateVideoLayout() {
  const gallery = document.getElementById('gallery');
  const mainScreenContainer = document.getElementById('main-screen-container');
  const aspectRat = 4 / 3;

  const availableWidth = calculateAvailableWidth();
  const availableHeight = calculateAvailableHeight();
  const vidCount = document.getElementsByClassName('participantVideo').length;

  function calculateVideoLayout(
    containerWidth: number,
    containerHeight: number,
    videoCount: number,
    aspectRatio: number,
  ): {
    videoTagWidth: number;
    videoTagHeight: number;
    cols: number;
    rows: number;
  } {
    let optimalLayout = {
      area: 0,
      cols: 0,
      rows: 0,
      videoTagWidth: 0,
      videoTagHeight: 0,
    };

    for (
      let possibleColsCount = 1;
      possibleColsCount <= videoCount;
      possibleColsCount++
    ) {
      const possibleRowCount = Math.ceil(videoCount / possibleColsCount);
      const hScale = containerWidth / (possibleColsCount * aspectRatio);
      const vScale = containerHeight / possibleRowCount;
      let possibleVideoTagWidth;
      let possibleVideoTagHeight;
      if (hScale <= vScale) {
        possibleVideoTagWidth = Math.floor(containerWidth / possibleColsCount);
        possibleVideoTagHeight = Math.floor(
          possibleVideoTagWidth / aspectRatio,
        );
      } else {
        possibleVideoTagHeight = Math.floor(containerHeight / possibleRowCount);
        possibleVideoTagWidth = Math.floor(
          possibleVideoTagHeight * aspectRatio,
        );
      }
      const area = possibleVideoTagWidth * possibleVideoTagHeight;
      if (area > optimalLayout.area) {
        optimalLayout = {
          videoTagWidth: possibleVideoTagWidth,
          videoTagHeight: possibleVideoTagHeight,
          cols: possibleColsCount,
          rows: possibleRowCount,
          area,
        };
      }
    }
    return optimalLayout;
  }

  const {videoTagWidth, videoTagHeight, cols, rows} = calculateVideoLayout(
    availableWidth,
    availableHeight,
    vidCount,
    aspectRat,
  );

  setGalleryDimensions({
    gallery,
    mainScreenContainer,
    videoTagWidth,
    videoTagHeight,
    mainScreenElementHeight: availableHeight,
    cols,
    rows,
  });
}
