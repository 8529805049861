import {
  AsyncData,
  Either,
  makeNonOptional,
  Optional,
} from '@ahanapediatrics/ahana-fp';
import {addBreadcrumb, captureException, Severity} from '@sentry/browser';
import * as H from 'history';
import {RefObject, useEffect} from 'react';
import {AudioSampleState} from './AudioOutConfigSection';
import {Devices, Dispatchers, MediaDeviceState} from './MediaDeviceManager';
import {User, UserType} from '@src/models';
import {canSetAudioSink} from '@src/util/browserTools';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {
  categorizeDevices,
  getMediaDevices,
  getMediaPermission,
  getUserMedia,
  MediaFailure,
  PossibleStream,
  stopStream,
} from '@src/util/videoChat';

const streams: {
  audio: Map<string, PossibleStream>;
  video: Map<string, PossibleStream>;
} = {
  audio: new Map(),
  video: new Map(),
};

const NO_DEVICE_CHOSEN = 'NO_DEVICE_CHOSEN';

export const getStream = (type: 'audio' | 'video') => async (
  deviceId: string,
): Promise<PossibleStream> => {
  addBreadcrumb({
    category: 'media',
    message: `Getting stream`,
    data: {
      type,
      deviceId,
    },
    level: Severity.Info,
  });
  const stream = streams[type].get(deviceId);
  const trackIsAvailable =
    stream &&
    stream
      .mapRight(s => s.getTracks())
      .mapRight(ts => ts.find(t => t?.kind === type))
      .map(
        () => false,
        t => t && t?.readyState !== 'ended' && !t?.muted,
      );
  if (!trackIsAvailable) {
    streams[type].clear();
    streams[type].set(
      deviceId,
      await getUserMedia(`get${toTitleCase(type)}Stream`)({
        [type]:
          deviceId === NO_DEVICE_CHOSEN ? true : {deviceId: {exact: deviceId}},
      }),
    );
  }

  return (
    streams[type].get(deviceId) ||
    Either.left(MediaFailure.DeviceCannotBeStarted)
  );
};
/**
 * Gets a `MediaStream` object for the video device represented by the given
 * device ID
 *
 * @param deviceId - the ID of the device in question
 */
const getVideoStream = getStream('video');

/**
 * Gets a `MediaStream` object for the audio device represented by the given
 * device ID
 *
 * @param deviceId - the ID of the device in question
 */
const getAudioStream = getStream('audio');

/**
 * Connects a video MediaStream to an HTML Video Element so users can see it
 *
 * @param videoStream
 * @param videoRef
 * @param setVideoStream
 */
export const connectStreamToVideoElement = (
  videoStream: Optional<MediaStream>,
  videoRef: RefObject<HTMLVideoElement>,
) => {
  const {current} = videoRef;
  if (current) {
    current.srcObject = videoStream.orNothing() || null;
  }

  return videoStream;
};

export const loadDevices = async (): Promise<Either<MediaFailure, Devices>> => {
  // Call getUserMedia first to get permissions so that we get labels
  const result = await getMediaPermission();

  return result.proceedRightAsync(() =>
    getMediaDevices('loadDevices').then(r => r.mapRight(categorizeDevices)),
  );
};

/**
 *
 * @param videoInDeviceId
 */
const startVideo = async (
  videoInDeviceId: Optional<string>,
): Promise<Either<MediaFailure, MediaStream>> => {
  if (!videoInDeviceId.isPresent()) {
    return Either.left(MediaFailure.None);
  }

  return await getVideoStream(videoInDeviceId.get());
};

const stopVideo = (videoStream: Optional<MediaStream>) => {
  videoStream.ifPresent(stopStream);
};

/**
 * Starts an audio sample playing
 * @param audio - the HTML Audio element that plays the sample
 * @param setAudioPlaying -  a function that can be used to make an update to
 *                           any state that is tracking whether the audio is
 *                           playing
 */
export const startSound = (
  audio: HTMLAudioElement | undefined,
  setAudioPlaying: (a: AudioSampleState) => void,
) => {
  if (!audio) {
    console.warn('No audio outlet');
    return;
  }

  setAudioPlaying(AudioSampleState.Starting);
  return audio
    .play()
    .then(() => {
      setAudioPlaying(AudioSampleState.Playing);
    })
    .catch(e => {
      console.error('Problem starting sound');
      console.error(e);
      console.error(e.message);
      console.error(e.stack);
    });
};

/**
 * Stops the audio sample from playing
 *
 * @param audio - The HTML Audio element that plays the sample
 * @param setAudioPlaying -  a function that can be used to make an update to
 *                           any state that is tracking whether the audio is
 *                           playing
 */
export const stopSound = (
  audio: HTMLAudioElement | undefined,
  setAudioPlaying: (a: AudioSampleState) => void,
) => {
  if (!audio) {
    return;
  }

  audio.pause();
  audio.currentTime = 0;
  setAudioPlaying(AudioSampleState.Stopped);
};

export const updateMediaFailure = (
  setMediaFailure: (f: Optional<MediaFailure>) => void,
) => (x: MediaFailure): MediaFailure => {
  setMediaFailure(Optional.of(x));
  if (x !== MediaFailure.None) {
    captureException(new Error('Cannot access devices'));
  }
  return x;
};

export const updateAudioOutputDevice = async (
  audio: HTMLAudioElement,
  audioOutDeviceId: Optional<string>,
): Promise<Either<MediaFailure, string>> => {
  if (canSetAudioSink(audio) && audioOutDeviceId.isPresent()) {
    try {
      await audio.setSinkId(audioOutDeviceId);
    } catch (err) {
      const info = await loadDevices();
      return info.mapRight(() => audioOutDeviceId.get());
    }
  }
  return Either.right(audioOutDeviceId);
};

export const updateAudioInputDevice = async (
  audioInDeviceId: Optional<string>,
): Promise<Either<MediaFailure, MediaStream>> => {
  return await getAudioStream(audioInDeviceId.orElse(NO_DEVICE_CHOSEN));
};

export const updateVideoInputDevice = (
  currentStream: Optional<MediaStream>,
) => async (
  videoInDeviceId: Optional<string>,
): Promise<Either<MediaFailure, MediaStream>> => {
  stopVideo(currentStream);
  return startVideo(videoInDeviceId);
};

/**
 * Updates the browser history to take the user back to "safety"
 *
 * @param history - the browser history object
 * @param user - the current user
 */
export const returnToSafety = (
  history: H.History,
  user: AsyncData<User>,
) => () => {
  const searchParams = new URLSearchParams(window.location.search);
  const returnPath =
    typeof searchParams.get('return') === 'string' ||
    user
      .getOptional()
      .filter(u => u.userType === UserType.Guardian)
      .isPresent()
      ? '/'
      : '/on-call';

  history.push(returnPath);
};

/**
 * Creates an effect React hook that attaches a handler to an HTMLElement event and
 * then removes it when the effect is done
 *
 * @param element - the HTML element that can trigger an event
 * @param eventName - the name of the event to handle
 * @param handler - the function that handles the event
 */
export const useEventHandler = (
  element: EventTarget | undefined | null,
  eventName: string,
  handler: () => void,
) =>
  useEffect(() => {
    if (element) {
      if (typeof element.addEventListener === 'function') {
        element.addEventListener(eventName, handler);
      } else {
        console.warn(`No event listener for ${eventName}`);
      }
    }
    return () => {
      if (element && typeof element.removeEventListener === 'function') {
        element.removeEventListener(eventName, handler);
      }
    };
  }, [element, eventName, handler]);

const selectDevice = <T extends MediaDeviceInfo>(devicesInfo: T[]) => (
  selectedDevice: Optional<T>,
) => {
  // Check that any selected device is still actually available
  const availableDevice = selectedDevice.map(s =>
    devicesInfo.find(d => d.deviceId === s.deviceId),
  );
  const defaultDeviceGetter = () =>
    devicesInfo.find(d => d.deviceId === 'default') || devicesInfo[0];

  return Optional.of(availableDevice.orElseGet(defaultDeviceGetter));
};

type NoUpdate = {};
const idempodentUpdate = <T extends MediaDeviceInfo>(
  devicesInfo: T[],
  currentDevice: Optional<T>,
): Either<NoUpdate, T> => {
  const selectedDevice = selectDevice<T>(devicesInfo)(currentDevice);
  if (
    !currentDevice.equals(selectedDevice, (c, s) => c.deviceId === s.deviceId)
  ) {
    return Either.right(selectedDevice);
  }
  return Either.left({});
};

export const deviceReducer = (
  state: MediaDeviceState,
  dispatchers: Dispatchers,
) => (devices: Devices) => {
  const {audioIn, audioOut, videoIn} = state;
  idempodentUpdate(devices.audioInputs, audioIn).apply(() => {},
  makeNonOptional(dispatchers.audioIn));
  idempodentUpdate(devices.audioOutputs, audioOut).apply(() => {},
  makeNonOptional(dispatchers.audioOut));
  idempodentUpdate(devices.videoInputs, videoIn).apply(() => {},
  makeNonOptional(dispatchers.videoIn));
};

export const _updateDevice = <T extends MediaDeviceInfo>(
  dispatch: (device: Optional<T>) => void,
  deviceSet: Optional<T[]>,
) => async (deviceId: Optional<string>) => {
  dispatch(
    deviceSet.map(d => d.find(v => deviceId.equals(Optional.of(v.deviceId)))),
  );
};
