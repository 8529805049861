import {JSONType} from '../app-types';
import {$str, getParser, $num, $enum} from './ResponseParser';
import {Model, UserType} from '.';
import {getKeys} from '@src/util/objectManipulation/getKeys';

export class Identifier extends Model {
  val!: string;
  source!: string;
  tableType!: UserType | 'Patient';
  tableId!: number;

  static template = () => ({
    val: $str,
    source: $str,
    tableId: $num,
    tableType: $enum(UserType),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<Identifier>) {
    return new Identifier(getParser(Identifier.template)(json));
  }

  constructor(props: Identifier) {
    super(props);
    getKeys(Identifier.template()).forEach(k => {
      if (k !== 'id' && k !== 'updatedAt') {
        //@ts-expect-error
        this[k] = props[k];
      }
    });
  }
}
