import {Optional} from '@ahanapediatrics/ahana-fp';
import {$num, $opt, getParser} from './ResponseParser';
import {Model} from './Model';
import {NumericField} from './NumericField';
import {JSONType} from '@src/app-types';

export class NumericResponse extends Model {
  value: Optional<number>;
  formNumericFieldId: number;
  assignedFormId: number;
  numericField: Optional<NumericField>;

  static template = () => ({
    value: $opt($num),
    formNumericFieldId: $num,
    assignedFormId: $num,
    numericField: $opt(NumericField.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<NumericResponse>) {
    return new NumericResponse(getParser(NumericResponse.template)(json));
  }

  constructor(props: NumericResponse) {
    super(props);

    this.value = props.value;
    this.formNumericFieldId = props.formNumericFieldId;
    this.assignedFormId = props.assignedFormId;
    this.numericField = Optional.of(props.numericField);
  }
}
