import {LocalVideoTrack, Room} from 'twilio-video';
import {onEndScreenshare, Track, Tracks} from '../../shared/tracks';

export type AttachVideoTrackEventListenersProps = {
  videoTrack: Track;
  setIsVideoDisabled: (v: boolean) => void;
  videoTracks: Tracks;
  isLocal: boolean;
};

export function attachVideoTrackEventListeners({
  videoTrack,
  setIsVideoDisabled,
  videoTracks,
  isLocal,
}: AttachVideoTrackEventListenersProps): void {
  videoTrack?.on('disabled', () => {
    // We manage the UI from the onClick handler in LocalParticipantControls.
    // Not returning here creates odd bugs.
    if (isLocal) {
      return;
    }

    if (videoTracks.filter(t => t && t?.isEnabled).length <= 0) {
      setIsVideoDisabled(true);
    }
  });

  videoTrack?.on('enabled', () => {
    // We manage the UI from the onClick handler in LocalParticipantControls.
    // Not returning here creates odd bugs.
    if (isLocal) {
      return;
    }

    if (videoTracks.filter(t => t && !t?.isEnabled).length <= 0) {
      setIsVideoDisabled(false);
    }
  });
}

export type AttachAudioTrackEventListenersProps = {
  audioTrack: Track;
  setIsMuted: (v: boolean) => void;
  audioTracks: Tracks;
  isLocal: boolean;
};

export function attachAudioTrackEventListeners({
  audioTracks,
  setIsMuted,
  audioTrack,
  isLocal,
}: AttachAudioTrackEventListenersProps): void {
  audioTrack?.on('disabled', () => {
    // We manage the UI from the onClick handler in LocalParticipantControls.
    // Not returning here creates odd bugs.
    if (isLocal) {
      return;
    }

    if (audioTracks.filter(t => t && t?.isEnabled).length <= 0) {
      setIsMuted(true);
    }
  });

  audioTrack?.on('enabled', () => {
    // We manage the UI from the onClick handler in LocalParticipantControls.
    // Not returning here creates odd bugs.
    if (isLocal) {
      return;
    }

    if (audioTracks.filter(t => t && !t?.isEnabled).length <= 0) {
      setIsMuted(false);
    }
  });
}

export function attachStopScreenshareListener({
  screenTrack,
  room,
}: {
  screenTrack: LocalVideoTrack;
  room: Room;
}) {
  screenTrack?.once('stopped', async () => {
    onEndScreenshare({screenTrack, room});
  });
}
