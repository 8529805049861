import {JSONType} from '../app-types';
import {$str, getParser} from './ResponseParser';
import {InvoiceTarget, Model} from '.';

export class StoredReport extends Model {
  name: string;
  description: string;
  functionName: string;
  invoiceTarget: InvoiceTarget;

  static template = () => ({
    name: $str,
    description: $str,
    functionName: $str,
    invoiceTarget: InvoiceTarget.fromJSON,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<StoredReport>) {
    return new StoredReport(getParser(StoredReport.template)(json));
  }

  constructor(props: StoredReport) {
    super(props);
    this.name = props.name;
    this.description = props.description;
    this.functionName = props.functionName;
    this.invoiceTarget = props.invoiceTarget;
  }
}
