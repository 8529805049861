import {useState, useCallback} from 'react';
import {useInterval} from '.';

/**
 * This hook returns the current time and updates it periodically
 *
 * @param period The update period in seconds; defaults to 30
 */
export const useCurrentTime = (period: number = 30) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  const updateTime = useCallback(() => {
    setCurrentTime(new Date());
  }, []);
  useInterval(updateTime, period * 1000);

  return currentTime;
};
