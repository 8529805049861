import {
  RemoteParticipant,
  RemoteTrack,
  Participant,
  Room,
  DataTrack,
} from 'twilio-video';
import {Tracks} from '../../shared/tracks';
import {NetworkQualityDataSetters} from '../../shared/participants/utils/networkQuality/networkQuality';
import {
  onTrackSubscribedOrPublished,
  onTrackUnsubscribedOrUnpublished,
} from './eventHandlers';
import {
  SetParticipants,
  attachNetworkQualityEventListener,
} from '@src/util/videoChat';

export type AttachDataTrackEventListenerProps = {
  dataTrack: DataTrack;
  participant: Participant;
  setWaitingParticipants: SetParticipants;
};

export function attachDataTrackEventListener({
  dataTrack,
  participant,
  setWaitingParticipants,
}: AttachDataTrackEventListenerProps): void {
  dataTrack?.on('message', message => {
    const json = JSON.parse(message);
    const {admitted} = json;

    if (!admitted) {
      setWaitingParticipants(prevParticipants => [
        ...prevParticipants.filter(p => p.sid !== participant.sid),
      ]);
    }
  });
}

export type AttachParticipantEventListeners = {
  participant: Participant | RemoteParticipant;
  setAudioTracks?: (fn: (vts: Tracks) => Tracks) => void;
  setVideoTracks?: (fn: (vts: Tracks) => Tracks) => void;
  room: Room | null;
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  networkQualityDataSetters: NetworkQualityDataSetters;
  visitId: number;
  isLocal: boolean;
};

export function attachParticipantEventListeners({
  participant,
  setAudioTracks,
  setVideoTracks,
  room,
  setParticipants,
  setWaitingParticipants,
  networkQualityDataSetters,
  visitId,
  isLocal,
}: AttachParticipantEventListeners) {
  attachNetworkQualityEventListener({
    participant,
    networkQualityDataSetters,
    visitId,
    isLocal,
  });

  participant.on('trackSubscribed', (t: RemoteTrack) => {
    onTrackSubscribedOrPublished({
      track: t,
      setAudioTracks,
      setVideoTracks,
      room,
      setParticipants,
      setWaitingParticipants,
      participant,
    });
  });

  participant.on('trackUnsubscribed', (t: RemoteTrack) => {
    onTrackUnsubscribedOrUnpublished({
      track: t,
      setAudioTracks,
      setVideoTracks,
      room,
      setParticipants,
      setWaitingParticipants,
      participant,
    });
  });

  participant.on('trackPublished', t => {
    onTrackSubscribedOrPublished({
      track: t.track,
      setAudioTracks,
      setVideoTracks,
      room,
      setParticipants,
      setWaitingParticipants,
      participant,
    });
  });

  participant.on('trackUnpublished', t => {
    onTrackUnsubscribedOrUnpublished({
      track: t.track,
      setAudioTracks,
      setVideoTracks,
      room,
      setParticipants,
      setWaitingParticipants,
      participant,
    });
  });
}
