import {LocalParticipant} from 'twilio-video';
import {Track} from './types';

export function muteLocalParticipant({
  localParticipant,
}: {
  localParticipant?: LocalParticipant;
}) {
  if (!localParticipant) return;
  localParticipant.audioTracks.forEach(function(audioTrackPublication) {
    audioTrackPublication.track.disable();
  });
}

export function unmuteLocalParticipant({
  localParticipant,
}: {
  localParticipant?: LocalParticipant;
}) {
  if (!localParticipant) return;
  localParticipant.audioTracks.forEach(function(audioTrackPublication) {
    audioTrackPublication.track.enable();
  });
}

export function isAudioMuted({audioTrack}: {audioTrack: Track}): boolean {
  return Boolean(audioTrack && !audioTrack.isEnabled);
}
