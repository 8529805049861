import React from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Button} from '@material-ui/core';
import {
  optionalNoSelect,
  optionalYesSelect,
} from '@src/util/forms/optionalSelect';

/**
 * value: represents the answer a yes/no question that may not have been answered
 * setValue: sets a true/false based on the answer
 * yesMessage: a string representing the Yes answer
 * noMessage: a string representing the No answer
 * disabled: a way to disable the button
 * invert: normally Yes => true/No => false. Setting `invert` to `true` will swap this mapping
 */
type Props = {
  value: Optional<boolean>;
  setValue: (b: boolean) => unknown;
  yesMessage?: string;
  noMessage?: string;
  disabled?: boolean;
  invert?: boolean;
};

export const YesOrNo = ({
  value,
  setValue,
  yesMessage = 'Yes',
  noMessage = 'No',
  disabled = false,
  invert = false,
}: Props) => {
  return (
    <div style={{float: 'left'}}>
      <Button
        color="primary"
        variant={optionalYesSelect(
          value.map(v => v !== invert),
          'contained',
          'outlined',
        )}
        onClick={() => {
          setValue(true !== invert);
        }}
        disabled={disabled}
        style={{marginRight: '1rem'}}
      >
        {yesMessage}
      </Button>
      <Button
        color="primary"
        variant={optionalNoSelect(
          value.map(v => v !== invert),
          'contained',
          'outlined',
        )}
        onClick={() => {
          setValue(false !== invert);
        }}
        disabled={disabled}
      >
        {noMessage}
      </Button>
    </div>
  );
};
