import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$opt, getParser} from './ResponseParser';
import {ProviderDetails, TrackedModel} from '.';

export class ProviderPartner extends TrackedModel {
  inviterDetails: Optional<ProviderDetails>;
  partnerDetails: Optional<ProviderDetails>;

  static template = () => ({
    inviterDetails: $opt(ProviderDetails.fromJSON),
    partnerDetails: $opt(ProviderDetails.fromJSON),
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<ProviderPartner>) {
    return new ProviderPartner(getParser(ProviderPartner.template)(json));
  }

  constructor(props: ProviderPartner) {
    super(props);
    this.inviterDetails = props.inviterDetails;
    this.partnerDetails = props.partnerDetails;
  }
}
