import {Room, RemoteParticipant} from 'twilio-video';
import {SetParticipants} from '../../shared/participants/utils/types';

export type StopLocalDataTracksProps = {
  room: Room | null;
};

export function stopLocalDataTracks({room}: StopLocalDataTracksProps) {
  room?.localParticipant.dataTracks.forEach(function(trackPublication) {
    if (trackPublication.track.kind === 'data') {
      trackPublication.unpublish();
      trackPublication.removeAllListeners();
    }
  });
}

type RespondToJoinRequest = {
  response: 'admit' | 'deny';
  room: Room | null;
  setWaitingParticipants: SetParticipants;
  waitingParticipant: RemoteParticipant;
};

export function respondToJoinRequest({
  waitingParticipant,
  setWaitingParticipants,
  response,
  room,
}: RespondToJoinRequest) {
  if (!room) {
    return;
  }

  room.localParticipant.dataTracks?.forEach(t => {
    t.track.send(
      JSON.stringify({
        visitorSid: waitingParticipant.sid,
        admitted: response === 'admit' ? true : false,
      }),
    );
  });

  setWaitingParticipants(prevParticipants => [
    ...prevParticipants.filter(
      participant => waitingParticipant.sid !== participant.sid,
    ),
  ]);
}
