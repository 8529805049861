import {AppAPI, coll, single} from './AppAPI';
import {requiresAuth} from './decorators';
import {
  ApplicationException,
  EndpointCreationException,
  HttpResponseException,
} from './exceptions';
import {Resource} from './Resource';
import {Endpoint} from '@src/models';
import {$NewEndpoint} from '@src/models/Endpoint';

class EndpointAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'endpoints');
  }

  @requiresAuth
  getAll() {
    return this.do<Endpoint[]>(
      'get',
      `/`,
      null,
      'Something went wrong trying to get all Endpoints',
    ).then(coll(Endpoint.fromJSON));
  }

  /**
   * Create an `Endpoint`
   * @param data The `Endpoint` data
   * @returns {Promise.<*>} Promise that resolves to the newly created `Endpoint`
   */
  @requiresAuth
  create(data: $NewEndpoint): Promise<Endpoint> {
    return this.do<Endpoint>('post', '/', data)
      .then(single(Endpoint.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new EndpointCreationException(
            `Something went wrong trying to create an Endpoint: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }
}

export default EndpointAPI;
