import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import {RouteComponentProps} from 'react-router';
import {Room} from 'twilio-video';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {Modal} from '../../../../../components/shared/VideoChat/ExamRoomModals';
import {
  flashError,
  flashWarn,
} from '../../../../../components/shared/notifications/flash';
import {stopTracks} from '@src/util/videoChat';

export type DisconnectFromTwilioRoomProps = {
  room: Room | null;
};

export function disconnectFromTwilioRoom({
  room,
}: DisconnectFromTwilioRoomProps): void {
  if (room) {
    room.disconnect();
  }
}

export const navigateOutOfRoom = (
  returnPath: string,
  showModal: (m: Modal) => unknown,
  history: RouteComponentProps<{}>['history'],
) => {
  showModal('none');
  clearAllBodyScrollLocks();
  setTimeout(() => history.push(returnPath), 10);
};

export type BailProps = {
  e: Error;
  message: string;
  isSerious?: boolean;
  room?: Room | null;
  getDestination: (type: 'temporary' | 'permanent') => string;
  showModal: (m: Modal) => unknown;
  history: RouteComponentProps<{}>['history'];
};

export const bail = ({
  e,
  message,
  isSerious,
  room,
  getDestination,
  showModal,
  history,
}: BailProps) => {
  console.error(`Bailing due to ${e}`);
  if (isSerious) {
    flashError(message);
  } else {
    flashWarn(message);
  }
  if (room) {
    room.disconnect();
  }
  navigateOutOfRoom(getDestination('permanent'), showModal, history);
};

type EjectFromRoomProps = {
  reason: string;
  room: Room | null;
  showModal: (m: Modal) => unknown;
  history: RouteComponentProps<{}>['history'];
  returnPath: string;
};

export const ejectFromRoom = ({
  reason,
  room,
  showModal,
  history,
  returnPath,
}: EjectFromRoomProps) => {
  addBreadcrumb({
    category: 'exam-room',
    message: reason,
    data: {
      room,
    },
    level: Severity.Info,
  });

  stopTracks({room});
  disconnectFromTwilioRoom({room});
  navigateOutOfRoom(returnPath, showModal, history);
};
