import {Grid} from '@material-ui/core';
import React from 'react';
import {CompletedUpload} from '@src/components/ui/organisms/uploaders/files/UploadsList/CompletedUpload';
import {useStyles} from '@src/components/ui/organisms/uploaders/files/UploadsList/styles';
import {FileBeingUploaded} from '@src/components/ui/organisms/uploaders/files/UploadsList/FileBeingUploaded';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {AppFile} from '@src/models';
import {byId} from '@src/util/sorters/byId';
import {InProgressFile} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';

type Props = {
  uploadingFiles: InProgressFile[];
  uploadedFiles: AppFile[];
  hideTitle?: boolean;
  onDeleteFile?: (file: AppFile) => unknown;
  onUpdateFile?: (file: AppFile) => unknown;
  FileEditIcon?: React.FunctionComponent<{
    file: AppFile;
    onSave: (file: AppFile) => unknown;
  }>;
  hideNoUploadsText?: boolean;
};

export const UploadsList = ({
  uploadedFiles,
  uploadingFiles,
  hideTitle = false,
  onUpdateFile,
  onDeleteFile,
  FileEditIcon,
  hideNoUploadsText = false,
}: Props) => {
  const classes = useStyles();

  const uploaded = uploadedFiles.length;
  const uploading = uploadingFiles.length;

  return (
    <Grid container direction="row" style={{marginTop: '2rem'}}>
      {!hideTitle && (
        <MuiHeading color="primary" style={{marginBottom: '0.5rem'}}>
          Files
        </MuiHeading>
      )}

      {uploaded + uploading === 0 && !hideNoUploadsText && (
        <Grid item xs={12} className={classes.gridItem}>
          <ParagraphText>No files uploaded yet</ParagraphText>
        </Grid>
      )}

      <Grid item xs={12} className={classes.gridItem}>
        {uploaded > 0 &&
          uploadedFiles
            .sort(byId)
            .map(f => (
              <CompletedUpload
                key={f.key}
                FileEditIcon={FileEditIcon}
                onUpdateFile={onUpdateFile}
                onDeleteFile={onDeleteFile}
                file={f}
              />
            ))}
      </Grid>

      {uploading > 0 && (
        <Grid item xs={12} className={classes.gridItem}>
          {uploadingFiles.map(f => (
            <FileBeingUploaded file={f} />
          ))}
        </Grid>
      )}
    </Grid>
  );
};
