import {JSONType} from '../app-types';
import {AppAPI, asIs, coll, PaginationOptions, single, singleH} from './AppAPI';
import {APIWithSearch} from './APIWithSearch';
import {requiresAuth, requiresId} from './decorators';
import {
  ApplicationException,
  BasicQueryException,
  ClinicClosedException,
  ConnectGuardianException,
  HttpResponseException,
  PatientCreationException,
  PatientMergeException,
  PatientUpdateException,
  PaymentInformationUpdateException,
  ResourceConflictException,
  ResourceNotFoundException,
  UpdateSignoutException,
  VisitCreationException,
} from './exceptions';
import {DetailsRequest} from './ProviderDetailsAPI';
import {Resource} from './Resource';
import {search} from './standardRequests';
import {UserId} from '@src/models/User';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {MergeOptions, PatientMergeRequest} from '@src/models/Patient';
import {
  $NewPatient,
  $NewVisit,
  AppFile,
  GeneralFile,
  SCPFile,
  VisitFile,
  AssignedForm,
  CallPool,
  CreditCard,
  LegalDocument,
  LonelyPatient,
  LonelyVisit,
  MedicalHistory,
  MedicalHistoryDetails,
  Patient,
  PatientRelationship,
  PaymentInformation,
  PaymentInformationRequestBody,
  ResponsiblePerson,
  SharedCarePlan,
  Signout,
  SignoutInfo,
  SignoutItem,
  SimpleVisit,
} from '@src/models';

class PatientAPI extends Resource implements APIWithSearch<LonelyPatient> {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'patients');
  }

  @requiresAuth
  search(searchString: string): Promise<LonelyPatient[]> {
    return search<LonelyPatient>(this, searchString).then(
      coll(LonelyPatient.fromJSON),
    );
  }

  /**
   * Create a new Patient
   * @param data The patient data
   * @returns {Promise.<*>} Promise that resolves to the newly created patient
   */
  @requiresAuth
  create(data: $NewPatient): Promise<Patient> {
    return this.do<Patient>('post', '/', data)
      .then(single(Patient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new PatientCreationException(
            `Something went wrong trying to add a Patient: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  /**
   */
  @requiresAuth
  @requiresId
  get(): Promise<Patient> {
    return this.do<Patient>('get', `/${this.id}`)
      .then(single(Patient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a Patient: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  /**
   * Create a new visit for the Patient
   * @param data
   */
  @requiresAuth
  @requiresId
  createVisit(data: $NewVisit): Promise<LonelyVisit> {
    return this.do<LonelyVisit>('post', `/${this.id}/visits`, {
      ...data,
    })
      .then(response =>
        response.map(
          e => {
            throw e;
          },
          res => LonelyVisit.fromJSON(res),
        ),
      )
      .catch(error => {
        if (error instanceof ResourceConflictException) {
          throw new ClinicClosedException('Clinic is currently closed');
        }
        if (error instanceof HttpResponseException) {
          throw new VisitCreationException(
            `Something went wrong trying to create an visit: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  /**
   * Get the Patient's current Guardians
   * @returns {Promise.<*>}
   */
  @requiresAuth
  @requiresId
  getGuardianships(): Promise<PatientRelationship[]> {
    return this.do<PatientRelationship[]>('get', `/${this.id}/guardianships/`)
      .then(coll(PatientRelationship.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a the Guardians of a Patient (${this.id}): ${error.message}`,
          );
        }
        throw new ApplicationException(error);
      });
  }

  /**
   * Invite a non-professional person to connect to a patient
   * @param email The email of the Person to connect to this Patient
   * @param isSelf Determines if we are inviting Patient to their own Patient record
   * @param relationshipName The label to put on the relationship that will be created
   * @returns
   */
  @requiresAuth
  @requiresId
  invitePerson({
    email,
    isSelf = false,
    relationshipName,
  }: {
    email: string;
    isSelf?: boolean;
    relationshipName: string;
  }): Promise<Patient> {
    return this.do<Patient>('put', `/${this.id}/invitations/${email}`, {
      isSelf,
      relationshipName,
    })
      .then(single(Patient.fromJSON))
      .catch(error => {
        throw new ConnectGuardianException(
          `Something went wrong trying to invite Person ${email} to connect to Patient (${
            this.id
          }): ${
            error instanceof Response ? error.status : 'No response from server'
          }`,
          error,
        );
      });
  }

  /**
   * Remove an invitation for a Patient
   * @param email The email of the Guardian to disinvite to this Patient
   * @returns {Promise.<*>}
   */
  @requiresAuth
  @requiresId
  deleteInvitation(email: string): Promise<LonelyPatient> {
    return this.do<LonelyPatient>(
      'delete',
      `/${this.id}/invitations/${email}`,
      {},
    )
      .then(single(LonelyPatient.fromJSON))
      .catch(error => {
        throw new ConnectGuardianException(
          `Something went wrong trying to remove an invite to Guardian ${email} to Patient (${
            this.id
          }): ${
            error instanceof Response ? error.status : 'No response from server'
          }`,
          error,
        );
      });
  }

  @requiresAuth
  @requiresId
  acceptInvitation(email: string): Promise<LonelyPatient> {
    return this.do<LonelyPatient>(
      'put',
      `/${this.id}/invitations/${email}/approval`,
      {},
    )
      .then(single(LonelyPatient.fromJSON))
      .catch(error => {
        throw new ConnectGuardianException(
          `Something went wrong trying to invite Guardian ${email} to connect to Patient (${
            this.id
          }): ${
            error instanceof Response ? error.status : 'No response from server'
          }`,
          error,
        );
      });
  }

  @requiresAuth
  @requiresId
  removeGuardianshipOrSelf(relId: number): Promise<void> {
    return this.do<void>('delete', `/${this.id}/guardianshipsOrSelf/${relId}`)
      .then(asIs)
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new PatientUpdateException(
            `Something went wrong trying to remove a relationship for a Patient: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  updatePatientInfo(data: $NewPatient): Promise<LonelyPatient> {
    return this.do<LonelyPatient>('patch', `/${this.id}`, data)
      .then(single(LonelyPatient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new PatientUpdateException(
            `Something went wrong trying to update a Patient: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  mergePatients(data: PatientMergeRequest): Promise<LonelyPatient> {
    return this.do<LonelyPatient>('patch', `/${this.id}/merge`, data)
      .then(single(LonelyPatient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new PatientMergeException(
            `Something went wrong trying to merge Patients: ${error.message}`,
            error.details,
          );
        }

        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  getMergeOptions(duplicatePatientId: number): Promise<MergeOptions> {
    return this.do<MergeOptions>(
      'get',
      `/${this.id}/mergeOptions?duplicatePatientId=${duplicatePatientId}`,
    )
      .then(single(MergeOptions.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new PatientMergeException(
            `Something went wrong trying to get Financially Responsible merge options: ${error.message}`,
            error.details,
          );
        }

        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  async update(data: $NewPatient): Promise<LonelyPatient> {
    await this.updatePatientInfo(data);
    let providerInfoId = 0 as ProviderDetailsId;
    if (data.details.isPresent()) {
      const info = data.details.get();
      providerInfoId = info.id ?? (0 as ProviderDetailsId);
      if (!providerInfoId) {
        const details = await this.api.providerDetails().create(info);
        console.log(details);
        providerInfoId = details.id;
      } else if (!info.searchable) {
        await this.api.providerDetails(providerInfoId).update(info);
      }
    }
    if (providerInfoId !== 0) {
      return this.setPcp(providerInfoId);
    } else {
      return this.removePcp();
    }
  }

  /**
   * Delete a new signout for the Patient
   * @param signoutId
   */
  @requiresAuth
  @requiresId
  deleteSignout(signoutId: number): Promise<Signout> {
    return this.do<Signout>('delete', `/${this.id}/signouts/${signoutId}`)
      .then(single(Signout.fromJSON))
      .catch(error => {
        throw new UpdateSignoutException(
          `Something went wrong trying to delete a signout for Patient (${
            this.id
          }): ${
            error instanceof HttpResponseException
              ? error.message
              : 'No response from server'
          }`,
          error.details,
        );
      });
  }

  /**
   * Get Patient's signouts
   * @returns
   */
  @requiresId
  @requiresAuth
  getSignouts(): Promise<SignoutInfo> {
    return this.do<SignoutInfo>('get', `/${this.id}/signouts`)
      .then(
        single((s: JSONType<SignoutInfo>) => ({
          acute: s.acute === null ? null : SignoutItem.fromJSON(s.acute),
        })),
      )
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a Provider's signouts: ${error.message}`,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  getVisitFiles(options: PaginationOptions): Promise<VisitFile[]> {
    const {pageSize = 10, start = 0} = options;

    return this.basicGetter<VisitFile>(
      `files/visits?pageSize=${pageSize}&start=${start}`,
      VisitFile.fromJSON,
    ).catch(response => {
      throw new BasicQueryException(
        `Something went wrong fetching patient's files for their visits: ${response}`,
      );
    });
  }

  @requiresId
  @requiresAuth
  getSCPFiles(): Promise<SCPFile[]> {
    return this.do<SCPFile[]>('get', `/${this.id}/files/scp`)
      .then(coll(SCPFile.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching patient's scp files: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  getGeneralFiles(): Promise<GeneralFile[]> {
    return this.do<GeneralFile[]>('get', `/${this.id}/files/general`)
      .then(coll(GeneralFile.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching patient's general files: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  addGeneralFile(fileInfo: AppFile): Promise<GeneralFile> {
    return this.do<GeneralFile>(
      'post',
      `/${this.id}/files/general`,
      fileInfo,
      'Something went wrong trying add a file',
    ).then(single(GeneralFile.fromJSON));
  }

  @requiresId
  @requiresAuth
  getCurrentMedicalHistory(version?: number): Promise<MedicalHistory> {
    return this.do<MedicalHistory>(
      'get',
      `${this.id}/medicalHistory/${version ?? 'latest'}`,
    ).then(single(MedicalHistory.fromJSON));
  }

  @requiresId
  @requiresAuth
  updateMedicalHistory(
    details: MedicalHistoryDetails,
  ): Promise<MedicalHistory> {
    return this.do<MedicalHistory>(
      'put',
      `${this.id}/medicalHistory`,

      details,
      'Something went wrong trying to update the medical history',
    )
      .then(single(MedicalHistory.fromJSON))
      .catch(e => {
        throw new PatientUpdateException(e);
      });
  }

  @requiresId
  @requiresAuth
  updatePaymentInformation(
    data: PaymentInformationRequestBody,
    {primary}: {primary: boolean},
  ): Promise<PaymentInformation> {
    return this.do<PaymentInformation>(
      'put',
      `${this.id}/paymentInformation/${primary ? 'primary' : 'secondary'}`,
      data,
      'Something went wrong trying find update patient payment information',
    )
      .then(single(PaymentInformation.fromJSON))
      .catch(e => {
        throw new PaymentInformationUpdateException(e);
      });
  }

  @requiresId
  @requiresAuth
  updateCreditCardInformation({
    stripeToken,
    callPoolId,
  }: {
    callPoolId: number;
    stripeToken: string;
  }): Promise<CreditCard> {
    return this.do<CreditCard>(
      'put',
      `${this.id}/paymentInformation/creditCard`,
      {stripeToken, callPoolId},
      'Something went wrong trying find update patient payment information',
    )
      .then(single(CreditCard.fromJSON))
      .catch(e => {
        throw new PaymentInformationUpdateException(e);
      });
  }

  @requiresId
  @requiresAuth
  /**
   * Returns Active Visits for a Patient that have not been resolved
   */
  getActiveVisits(): Promise<SimpleVisit[]> {
    return this.basicGetter<SimpleVisit>(
      'activeVisits',
      SimpleVisit.fromJSON,
    ).catch(error => {
      if (error instanceof HttpResponseException) {
        throw new BasicQueryException(
          `Something went wrong trying to get a the active Visits of a Patient (${this.id}): ${error.message}`,
        );
      }
      throw new ApplicationException(error);
    });
  }

  @requiresId
  @requiresAuth
  /**
   * Returns Visits for a Patient that have not been resolved
   */
  getVisits(lightweight?: boolean): Promise<SimpleVisit[]> {
    return this.do<SimpleVisit[]>(
      'get',
      `${this.id}/visits?lightweight=${lightweight ? 1 : 0}`,
    )
      .then(coll(SimpleVisit.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a the  Visits of a Patient (${this.id}): ${error.message}`,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  setFinanciallyResponsiblePerson(
    responsiblePersonId: NonProfessionalId,
    relationship: string,
  ): Promise<Patient> {
    if (!responsiblePersonId) {
      throw new ApplicationException(
        'Cannot add a ResponsiblePerson without an ID to a Patient',
      );
    }
    return this.do<Patient>(
      'put',
      `${this.id}/financiallyResponsiblePerson`,
      {
        id: responsiblePersonId,
        relationship,
      },
      'Something went wrong trying find update patient financially responsible person',
    )

      .then(single(Patient.fromJSON))
      .catch(e => {
        throw new PatientUpdateException(e);
      });
  }

  @requiresId
  @requiresAuth
  getFinanciallyResponsiblePerson(): Promise<ResponsiblePerson> {
    return this.do<ResponsiblePerson>(
      'get',
      `/${this.id}/financiallyResponsiblePerson`,
    )
      .then(single(ResponsiblePerson.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a financially ResponsiblePerson: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  findSelfRelationship(): Promise<PatientRelationship | null> {
    return this.do<PatientRelationship>('get', `/${this.id}/selfRelationship`)
      .then(single(PatientRelationship.fromJSON))
      .catch(error => {
        if (error instanceof ResourceNotFoundException) {
          return null;
        }

        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to find a self PatientRelationship: ${error.message}`,
            error.details,
          );
        }

        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  setPcp(providerDetailsId: ProviderDetailsId): Promise<LonelyPatient> {
    if (providerDetailsId === 0) {
      throw new Error('Invalid ID');
    }
    return this.do<LonelyPatient>('put', `/${this.id}/pcp/${providerDetailsId}`)
      .then(single(LonelyPatient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to set a Patient's PCP: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  removePcp(): Promise<LonelyPatient> {
    return this.do<LonelyPatient>('delete', `/${this.id}/pcp`)
      .then(single(LonelyPatient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to remove a Patient's PCP: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  createSCPWithOwner(
    providerId: UserId,
    ownershipExpiration?: Date,
    nextOwnerInfo?: DetailsRequest,
  ): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>('put', `/${this.id}/scp`, {
      ownershipExpiration,
      providerId,
      nextOwnerInfo,
    })
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to create a Shared Care Plan: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  createSCPWithoutOwner(
    currentOwner: DetailsRequest | null,
  ): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'put',
      `/${this.id}/scp/ownerless`,
      currentOwner,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to create a Shared Care Plan: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  getSCP(): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>('get', `/${this.id}/scp`)
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof ResourceNotFoundException) {
          throw error;
        }
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a Shared Care Plan: ${error.message}`,
            error.details,
          );
        }
        console.error(error.stack);
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  addProviderDetailsToCareTeam(
    providerDetailsId: ProviderDetailsId,
  ): Promise<Patient> {
    return this.do<Patient>('put', `/${this.id}/careTeam/${providerDetailsId}`)
      .then(single(Patient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to add Provider to a CareTeam: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  removeProviderDetailsFromCareTeam(
    providerDetailsId: ProviderDetailsId,
  ): Promise<Patient> {
    return this.do<Patient>(
      'delete',
      `/${this.id}/careTeam/${providerDetailsId}`,
    )
      .then(single(Patient.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to remove Provider from a CareTeam: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  getAvailableCallPools(): Promise<CallPool[]> {
    return this.do<CallPool[]>('get', `/${this.id}/availableCallPools`)
      .then(coll(CallPool.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching call pools: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  getOnDemandCallPools(): Promise<CallPool[]> {
    return this.do<CallPool[]>('get', `/${this.id}/onDemandCallPools`)
      .then(coll(CallPool.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching call pools: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  getUnsignedCallPoolDocuments(callPoolId: number): Promise<LegalDocument[]> {
    return this.do<LegalDocument[]>(
      'get',
      `/${this.id}/callPool/${callPoolId}/unsignedDocuments`,
    )
      .then(coll(LegalDocument.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching call pools: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  getForms(): Promise<AssignedForm[]> {
    return this.do<AssignedForm[]>('get', `/${this.id}/forms`)
      .then(coll(AssignedForm.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching forms: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  getIncompleteForms(): Promise<AssignedForm[]> {
    return this.do<AssignedForm[]>('get', `/${this.id}/forms/incomplete`)
      .then(coll(AssignedForm.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching incomplete forms: ${response}`,
        );
      });
  }

  @requiresId
  @requiresAuth
  addAssignedForm(assignedFormId: number): Promise<Patient> {
    return this.do<Patient>(
      'post',
      `/${this.id}/forms`,
      assignedFormId,
      'Something went wrong trying to add a form',
    ).then(single(Patient.fromJSON));
  }
}

export default PatientAPI;
