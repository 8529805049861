import {Room} from 'twilio-video';
import {onLeaveWindow} from '@src/util/videoChat';

type AttachWindowEventListeners = {
  room: Room;
  setIsDisconnecting: (v: boolean) => unknown;
};

export function attachWindowEventListeners({
  setIsDisconnecting,
  room,
}: AttachWindowEventListeners) {
  window.addEventListener('beforeunload', () => {
    onLeaveWindow({room, setIsDisconnecting});
  });

  window.addEventListener('popstate', () => {
    onLeaveWindow({room, setIsDisconnecting});
  });
}
