import {Optional} from '@ahanapediatrics/ahana-fp';
import {$num, $date, $opt} from './ResponseParser';
import {Model, User} from '.';

export class TrackedModel extends Model {
  readonly lastUpdatedBy: Optional<User>;

  constructor(model: TrackedModel) {
    super(model);
    this.lastUpdatedBy = model.lastUpdatedBy;
  }

  static template = () => ({
    id: $num,
    updatedAt: $date,
    lastUpdatedBy: $opt(User.fromJSON),
  });
}
