import {Optional} from '@ahanapediatrics/ahana-fp';
import update from 'immutability-helper';
import {ActionType, getType} from 'typesafe-actions';
import * as media from '../actions/media';

export type MediaAction = ActionType<typeof media>;
export type AudioInputInfo = MediaDeviceInfo & {kind: 'audioinput'};
export type AudioOutputInfo = MediaDeviceInfo & {kind: 'audiooutput'};
export type VideoInputInfo = MediaDeviceInfo & {kind: 'videoinput'};

export type MediaState = {
  selectedAudioOutput: Optional<AudioOutputInfo>;
  selectedAudioInput: Optional<AudioInputInfo>;
  selectedVideoInput: Optional<VideoInputInfo>;
  h264IsSupported: Optional<boolean>;
  isPublishing: boolean;
  canPublish: boolean;
  hairChecked: Record<number, boolean>;
};

const baseState: MediaState = {
  selectedAudioOutput: Optional.empty<AudioOutputInfo>(),
  selectedAudioInput: Optional.empty<AudioInputInfo>(),
  selectedVideoInput: Optional.empty<VideoInputInfo>(),
  h264IsSupported: Optional.empty(),
  isPublishing: false,
  canPublish: false,
  hairChecked: {},
};

export default (state: MediaState = baseState, action: MediaAction) => {
  switch (action.type) {
    case getType(media.setAudioInput):
      return update(state, {
        selectedAudioInput: {$set: action.payload},
      });
    case getType(media.setAudioOutput):
      return update(state, {
        selectedAudioOutput: {$set: action.payload},
      });
    case getType(media.setVideoInput):
      return update(state, {
        selectedVideoInput: {$set: action.payload},
      });
    case getType(media.setH264Supported):
      return update(state, {
        h264IsSupported: {$set: Optional.of(action.payload)},
      });
    case getType(media.setOtCanPublish):
      return update(state, {canPublish: {$set: action.payload}});
    case getType(media.setOtIsPublishing):
      return update(state, {isPublishing: {$set: action.payload}});
    case getType(media.setHairChecked):
      return update(state, {
        hairChecked: {
          [action.payload]: {
            $set: true,
          },
        },
      });
  }
  return state;
};
