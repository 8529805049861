import {AppAPI, asIs, coll, single} from './AppAPI';
import {APIWithSearch} from './APIWithSearch';
import {requiresAuth, requiresId} from './decorators';
import {
  BasicQueryException,
  HttpResponseException,
  PatientUpdateException,
} from './exceptions';
import {Resource} from './Resource';
import {
  IndependentPatientRequest,
  LinkPatientToPersonRequest,
} from '@src/util/relationships/responsiblePerson';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';
import {
  AssignedForm,
  PatientRelationship,
  Relationship,
  ResponsiblePerson,
  ResponsiblePersonRequest,
} from '@src/models';

class ResponsiblePersonAPI extends Resource
  implements APIWithSearch<ResponsiblePerson> {
  constructor(id: NonProfessionalId | undefined, api: AppAPI) {
    super(id, api, 'responsiblePeople');
  }

  /**
   * Creates a ResponsiblePerson
   */
  @requiresAuth
  create(
    payload: ResponsiblePersonRequest | IndependentPatientRequest,
  ): Promise<ResponsiblePerson> {
    return this.do<ResponsiblePerson>(
      'post',
      '',
      payload,
      'Something went wrong trying to create ResponsiblePerson',
    ).then(single(ResponsiblePerson.fromJSON));
  }

  /**
   * Get the details of the current ResponsiblePerson
   */
  @requiresId
  @requiresAuth
  get(): Promise<ResponsiblePerson> {
    return this.do<ResponsiblePerson>(
      'get',
      `${this.id}`,
      null,
      'Something went wrong trying to get ResponsiblePerson',
    ).then(single(ResponsiblePerson.fromJSON));
  }

  /**
   * Get the patient relationships connected to a Person.
   */
  @requiresAuth
  @requiresId
  getPatientRelationships(): Promise<PatientRelationship[]> {
    return this.do<PatientRelationship[]>(
      'get',
      `/${this.id}/patientRelationships`,
    ).then(coll(PatientRelationship.fromJSON));
  }

  /**
   * Updates a ResponsiblePerson
   */
  @requiresId
  @requiresAuth
  update(payload: ResponsiblePersonRequest): Promise<ResponsiblePerson> {
    return this.do<ResponsiblePerson>(
      'put',
      `${this.id}`,
      payload,
      'Something went wrong trying to update ResponsiblePerson',
    ).then(single(ResponsiblePerson.fromJSON));
  }

  /**
   * Links a Person to a Patient with relationship options
   */
  @requiresId
  @requiresAuth
  linkPersonToPatient(
    patientId: number,
    payload: LinkPatientToPersonRequest,
  ): Promise<Relationship> {
    return this.do<Relationship>(
      'put',
      `${this.id}/patients/${patientId}`,
      payload,
      'Something went wrong trying to update ResponsiblePerson',
    ).then(single(Relationship.fromJSON));
  }

  @requiresAuth
  @requiresId
  getRelationshipName(patientId: number): Promise<string> {
    if (!patientId) {
      throw new PatientUpdateException(
        'Invalid patient ID passed to `updatePatient` in the GuardianAPI',
      );
    }
    return this.do<string>(
      'get',
      `/${this.id}/patients/${patientId}/relationshipName`,
    ).then(asIs);
  }

  @requiresAuth
  search(q: string): Promise<ResponsiblePerson[]> {
    const params = new URLSearchParams();
    params.set('q', q);
    return this.do<ResponsiblePerson[]>('get', `/`, params).then(
      coll(ResponsiblePerson.fromJSON),
    );
  }

  @requiresAuth
  findByEmail(email: string): Promise<ResponsiblePerson> {
    return this.do<ResponsiblePerson>('get', `/search/${email}`).then(
      single(ResponsiblePerson.fromJSON),
    );
  }

  @requiresAuth
  @requiresId
  updateRelationshipName(
    patientId: number,
    name: string,
  ): Promise<Relationship> {
    if (!patientId) {
      throw new PatientUpdateException(
        'Invalid patient ID passed to `updatePatient` in the GuardianAPI',
      );
    }
    return this.do<Relationship>(
      'post',
      `/${this.id}/patients/${patientId}/relationshipName`,
      {
        name,
      },
    )
      .then(single(Relationship.fromJSON))
      .catch(error => {
        throw new PatientUpdateException(
          `Something went wrong trying to update a relationship with: ${
            error instanceof HttpResponseException
              ? error.message
              : 'No response from server'
          }`,
          error.details,
        );
      });
  }

  @requiresId
  @requiresAuth
  getIncompleteForms(): Promise<AssignedForm[]> {
    return this.do<AssignedForm[]>('get', `/${this.id}/forms/incomplete`)
      .then(coll(AssignedForm.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching incomplete forms: ${response}`,
        );
      });
  }
}

export default ResponsiblePersonAPI;
