import {$bool, $date, $num, $str, getParser} from './ResponseParser';
import {Model} from './Model';
import {JSONType} from '@src/app-types';

export class DateField extends Model {
  createdAt: Date;
  name: string;
  description: string;
  ordinal: number;
  required: boolean;
  blankFormId: number;

  static template = () => ({
    createdAt: $date,
    name: $str,
    description: $str,
    ordinal: $num,
    required: $bool,
    blankFormId: $num,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<DateField>) {
    return new DateField(getParser(DateField.template)(json));
  }

  constructor(props: DateField) {
    super(props);

    this.createdAt = props.createdAt;
    this.name = props.name;
    this.description = props.description;
    this.ordinal = props.ordinal;
    this.required = props.required;
    this.blankFormId = props.blankFormId;
  }
}
