import {Model} from './Model';
import {$date, $num, $str, getParser} from './ResponseParser';
import {JSONType} from '@src/app-types';

export class MultipleChoiceOption extends Model {
  createdAt: Date;
  name: string;
  description: string;
  ordinal: number;
  formMultipleChoiceFieldId: number;

  static template = () => ({
    createdAt: $date,
    name: $str,
    description: $str,
    ordinal: $num,
    formMultipleChoiceFieldId: $num,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<MultipleChoiceOption>) {
    return new MultipleChoiceOption(
      getParser(MultipleChoiceOption.template)(json),
    );
  }

  constructor(props: MultipleChoiceOption) {
    super(props);

    this.createdAt = props.createdAt;
    this.name = props.name;
    this.description = props.description;
    this.ordinal = props.ordinal;
    this.formMultipleChoiceFieldId = props.formMultipleChoiceFieldId;
  }
}
