import {Optional} from '@ahanapediatrics/ahana-fp';

const optionalSelect = (filter: (v: boolean) => boolean) => <T>(
  o: Optional<boolean>,
  ifTrue: T,
  ifNot: T,
): T =>
  o
    .filter(filter)
    .map<T>(() => ifTrue)
    .orElse(ifNot);

export const id = (b: boolean) => b;
export const flip = (b: boolean) => !b;
export const optionalYesSelect = optionalSelect(id);
export const optionalNoSelect = optionalSelect(flip);
