import {Optional} from '@ahanapediatrics/ahana-fp';
import {ChosenDeviceIds} from '@src/components/shared/VideoChat/CheckHairRoom/SessionConfigForm/MediaDeviceManager';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';

export type IDProps = {
  visitId: number;
  audioIn: Optional<AudioInputInfo>;
  videoIn: Optional<VideoInputInfo>;
  audioOut: Optional<AudioOutputInfo>;
};

const getKey = (visitId: number) => `ahana:media:${visitId}`;

/**
 * This function figures out the device IDs for the various media devices
 *
 * If some have been explicitly chosen, those are used
 * If some have been saved in the session storage, those are used
 * Otherwise, 'default' is used
 *
 */
export function getDeviceIds({
  visitId,
  audioIn,
  audioOut,
  videoIn,
}: IDProps): ChosenDeviceIds {
  const mediaChoiceJson =
    window.sessionStorage.getItem(getKey(visitId)) || '{}';
  const mediaChoice = JSON.parse(mediaChoiceJson);

  const audioInId = audioIn
    .map(a => a.deviceId)
    .orElse(mediaChoice.audioInId ?? 'default');
  const videoInId = videoIn
    .map(v => v.deviceId)
    .orElse(mediaChoice.videoInId ?? 'default');
  const audioOutId = audioOut
    .map(a => a.deviceId)
    .orElse(mediaChoice.audioOutId ?? 'default');

  return {
    audioInId,
    videoInId,
    audioOutId,
  };
}

/**
 * This saves the currently chosen device IDs into session storage
 *
 */
export function saveDeviceIds({
  visitId,
  audioInId,
  audioOutId,
  videoInId,
}: {visitId: number} & ChosenDeviceIds) {
  window.sessionStorage.setItem(
    getKey(visitId),
    JSON.stringify({
      audioInId,
      videoInId,
      audioOutId,
    }),
  );
}
