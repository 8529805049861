import {Either} from '@ahanapediatrics/ahana-fp';
import {
  BAD_REQUEST,
  CONFLICT,
  FORBIDDEN,
  NOT_FOUND,
  NO_CONTENT,
} from 'http-status-codes';
import {JSONNonArrayType, JSONType} from '../app-types';
import ConfigService from '../ConfigService';
import {Model} from '../models';
import {AppAPI, coll, paged, Paged} from './AppAPI';
import {
  BadUserInput,
  HttpResponseException,
  NotAuthorizedError,
  ResourceConflictException,
  ResourceNotFoundException,
} from './exceptions';
import {HATEOAS} from './HATEOAS';

type HttpMethod = 'get' | 'post' | 'patch' | 'delete' | 'put' | 'head';

const errorConstructors = new Map([
  [BAD_REQUEST, BadUserInput],
  [FORBIDDEN, NotAuthorizedError],
  [NOT_FOUND, ResourceNotFoundException],
  [CONFLICT, ResourceConflictException],
]);

export class Resource {
  id: number | string | undefined;
  api: AppAPI;
  apiBase: Promise<string>;
  configService: ConfigService;

  constructor(id: number | string | undefined, api: AppAPI, name: string) {
    this.id = id;
    this.api = api;
    this.apiBase = this.api.apiUrl.then(apiUrl => `${apiUrl}/${name}`);
    this.configService = ConfigService.getEnvironmentInstance();
  }

  async doHateoas<R extends Model>(
    method: HttpMethod = 'get',
    endpoint: string = '/',
    payload?: {} | URLSearchParams | null,
    errorMessage?: string | ((e: Response) => string),
  ): Promise<Either<Error, HATEOAS<R>>> {
    return this.do(method, endpoint, payload, errorMessage).then(r =>
      r.mapRight(j => j as HATEOAS<R>),
    );
  }

  /**
   * Executes an HTTP request
   * @param method The HTTP method to use
   * @param endpoint The endpoint to use
   * @param [payload] optional data to send
   * @param [errorMessage] optional data to send
   */
  async do<R extends object | object[] | number | string | boolean | void>(
    method: HttpMethod = 'get',
    endpoint: string = '/',
    payload?: {} | URLSearchParams | null,
    errorMessage?: string | ((e: Response) => string),
    noAuth: boolean = false,
  ): Promise<Either<Error, JSONType<R>>> {
    const headers = new Headers();
    if (!noAuth) {
      const token = await this.api.access_token;
      if (token) {
        headers.append('Authorization', `Bearer ${token}`);
      }
    }
    const baseURL = await this.apiBase;
    const url = new URL(
      `${baseURL}/${endpoint[0] === '/' ? endpoint.slice(1) : endpoint}`,
    );

    const requestInit: RequestInit = {
      method: method.toLocaleUpperCase(), // https://github.com/github/fetch/issues/37
      headers,
    };
    if (payload instanceof URLSearchParams) {
      payload.forEach((value, key) => {
        url.searchParams.set(key, value);
      });
    } else if (payload) {
      requestInit.body = JSON.stringify(payload);
    }

    const response = await this.api.fetch(
      new Request(url.toString()),
      requestInit,
    );

    if (response.ok) {
      if (response.status === NO_CONTENT) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Either.right({} as any);
      }
      return Either.right(await response.json());
    }

    const details: Record<string, string> = {
      location: response.headers.get('location') ?? '',
    };

    const ErrorCtor =
      errorConstructors.get(response.status) ?? HttpResponseException;

    if (errorMessage) {
      const message =
        typeof errorMessage === 'string'
          ? errorMessage
          : errorMessage(response);

      return Either.left(new ErrorCtor(message, details));
    }

    return Either.left(
      new ErrorCtor(`${response.status}`, await response.text()),
    );
  }

  /**
   * A basic getter for request a sub-resource
   *
   * @param resource
   * @returns
   */
  basicGetter<R extends object>(
    resource: string,
    mapper: (x: JSONNonArrayType<R> | JSONType<R>) => R,
  ): Promise<R[]> {
    return this.do<R[]>('get', `/${this.id}/${resource}`).then(coll(mapper));
  }

  /**
   * A basic getter for request a sub-resource
   *
   * @param resource
   * @returns
   */
  pagedGet<R extends object>(
    resource: string,
    mapper: (x: JSONNonArrayType<R> | JSONType<R>) => R,
    errorMessage: string = 'Error doing a Paged GET',
  ): Promise<Paged<R>> {
    return this.do<Paged<R>>(
      'get',
      `/${this.id}/${resource}`,
      null,
      errorMessage,
    ).then(paged(mapper));
  }
}
