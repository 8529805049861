import {useState, useCallback, useMemo} from 'react';

export type InputValue<T> = {
  value: T;
  error: string | boolean;
  touched: boolean;
  reset: () => unknown;
  set: (v: T) => unknown;
  setError: (e: string | boolean) => unknown;
  setTouched: (t: boolean) => unknown;
};

export const useInput = <T>(initial: T): InputValue<T> => {
  const [value, _setValue] = useState<T>(initial);
  const [touched, _setTouched] = useState<boolean>(false);
  const [error, _setError] = useState<string | boolean>('');

  const set = useCallback((v: T) => {
    _setValue(v);
    _setTouched(true);
  }, []);

  const reset = useCallback(() => {
    _setValue(initial);
    _setTouched(false);
    _setError('');
  }, [initial]);

  // This is necessary to ensure that this hook returns a stable
  // value
  const payload = useMemo(
    () => ({
      value,
      touched,
      error,
      set,
      reset,
      setError: _setError,
      setTouched: _setTouched,
    }),
    [value, touched, error, set, reset, _setError, _setTouched],
  );

  return payload;
};
