import {Optional} from '@ahanapediatrics/ahana-fp';

type HTMLEnhancedAudioElement = HTMLAudioElement & {
  setSinkId: (id: Optional<string>) => Promise<void>;
};

/**
 * Determine whether an HTML audio elment can have its 'sink' (ie its output)
 * set
 *
 * @param audio - The HTML Audio Element in question
 */
export const canSetAudioSink = (
  audio: HTMLAudioElement,
): audio is HTMLEnhancedAudioElement => !!audio && 'setSinkId' in audio;
