import {AppAPI} from './AppAPI';
import {requiresAuth} from './decorators';
import {BasicQueryException} from './exceptions';
import {Resource} from './Resource';

export type ProviderGroupReportRow = {
  visitUid: string;
  patientUid: string;
  insurance: string;
  start: string;
  visitLength: number;
  emCode: string;
  groupName: string;
  providerName: string;
  telemedEndpoint: string;
};

class ReportAPI extends Resource {
  constructor(api: AppAPI) {
    super(0, api, 'reports');
  }

  @requiresAuth
  async getProviderGroupReport(
    groupIds: number[],
  ): Promise<ProviderGroupReportRow[]> {
    const apiBase = await this.apiBase;

    const url = new URL(`${apiBase}/visitReport`);
    url.searchParams.append('ids', groupIds.join(','));

    try {
      return this.api
        .fetch(new Request(url.toString()), {
          headers: {
            Authorization: `Bearer ${await this.api.access_token}`,
          },
        })
        .then(response => response.json());
    } catch (error) {
      throw new BasicQueryException(
        `Something went wrong trying to get a signature to upload a file: ${error}`,
      );
    }
  }

  async getProviderGroupReportUrl(groupIds: number[]): Promise<string> {
    const apiBase = await this.apiBase;

    const url = new URL(`${apiBase}/visitReport.csv`);
    url.searchParams.append('ids', groupIds.join(','));

    return url.toString();
  }
}

export default ReportAPI;
