import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {useAuth0} from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import React, {
  useEffect,
  useCallback,
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import {addBreadcrumb, configureScope} from '@sentry/browser';
import {useResources} from './useResources';
import {User, UserType} from '@src/models';
import {useApi} from '@src/api/useApi';
import {JWTBody} from '@src/app-types';
import {UserId} from '@src/models/User';
import {email} from '@src/util/users/getDemographics';

const getUserType = (auth0User: JWTBody): UserType => {
  const permissions = auth0User?.permissions;

  if (!permissions) {
    return UserType.Unknown;
  }

  return permissions.includes('is:guardian')
    ? UserType.Guardian
    : permissions.includes('is:provider')
    ? UserType.Professional
    : permissions.includes('is:admin')
    ? UserType.Admin
    : UserType.Unknown;
};

type UserState = {
  isAnonymous: boolean;
  permissions: string[];
  setUser: (u: User) => unknown;
  user: AsyncData<User, {}>;
  userType: UserType;
};

const initialState: UserState = {
  isAnonymous: false,
  permissions: [],
  setUser: () => {},
  user: AsyncData.notAsked(),
  userType: UserType.Unknown,
};

const userContext = createContext(initialState);
const {Provider: UserStateProvider} = userContext;

type Props = {
  children: ReactNode;
};

/**
 * This is a React Provider that holds User state
 */
export const UserProvider = ({children}: Props) => {
  const api = useApi();
  const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
  const [jwt] = useResources(async () => {
    const token = await getAccessTokenSilently();
    return jwt_decode<JWTBody>(token);
  }, []);

  const requestGate = useCallback(
    () => isAuthenticated && !isLoading && jwt.isLoaded(),
    [isAuthenticated, isLoading, jwt],
  );

  const [user, , setUser] = useResources<User>(
    async () => {
      // Use jwt directly here to avoid circular reference and endless reloading
      // Do not use memoized `userType`!
      if (getUserType(jwt.singleValue()) === UserType.Admin) {
        return new User({
          id: 0 as UserId,
          phone: Optional.empty(),
          userType: UserType.Admin,
          signatures: Optional.empty(),
          updatedAt: new Date(),
          providerDetails: Optional.empty(),
          responsiblePersonDetails: Optional.empty(),
        });
      } else {
        const u = await api.getUser();

        configureScope(scope => {
          scope.setUser({
            id: `${u.id}`,
            email: email(u).orElse('unknown@vynemedical.com'),
          });
        });

        return u;
      }
    },
    [api, jwt],
    {requestGate},
  );

  const permissions = useMemo(
    () =>
      jwt
        .getOptional()
        .map(token => token.permissions ?? [])
        .orElse([]),
    [jwt],
  );

  const userType = useMemo(
    () =>
      user
        .getOptional()
        .map(u => u.userType)
        .orElse(getUserType(jwt.getOptional().orElse({} as JWTBody))),
    [jwt, user],
  );

  useEffect(() => {
    addBreadcrumb({
      data: {
        isLoading,
        isAuthenticated,
        requestInFlight: user.isLoading(),
        user,
      },
    });
  }, [isAuthenticated, isLoading, user]);

  return (
    <UserStateProvider
      value={{
        isAnonymous: !isAuthenticated && !isLoading,
        permissions,
        setUser,
        user,
        userType,
      }}
    >
      {children}
    </UserStateProvider>
  );
};

/**
 * This hook provides access to the current user (if there is one)
 *
 * It only works within the context of the UserProvider
 *
 * Here's what it returns
 *
 *  - user {AsyncData<User>} - this is the currently logged in user
 *  - userType {UserType} - this is the currently logged in users' user type
 *  - setUser {(u: User) => unknown} - this provides the ability to set the current user manually
 *  - permission {string[]} - the current user's permissions
 *  - isAnonymous {boolean} - whether or not access is by anonymous user
 *
 */
export function useUser(): [
  AsyncData<User>,
  UserType,
  (u: User) => unknown,
  string[],
  boolean,
] {
  const {user, userType, setUser, permissions, isAnonymous} = useContext(
    userContext,
  );

  return [user, userType, setUser, permissions, isAnonymous];
}
