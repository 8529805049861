import {cloneElement} from 'react';
import {ButtonSize, ButtonStyle, MuiButtonSize, MuiButtonStyle} from './types';

export const getMuiSize = (bSize: ButtonSize | MuiButtonSize): MuiButtonSize =>
  bSize === 'big' ? 'large' : bSize === 'small' ? 'small' : 'medium';

export const getMuiVariant = (
  bStyle: ButtonStyle | MuiButtonStyle,
): MuiButtonStyle =>
  bStyle === 'outlined' || bStyle === 'text'
    ? (bStyle as MuiButtonStyle)
    : 'contained';

export function convertToFullWidth(button: JSX.Element) {
  return cloneElement(button, {
    fullWidth: true,
  });
}
