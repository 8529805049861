import {Either} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {Model} from '../models';
import {Resource} from './Resource';

export const search = <T extends Model | {}>(
  ohanaApi: Resource,
  searchString: string,
  params: URLSearchParams = new URLSearchParams(),
): Promise<Either<Error, JSONType<T[]>>> => {
  params.set('q', searchString.trim() || '');
  return ohanaApi.do<T[]>(
    'get',
    '/',
    params,
    `Something went wrong trying to query for ${searchString}`,
  );
};
