import {Either} from '@ahanapediatrics/ahana-fp';
import {AppAPI, coll, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {
  ApplicationException,
  HttpResponseException,
  LegalDocumentCreationEndpoint,
  LegalSignatureException,
} from './exceptions';
import {getUploadSignature, SigntatureRequest} from './FileAPI';
import {Resource} from './Resource';
import {DocumentSignature, LegalDocument} from '@src/models';
import {$NewLegalDocument} from '@src/models/LegalDocument';

class LegalDocumentAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'legalDocuments');
  }

  @requiresAuth
  async getUploadSignature({
    objectName,
    fileName,
    contentType,
  }: SigntatureRequest): Promise<string> {
    const apiBase = await this.apiBase;
    return getUploadSignature({
      objectName,
      fileName,
      contentType,
      apiBase,
      api: this.api,
    });
  }

  @requiresAuth
  query(ids: number[] = []) {
    const params = new URLSearchParams();
    params.append('ids', ids.join(','));
    return this.do<LegalDocument[]>('get', `/`, params).then(
      coll(LegalDocument.fromJSON),
    );
  }

  /**
   * Sign a legal document
   */
  @requiresId
  @requiresAuth
  sign(patientId?: number): Promise<DocumentSignature> {
    return this.do<DocumentSignature>('post', `/${this.id}/signature`, {
      patientId,
    })
      .then(single(DocumentSignature.fromJSON))
      .catch(async (error: Either<Error, Response>) => {
        if (error instanceof HttpResponseException) {
          throw new LegalSignatureException(
            `Something went wrong trying to sign legal document: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException('Unexpected application error');
      });
  }

  /**
   * Create a legal document
   */
  @requiresAuth
  create(data: $NewLegalDocument): Promise<LegalDocument> {
    return this.do<LegalDocument>('post', ``, data)
      .then(single(LegalDocument.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new LegalDocumentCreationEndpoint(
            `Something went wrong trying to create a LegalDocument: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }
}

export default LegalDocumentAPI;
