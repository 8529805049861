import {Either} from '@ahanapediatrics/ahana-fp';
import {captureException} from '@sentry/browser';
import {CONFLICT} from 'http-status-codes';
import {JSONNonArrayType, JSONType} from '../app-types';
import {SMSNumberType} from '../components/shared/VideoChat/ExamRoomModals/SelectPhoneNumberModal';
import ConfigService from '../ConfigService';
import {Patient, User, Visit} from '../models';
import {CallPoolAPI} from './CallPoolAPI';
import ConnectionTokenAPI from './ConnectionTokenAPI';
import {requiresAuth} from './decorators';
import {
  ApplicationException,
  BasicQueryException,
  NotAuthorizedError,
  UserWithoutPhoneException,
} from './exceptions';
import FileAPI from './FileAPI';
import {getFetch} from './getFetch';
import GuardianAPI from './GuardianAPI';
import {HATEOAS} from './HATEOAS';
import LegalDocumentAPI from './LegalDocumentAPI';
import {SCPAPI} from './SCPAPI';
import OnCallAPI from './OnCallAPI';
import ClientConfigurationAPI from './ClientConfigurationAPI';
import PatientAPI from './PatientAPI';
import PracticeAPI from './PracticeAPI';
import ProviderAPI from './ProviderAPI';
import ProviderDetailsAPI from './ProviderDetailsAPI';
import ReportAPI from './ReportAPI';
import ResponsiblePersonAPI from './ResponsiblePersonAPI';
import UserAPI from './UserAPI';
import EndpointAPI from './EndpointAPI';
import StoredReportAPI from './StoredReportAPI';
import VisitAPI from './VisitAPI';
import InvoiceTargetAPI from './InvoiceTargetAPI';
import AssignedFormsAPI from './AssignedFormsAPI';
import BlankFormsAPI from './BlankFormsAPI';
import {UserFactory} from '@src/UserFactory';
import {AppConfig} from '@src/store/reducers/configuration';
import {PayloadParseError} from '@src/models/ResponseParser';
import {UserId} from '@src/models/User';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

const TYPE_RESOURCE_MAP: {[name: string]: string} = {
  Admin: 'admin',
  FrontOffice: 'frontOffices',
  Guardian: 'guardians',
  MedicalAssistant: 'medicalAssistants',
  PracticeManager: 'practiceManagers',
  Provider: 'providers',
};

const typeToResource = (type: string): string | undefined =>
  TYPE_RESOURCE_MAP[type];

/*
 * We really wanna know if there's a JSON or Payload error
 * so we're gonna log them here.
 */
const handleConversionError = (e: Error): never => {
  if (e instanceof TypeError) {
    console.error('Likely JSON error');
    console.error(e);
    captureException(e);
  }
  if (e instanceof PayloadParseError) {
    console.error(`Payload Parsing Error: ${e.message}`);
    captureException(e);
  }
  throw e;
};

export const asIs = <R>(r: Either<Error, R>) =>
  r.map<R>(handleConversionError, x => x);

export const singleH = <R>(f: (x: HATEOAS<R>) => R) => (
  r: Either<Error, HATEOAS<R>>,
) => r.map(handleConversionError, f);

export const single = <R>(f: (x: JSONType<R> | HATEOAS<R>) => R) => (
  r: Either<Error, JSONType<R>>,
) => r.map(handleConversionError, f);

export const coll = <R extends object>(
  f: (x: JSONType<R> | JSONNonArrayType<R>) => R,
) => (r: Either<Error, (JSONType<R> | JSONNonArrayType<R>)[]>) =>
  r.map(handleConversionError, o => {
    try {
      return o.map(f);
    } catch (e) {
      return handleConversionError(e); // we have to return this, even though it always throws, as TS has no way to know that
    }
  });

export type Paged<R> = {
  count: number;
  rows: R[];
};
export const paged = <R extends object>(
  f: (x: JSONType<R> | JSONNonArrayType<R>) => R,
) => (r: Either<Error, Paged<JSONType<R> | JSONNonArrayType<R>>>) =>
  r.map(handleConversionError, data => {
    try {
      return {
        rows: data.rows.map(f),
        count: data.count,
      };
    } catch (e) {
      return handleConversionError(e); // we have to return this, even though it always throws, as TS has no way to know that
    }
  });

export interface PaginationOptions<T = {}> {
  pageSize?: number;
  start?: number;
  filter?: T;
}

export class AppAPI {
  private static instance: AppAPI;
  public configService: ConfigService;
  public apiUrl: Promise<string>;
  private getToken: (options?: {}) => Promise<string> = () => {
    throw new Error('No token getter set');
  };
  private customerCode?: string;

  fetch: typeof fetch;
  clockSkew: number = 0;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new AppAPI();
    }

    return this.instance;
  }

  get access_token() {
    return this.getToken().catch(e => {
      console.warn('API could not get token');
      return '';
    });
  }

  public setTokenGetter(val: (options?: {}) => Promise<string>) {
    this.getToken = val;
  }

  public setCustomerCode(code: string) {
    this.customerCode = code;
  }

  visit(id?: number): VisitAPI {
    return new VisitAPI(id, this);
  }

  callPool(uid?: number): CallPoolAPI {
    return new CallPoolAPI(String(uid), this);
  }

  connectionToken(uid?: string): ConnectionTokenAPI {
    return new ConnectionTokenAPI(uid, this);
  }

  endpoint(id?: number): EndpointAPI {
    return new EndpointAPI(id, this);
  }

  file(id?: number): FileAPI {
    return new FileAPI(id, this);
  }

  invoiceTarget(id?: number): InvoiceTargetAPI {
    return new InvoiceTargetAPI(id, this);
  }

  legalDocument(id?: number): LegalDocumentAPI {
    return new LegalDocumentAPI(id, this);
  }

  clientConfigurations(id?: number): ClientConfigurationAPI {
    return new ClientConfigurationAPI(id, this);
  }

  scp(id?: number): SCPAPI {
    return new SCPAPI(id, this);
  }

  storedReports(id?: number): StoredReportAPI {
    return new StoredReportAPI(id, this);
  }

  onCallPeriods(id?: number): OnCallAPI {
    return new OnCallAPI(id, this);
  }

  practices(id?: number): PracticeAPI {
    return new PracticeAPI(id, this);
  }

  provider(id?: UserId): ProviderAPI {
    return new ProviderAPI(id, this);
  }

  providerDetails(id?: ProviderDetailsId): ProviderDetailsAPI {
    return new ProviderDetailsAPI(id, this);
  }

  patient(id?: number): PatientAPI {
    return new PatientAPI(id, this);
  }

  responsiblePerson(id?: NonProfessionalId): ResponsiblePersonAPI {
    return new ResponsiblePersonAPI(id, this);
  }

  guardian(id?: UserId): GuardianAPI {
    return new GuardianAPI(id, this);
  }

  reports(): ReportAPI {
    return new ReportAPI(this);
  }

  user(id?: number): UserAPI {
    return new UserAPI(id, this);
  }

  blankForms(id?: number): BlankFormsAPI {
    return new BlankFormsAPI(this);
  }

  assignedForms(id?: number): AssignedFormsAPI {
    return new AssignedFormsAPI(id, this);
  }

  async getConfiguration(): Promise<AppConfig> {
    const apiUrl = await this.apiUrl;

    return this.fetch(`${apiUrl}/configuration`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new BasicQueryException(
        `Something went wrong trying get configuration`,
      );
    });
  }

  @requiresAuth
  async getUser(): Promise<User> {
    const apiUrl = await this.apiUrl;
    return this.fetch(`${apiUrl}/users/self`, {
      headers: {
        Authorization: `Bearer ${await this.access_token}`,
      },
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        if (response.status === 401) {
          throw new NotAuthorizedError('');
        } else {
          throw new BasicQueryException(
            `Error thrown while getting user details: ${response.status}`,
          );
        }
      })
      .then(UserFactory.fromJSON);
  }

  @requiresAuth
  async getVisitsForUser(userType: string): Promise<Visit[]> {
    const apiUrl = await this.apiUrl;
    return this.fetch(`${apiUrl}/${typeToResource(userType)}/self/visits`, {
      headers: {
        Authorization: `Bearer ${await this.access_token}`,
      },
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new BasicQueryException(
          `Something went wrong trying find visits for ${userType}: ${response.status}`,
        );
      })
      .then(response => response.map(Visit.fromJSON));
  }

  @requiresAuth
  async getPatientsForUser(userType: string): Promise<Patient[]> {
    const apiUrl = await this.apiUrl;

    return this.fetch(`${apiUrl}/${typeToResource(userType)}/self/patients`, {
      headers: {
        Authorization: `Bearer ${await this.access_token}`,
      },
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new BasicQueryException(
          `Something went wrong trying find patients for ${userType}: ${response.status}`,
        );
      })
      .then(response => response.map(Patient.fromJSON));
  }

  @requiresAuth
  async saveUserOperations(operations: {
    timeOnSite: number;
    visitOccurred: boolean;
    surveySkipped: boolean;
  }): Promise<void> {
    const apiUrl = await this.apiUrl;
    return this.fetch(`${apiUrl}/logout`, {
      method: 'POST',
      body: JSON.stringify(operations),
      headers: {
        Authorization: `Bearer ${await this.access_token}`,
      },
      mode: 'cors',
      credentials: 'include',
    }).then(response => {
      if (!response.ok) {
        console.error(response);
      }
    });
  }

  @requiresAuth
  async sendOhanaConnectSMS(
    numberType: SMSNumberType,
    visitId: number,
  ): Promise<{}> {
    const apiUrl = await this.apiUrl;
    return this.fetch(`${apiUrl}/connect-sms`, {
      method: 'POST',
      body: JSON.stringify({numberType, visitId}),
      headers: {
        Authorization: `Bearer ${await this.access_token}`,
      },
      mode: 'cors',
      credentials: 'include',
    }).then(response => {
      if (response.ok) {
        return {};
      }
      if (response.status === CONFLICT) {
        throw new UserWithoutPhoneException(
          'Guardian does not have a phone number',
        );
      }
      const applicationException = new ApplicationException(
        'Something went wrong trying to send OhanaConnect SMS',
      );
      captureException(applicationException);
      throw applicationException;
    });
  }

  @requiresAuth
  async sendDirectConnectSMS(
    numberType: SMSNumberType,
    visitId: number,
    connectionTokenUUID: string,
  ): Promise<{}> {
    const apiUrl = await this.apiUrl;
    return this.fetch(`${apiUrl}/direct-connect-sms`, {
      method: 'POST',
      body: JSON.stringify({
        numberType,
        connectionTokenUUID,
        visitId,
      }),
      headers: {
        Authorization: `Bearer ${await this.access_token}`,
      },
      mode: 'cors',
      credentials: 'include',
    }).then(response => {
      if (response.ok) {
        return {};
      }

      if (response.status === CONFLICT) {
        throw new UserWithoutPhoneException(
          'Guardian does not have a phone number',
        );
      }
      const applicationException = new ApplicationException(
        'Something went wrong trying to send OhanaConnect SMS',
      );
      captureException(applicationException);
      throw applicationException;
    });
  }

  /**
   *
   */
  private constructor() {
    this.configService = ConfigService.getEnvironmentInstance();

    this.apiUrl = this.configService.get('API_URL').then(url => url || '');

    this.fetch = getFetch(this.setHeaders.bind(this));
  }

  private async setHeaders(headers: Headers) {
    const access_token = await this.access_token;

    if (headers.has('Authorization')) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }

    if (this.customerCode) {
      headers.set('X-Ahana-Customer', this.customerCode);
    }

    return headers;
  }
}
