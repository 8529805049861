import {Optional} from '@ahanapediatrics/ahana-fp';
import {Opaque} from 'ts-essentials';
import {JSONType} from '../app-types';
import {$opt, $phone, $arr, $enum, getParser, $opaque} from './ResponseParser';
import {DocumentSignature, Model, ProviderDetails, ResponsiblePerson} from '.';

export enum UserType {
  Unknown = 'Unknown',
  // The following is not an error. It's a first step in clearing up our nomenclature
  Professional = 'Provider',
  Guardian = 'Guardian',
  Admin = 'Admin',
  System = 'System',
}

export type UserId = Opaque<number, 'UserId'>;

export class User extends Model {
  id!: UserId;
  phone: Optional<string>;
  userType: UserType;
  signatures: Optional<DocumentSignature[]>;
  providerDetails: Optional<ProviderDetails>;
  responsiblePersonDetails: Optional<ResponsiblePerson>;

  static template = () => ({
    phone: $opt($phone),
    userType: $enum(UserType),
    signatures: $opt($arr(DocumentSignature.fromJSON)),
    providerDetails: $opt(ProviderDetails.fromJSON),
    responsiblePersonDetails: $opt(ResponsiblePerson.fromJSON),
    ...Model.template(),
    id: $opaque<UserId>(),
  });

  static fromJSON(json: JSONType<User>) {
    return new User(getParser(User.template)(json));
  }

  constructor(props: User) {
    super(props);

    this.phone = props.phone || Optional.empty();
    this.userType = props.userType || UserType.Unknown;
    this.signatures = props.signatures || [];
    this.providerDetails = props.providerDetails;
    this.responsiblePersonDetails = props.responsiblePersonDetails;
  }
}
