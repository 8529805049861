/**
 * Detect whether current browser is an iOS device
 */
export const isIOS = (): boolean =>
  !!navigator.platform &&
  ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;

export function isMobileOperatingSystem(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (
    /windows phone/i.test(userAgent) ||
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  ) {
    return true;
  } else {
    return false;
  }
}
