import {rqString, yObject, yString} from '@src/schema/types';

export type StreetAddress = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipcode: string;
};

export const streetAddressSchema = yObject<StreetAddress>({
  line1: rqString('Please provide an address'),
  line2: yString,
  city: rqString('Please provide a city for this address'),
  state: rqString('Please provide a statefor this address'),
  zipcode: rqString('Please provide a zip code for this address').min(5),
});

export const getEmptyAddress = (): StreetAddress => ({
  line1: '',
  line2: '',
  city: '',
  state: '',
  zipcode: '',
});

export const getDefaultStreetAddress = getEmptyAddress;

export const convertPlaceToAddress = (
  place: google.maps.places.PlaceResult,
): StreetAddress => {
  const streetAddress = {} as StreetAddress;
  const {address_components = []} = place;
  let number = '';
  let route = '';
  address_components.forEach(comp => {
    const {types, long_name, short_name} = comp;
    if (types.includes('postal_code')) {
      streetAddress.zipcode = long_name;
    }
    if (types.includes('administrative_area_level_1')) {
      streetAddress.state = short_name;
    }
    if (types.includes('locality')) {
      streetAddress.city = long_name;
    }
    if (types.includes('route')) {
      route = short_name;
      streetAddress.line1 = `${number} ${route}`;
    }
    if (types.includes('street_number')) {
      number = short_name;
      streetAddress.line1 = `${number} ${route}`;
    }
  });
  return streetAddress;
};
