import {Optional} from '@ahanapediatrics/ahana-fp';
import {$arr, $date, $num, $opt, $str, getParser} from './ResponseParser';
import {BlankForm} from './BlankForm';
import {Model} from './Model';
import {DateResponse} from './DateResponse';
import {MultipleChoiceResponse} from './MultipleChoiceResponse';
import {TextResponse} from './TextResponse';
import {NumericResponse} from './NumericResponse';
import {JSONType} from '@src/app-types';

export class AssignedForm extends Model {
  blankForm: Optional<BlankForm>;
  blankFormId: number;
  completedAt: Optional<Date>;
  creatorId: number;
  visitId: Optional<number>;
  patientId: Optional<number>;
  signatureName: Optional<string>;
  signatureFont: Optional<string>;
  signatureImageUri: Optional<string>;
  signedAt: Optional<Date>;
  dateResponses: Optional<DateResponse[]>;
  multipleChoiceResponses: Optional<MultipleChoiceResponse[]>;
  textResponses: Optional<TextResponse[]>;
  numericResponses: Optional<NumericResponse[]>;

  static template = () => ({
    blankForm: $opt(BlankForm.fromJSON),
    blankFormId: $num,
    completedAt: $opt($date),
    creatorId: $num,
    visitId: $opt($num),
    patientId: $opt($num),
    signatureName: $opt($str),
    signatureFont: $opt($str),
    signatureImageUri: $opt($str),
    signedAt: $opt($date),
    dateResponses: $opt($arr(DateResponse.fromJSON)),
    multipleChoiceResponses: $opt($arr(MultipleChoiceResponse.fromJSON)),
    textResponses: $opt($arr(TextResponse.fromJSON)),
    numericResponses: $opt($arr(NumericResponse.fromJSON)),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<AssignedForm>) {
    return new AssignedForm(getParser(AssignedForm.template)(json));
  }

  constructor(props: AssignedForm) {
    super(props);
    this.blankFormId = props.blankFormId;
    this.completedAt = props.completedAt;
    this.creatorId = props.creatorId;
    this.visitId = Optional.of(props.visitId);
    this.patientId = Optional.of(props.patientId);
    this.signatureName = props.signatureName;
    this.signatureFont = props.signatureFont;
    this.signatureImageUri = props.signatureImageUri;
    this.signedAt = props.signedAt;

    this.blankForm = Optional.of(props.blankForm);

    this.dateResponses = Optional.of(props.dateResponses);
    this.multipleChoiceResponses = Optional.of(props.multipleChoiceResponses);
    this.textResponses = Optional.of(props.textResponses);
    this.numericResponses = Optional.of(props.numericResponses);
  }
}
