import {AppAPI} from '@src/api/AppAPI';

export type GetTwilioParticipantsProps = {
  api: AppAPI;
  visitId: number;
};

export function getTwilioParticipants({
  api,
  visitId,
}: GetTwilioParticipantsProps) {
  return api.visit(visitId).getTwilioParticipants();
}
