import {Either, Optional} from '@ahanapediatrics/ahana-fp';
import {LocalAudioTrack, LocalTrack, LocalVideoTrack} from 'twilio-video';
import {getStream} from '@src/components/shared/VideoChat/CheckHairRoom/SessionConfigForm/functions';
import {ChosenDeviceIds} from '@src/components/shared/VideoChat/CheckHairRoom/SessionConfigForm/MediaDeviceManager';
import {MediaFailure} from '@src/util/videoChat/userMedia/streams';

export const createLocalTwilioTracksFromInputs = (
  onMediaFailure: (v: Optional<MediaFailure>) => unknown,
) => async ({audioInId, videoInId}: ChosenDeviceIds): Promise<LocalTrack[]> => {
  let audioTrack;
  let videoTrack;

  await createTwilioTrack(videoInId, 'video').then(e =>
    e.apply(
      failure => onMediaFailure(Optional.of(failure)),
      async t => {
        audioTrack = t as LocalTrack;
      },
    ),
  );

  await createTwilioTrack(audioInId, 'audio').then(e =>
    e.apply(
      failure => onMediaFailure(Optional.of(failure)),
      async t => {
        videoTrack = t as LocalTrack;
      },
    ),
  );

  // @ts-ignore
  return [audioTrack, videoTrack].filter(t => !!t) as LocalTrack[];
};

async function createTwilioTrack(
  deviceId: string,
  type: 'audio' | 'video',
): Promise<Either<MediaFailure, LocalAudioTrack | LocalVideoTrack | null>> {
  console.debug(`getStream: ${type} ${deviceId}`);

  return (await getStream(type)(deviceId)).mapRight((s: MediaStream) => {
    const mediaTrack = s.getTracks().filter(t => t.kind === type)[0];

    try {
      return type === 'video'
        ? new LocalVideoTrack(mediaTrack)
        : new LocalAudioTrack(mediaTrack);
    } catch (e) {
      console.error(
        `{${Date.now()}}: Creating local ${type} track: [${
          mediaTrack.id
        }] failed`,
      );
      console.error(e);
      return null;
    }
  });
}
