import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {Addendum} from './Addendum';
import {Model} from './Model';
import {
  $bool,
  $str,
  $arr,
  $obj,
  $enum,
  $opt,
  getParser,
} from './ResponseParser';
import {
  ExamCode,
  SystemCode,
} from '@src/components/providerSide/VisitDocumentationForm/sections/systems';
import {Professional} from '@src/models';

export type Exam = {
  code: ExamCode;
  remarks: string;
};

export type ReviewOfSystem = {
  code: SystemCode;
  remarks: string;
  positive: boolean | null;
};

export type ICD10Code = {
  id: string;
  code: string;
  description: string;
};

export enum ImmunizationStatus {
  Yes = 'yes',
  No = 'no',
  NotSure = 'not_sure',
}

interface Signature {
  signingDate: string;
  signer: Professional;
}

export class VisitDocumentation extends Model {
  'signing': boolean;
  'reviewOfSystems': ReviewOfSystem[];
  'red_flag': boolean;
  'red_flag_text': string;
  'hpi': string;
  'chief_complaint': string;
  'plan_followup_other': string;
  'plan_follow_up': string;
  'plan_what_now': string;
  'plan_medications_start': string;
  'plan_medications_continue': string;
  'other': string;
  'family_history': string;
  'past_surgeries': string;
  'past_health_issues': string;
  'exams': Exam[];
  'immunizations_up_to_date': ImmunizationStatus;
  'immunization_details': string;
  'allergies': string;
  'medications': string;
  'billing_code': string;
  'icd10_codes': ICD10Code[];
  'general': string;
  signature: Optional<Signature>;
  addendums: Optional<Addendum[]>;

  static template = () => ({
    signing: $bool,
    reviewOfSystems: $arr($obj<ReviewOfSystem>()),
    red_flag: $bool,
    red_flag_text: $str,
    hpi: $str,
    chief_complaint: $str,
    plan_followup_other: $str,
    plan_follow_up: $str,
    plan_what_now: $str,
    plan_medications_start: $str,
    plan_medications_continue: $str,
    other: $str,
    family_history: $str,
    past_surgeries: $str,
    past_health_issues: $str,
    exams: $arr($obj<Exam>()),
    immunizations_up_to_date: $enum(ImmunizationStatus),
    immunization_details: $str,
    allergies: $str,
    medications: $str,
    billing_code: $str,
    icd10_codes: $arr($obj<ICD10Code>()),
    general: $str,
    signature: $opt($obj<Signature>()),
    addendums: $opt($arr(Addendum.fromJSON)),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<VisitDocumentation>) {
    return new VisitDocumentation(getParser(VisitDocumentation.template)(json));
  }

  constructor(doc: VisitDocumentation) {
    super(doc);

    this.signing = doc.signing || false;
    this.reviewOfSystems = doc.reviewOfSystems || [];
    this.red_flag = doc.red_flag || false;
    this.red_flag_text = doc.red_flag_text || '';
    this.hpi = doc.hpi || '';
    this.chief_complaint = doc.chief_complaint || '';
    this.plan_followup_other = doc.plan_followup_other || '';
    this.plan_follow_up = doc.plan_follow_up || '';
    this.plan_what_now = doc.plan_what_now || '';
    this.plan_medications_start = doc.plan_medications_start || '';
    this.plan_medications_continue = doc.plan_medications_continue || '';
    this.other = doc.other || '';
    this.family_history = doc.family_history || '';
    this.past_surgeries = doc.past_surgeries || '';
    this.past_health_issues = doc.past_health_issues || '';
    this.exams = doc.exams || [];
    this.immunizations_up_to_date =
      doc.immunizations_up_to_date || ImmunizationStatus.NotSure;
    this.immunization_details = doc.immunization_details || '';
    this.allergies = doc.allergies || '';
    this.medications = doc.medications || '';
    this.billing_code = doc.billing_code || '';
    this.icd10_codes = doc.icd10_codes || [];
    this.general = doc.general || '';
    this.signature = doc.signature;
    this.addendums = doc.addendums;
  }
}
