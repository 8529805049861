import {AppAPI, asIs, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {ApplicationException} from './exceptions';
import {Resource} from './Resource';
import {AssignedForm} from '@src/models';

interface UpdateAssignedFormRequest {
  signedAt?: Date | null;
  signatureName?: string | null;
  signatureFont?: string | null;
  signatureImageUri?: string | null;
  multipleChoiceResponses: Array<{
    id: number;
    formMultipleChoiceOptionIds: number[];
  }>;
}

class AssignedFormsAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'assignedForms');
  }

  /**
   * Get an assigned form by ID
   */
  @requiresAuth
  @requiresId
  get(): Promise<AssignedForm> {
    return this.do<AssignedForm>('get', `/${this.id}`)
      .then(single(AssignedForm.fromJSON))
      .catch(e => {
        throw new ApplicationException(e);
      });
  }

  @requiresAuth
  @requiresId
  update(data: UpdateAssignedFormRequest): Promise<AssignedForm> {
    return this.do<AssignedForm>(
      'put',
      `/${this.id}`,
      data,
      'Something went wrong trying to update an Assigned Form',
    )
      .then(single(AssignedForm.fromJSON))
      .catch(e => {
        throw new ApplicationException(e);
      });
  }

  @requiresAuth
  @requiresId
  submit(data?: UpdateAssignedFormRequest): Promise<AssignedForm> {
    return this.do<AssignedForm>(
      'post',
      `/${this.id}/submit`,
      data,
      'Something went wrong trying to submit an Assigned Form',
    )
      .then(single(AssignedForm.fromJSON))
      .catch(e => {
        throw new ApplicationException(e);
      });
  }

  /**
   * Delete an assigned form by ID
   */
  @requiresAuth
  @requiresId
  async delete(): Promise<boolean> {
    return this.do<boolean>('delete', `/${this.id}`).then(asIs);
  }
}

export default AssignedFormsAPI;
