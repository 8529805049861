import {Optional} from '@ahanapediatrics/ahana-fp';
import {$bool, $date, $num, $opt, $str, getParser} from './ResponseParser';
import {Model} from './Model';
import {JSONType} from '@src/app-types';

export class TextField extends Model {
  createdAt: Date;
  name: string;
  description: string;
  ordinal: number;
  required: boolean;
  validation: Optional<string>;
  blankFormId: number;

  static template = () => ({
    createdAt: $date,
    name: $str,
    description: $str,
    ordinal: $num,
    required: $bool,
    blankFormId: $num,
    validation: $opt($str),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<TextField>) {
    return new TextField(getParser(TextField.template)(json));
  }

  constructor(props: TextField) {
    super(props);

    this.createdAt = props.createdAt;
    this.name = props.name;
    this.description = props.description;
    this.ordinal = props.ordinal;
    this.required = props.required;
    this.validation = Optional.of(props.validation);
    this.blankFormId = props.blankFormId;
  }
}
