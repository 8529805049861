import React, {ReactNode, useState} from 'react';
import {Button} from '../form';
import {MuiAsyncActionButton} from '../form/MuiAsyncActionButton';
import {Modal} from './Modal';

type Props = {
  show: boolean;
  onConfirm: () => Promise<unknown>;
  onCancel: () => void;
  onHide: () => void;
  text: string;
  children?: ReactNode | null;
  confirmText: string;
  cancelText?: string;
  titleText?: string;
};

export function ConfirmModal({
  show,
  onConfirm,
  onCancel,
  onHide,
  text,
  confirmText,
  cancelText,
  titleText,
  children = null,
}: Props) {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      show={show}
      title={titleText ?? 'Are you sure?'}
      onClose={() => {
        onHide();
        onCancel();
      }}
      modalActions={
        <>
          <MuiAsyncActionButton
            bStyle="danger"
            bSize="small"
            onClick={() => {
              setSubmitting(true);
              onConfirm().then(() => {
                setSubmitting(false);
                onHide();
              });
            }}
            actionInProgress={submitting}
            actionWord={confirmText}
            isInRow={true}
          />
          {cancelText && (
            <Button
              onClick={() => {
                onHide();
                onCancel();
              }}
            >
              {cancelText}
            </Button>
          )}
        </>
      }
    >
      {text}
      {children}
    </Modal>
  );
}
