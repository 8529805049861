import {addMinutes} from 'date-fns';
import Cookies from 'cookies-js';
import {PayloadForAnonymousVisitor} from '@src/util/videoChat/twilio/anonymousVisitor';

export function setAnonymousVisitorCookie({
  firstName,
  lastName,
  patientName,
  visitorConfirmedAuthorization,
}: PayloadForAnonymousVisitor): void {
  const expires = addMinutes(new Date(), 15);
  Cookies.set('firstName', firstName, {expires});
  Cookies.set('lastName', lastName, {expires});
  Cookies.set('patientName', patientName, {expires});
  Cookies.set('visitorConfirmedAuthorization', visitorConfirmedAuthorization, {
    expires,
  });
}

export function getAnonymousVisitorCookie(): PayloadForAnonymousVisitor | null {
  const firstName = Cookies.get('firstName');
  const lastName = Cookies.get('lastName');
  const patientName = Cookies.get('patientName');
  const visitorConfirmedAuthorization = Cookies.get(
    'visitorConfirmedAuthorization',
  );

  if (!(firstName && lastName && visitorConfirmedAuthorization === 'true')) {
    return null;
  }

  return {
    firstName,
    lastName,
    patientName,
    visitorConfirmedAuthorization: true,
  };
}
