import {Optional} from '@ahanapediatrics/ahana-fp';
import {SimpleVisit, Endpoint, EndpointId} from '@src/models';
import {EndpointType} from '@src/models/Endpoint';

const getDefaultEndpoint = () =>
  new Endpoint({
    id: 0 as EndpointId,
    type: EndpointType.Ahana,
    name: 'Ahana',
    nonProfessionalInstructions: '',
    professionalInstructions: '',
    url: Optional.empty(),
    updatedAt: new Date(),
  });

export const getEndpoint = ({endpoint}: SimpleVisit): Endpoint =>
  endpoint.orElse(getDefaultEndpoint());

export const getEndpointUrl = (visit: SimpleVisit): Optional<string> =>
  getEndpoint(visit).url;

export const getDisplayNameByType = (endpointType: EndpointType): string => {
  if (endpointType === EndpointType.Agnes) {
    return 'AGNES';
  } else if (endpointType === EndpointType.AMDTablet) {
    return 'AMD Tablet';
  } else {
    return 'Ahana';
  }
};
