import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$opt, $arr, getParser} from './ResponseParser';
import {ProviderDetails, User, UserType, Certification} from '.';
import {HATEOAS} from '@src/api/HATEOAS';

export const toProfessional = (u: User | Professional): u is Professional =>
  u.userType === UserType.Professional;
export class Professional extends User {
  links?: {
    self: {href: string};
    scps: {href: string};
    callPools: {href: string}[];
  };
  certifications: Optional<Certification[]>;
  providerDetails: Optional<ProviderDetails>;

  constructor(props: Omit<Professional, 'info'>) {
    super(props);

    this.certifications = Optional.of(props.certifications);
    this.providerDetails = Optional.of(props.providerDetails);
  }

  static template = () => ({
    ...User.template(),
    providerDetails: $opt(ProviderDetails.fromJSON),
    certifications: $opt($arr(Certification.fromJSON)),
  });

  static fromHATEOAS(hateoas: HATEOAS<Professional>) {
    const provider = Professional.fromJSON(hateoas);
    provider.links = hateoas._links as Professional['links'];
    return provider;
  }

  static fromJSON(json: JSONType<Professional>) {
    return new Professional(getParser(Professional.template)(json));
  }

  static fromUser(u: User) {
    if (u.userType !== UserType.Professional) {
      throw new Error('Cannot create a Provider from this user');
    }

    return new Professional(u as Professional);
  }
}
