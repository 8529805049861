/**
 * This helps standardize the various names we use in the UI
 * given that they change often.
 * The values assigned to the keys are the only values we
 * currently want to show to customers in the UI.
 */

export const NAMES = {
  // People
  provider: 'professional',
  professional: 'professional',
  guardian: 'non-professional',
  user: 'user',

  // Groups/CallPools
  callPool: 'group',
  group: 'group',

  // InvoiceTargets/Customers
  invoiceTarget: 'customer',
  customer: 'customer',
  customerCode: 'customer code',

  // Periods
  onCallPeriod: 'shift',

  // Shared Care Plan
  scp: 'Shared Care Plan',

  // Documents/Forms/Files
  legalDocument: 'form',
  document: 'form',
  file: 'file',

  // Visits
  visit: 'visit',

  // Endpoints
  endpoint: 'endpoint',
};
