import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';

import {
  $str,
  $phone,
  $date,
  $num,
  $opt,
  $arr,
  $bool,
  getParser,
} from './ResponseParser';
import {Invitation} from './Invitation';
import {GeneralFile, SCPFile, VisitFile} from './AppFile';
import {
  CareTeamMembership,
  CreditCard,
  PaymentInformation,
  ProviderDetails,
  TrackedModel,
  PatientRelationship,
  ResponsiblePerson,
  LonelyVisit,
} from '.';
import {DetailsRequest} from '@src/api/ProviderDetailsAPI';
import {ConnectedLoginsResult} from '@src/components/providerSide/MergePatients/shared/types';

export interface PaymentInformationRequestBody {
  hasHealthInsurance: boolean;
  insuranceProvider: string;
  insuranceId: string;
  insuranceGroup: string;
  hasMedicaid: boolean;
  medicaidEmail: string;
  frontOfCard: string;
  backOfCard: string;
  clearCard: boolean;
  skipCreditCard: boolean;
  stripeToken: string;
}

export type $NewPatient = {
  firstName: string;
  lastName: string;
  nickName: string;
  pronouns: string;
  genderAssignedAtBirth: string;
  phone: string;
  dob: Date | null;
  details: Optional<DetailsRequest>;
};

/** MERGE REQUEST TYPES */
export type FinanciallyResponsiblePerson = {
  personId: number;
  relationshipName: string;
};

export type PaymentInformationResult = {
  primary?: PaymentInformation;
  secondary?: PaymentInformation;
};

export type PatientMergeRequest = {
  mergedPatientDetails: Omit<$NewPatient, 'details'>;
  duplicatePatientId: number;
  pcpDetailsId: number;
  financiallyResponsiblePerson: FinanciallyResponsiblePerson;
  guardianships: ConnectedLoginsResult;
  paymentInformation: PaymentInformationResult;
};

export class MergeOptionsFinanciallyResponsible {
  nameOptions: string[];
  personOptions: ResponsiblePerson[];

  static template = () => ({
    nameOptions: $arr($str),
    personOptions: $arr(ResponsiblePerson.fromJSON),
  });

  static fromJSON(json: JSONType<MergeOptionsFinanciallyResponsible>) {
    return new MergeOptionsFinanciallyResponsible(
      getParser(MergeOptionsFinanciallyResponsible.template)(json),
    );
  }

  constructor(props: MergeOptionsFinanciallyResponsible) {
    this.nameOptions = props.nameOptions;
    this.personOptions = props.personOptions;
  }
}

class MergeOptionsFiles {
  generalFiles: GeneralFile[];
  scpFiles: SCPFile[];
  visitFiles: VisitFile[];

  static template = () => ({
    generalFiles: $arr(GeneralFile.fromJSON),
    scpFiles: $arr(SCPFile.fromJSON),
    visitFiles: $arr(VisitFile.fromJSON),
  });

  static fromJSON(json: JSONType<MergeOptionsFiles>) {
    return new MergeOptionsFiles(getParser(MergeOptionsFiles.template)(json));
  }

  constructor(props: MergeOptionsFiles) {
    this.generalFiles = props.generalFiles;
    this.scpFiles = props.scpFiles;
    this.visitFiles = props.visitFiles;
  }
}

export class MergeOptionsGuardian {
  nameOptions: string[];
  person: ResponsiblePerson;

  static template = () => ({
    nameOptions: $arr($str),
    person: ResponsiblePerson.fromJSON,
  });

  static fromJSON(json: JSONType<MergeOptionsGuardian>) {
    return new MergeOptionsGuardian(
      getParser(MergeOptionsGuardian.template)(json),
    );
  }

  constructor(props: MergeOptionsGuardian) {
    this.nameOptions = props.nameOptions;
    this.person = props.person;
  }
}

export class MergeOptions {
  financiallyResponsible: MergeOptionsFinanciallyResponsible;
  guardianships: MergeOptionsGuardian[];
  visits: LonelyVisit[];
  careTeam: ProviderDetails[];
  files: MergeOptionsFiles;
  paymentInfos: PaymentInformation[];
  creditCards: CreditCard[];

  static template = () => ({
    financiallyResponsible: MergeOptionsFinanciallyResponsible.fromJSON,
    guardianships: $arr(MergeOptionsGuardian.fromJSON),
    visits: $arr(LonelyVisit.fromJSON),
    careTeam: $arr(ProviderDetails.fromJSON),
    files: MergeOptionsFiles.fromJSON,
    paymentInfos: $arr(PaymentInformation.fromJSON),
    creditCards: $arr(CreditCard.fromJSON),
  });

  static fromJSON(json: JSONType<MergeOptions>) {
    return new MergeOptions(getParser(MergeOptions.template)(json));
  }

  constructor(props: MergeOptions) {
    this.financiallyResponsible = props.financiallyResponsible;
    this.guardianships = props.guardianships;
    this.visits = props.visits;
    this.careTeam = props.careTeam;
    this.files = props.files;
    this.paymentInfos = props.paymentInfos;
    this.creditCards = props.creditCards;
  }
}

export const getEmptyMergeOptions = () =>
  new MergeOptions({
    financiallyResponsible: {nameOptions: [], personOptions: []},
    visits: [],
    guardianships: [],
    careTeam: [],
    files: {
      generalFiles: [],
      scpFiles: [],
      visitFiles: [],
    },
    paymentInfos: [],
    creditCards: [],
  });

export type GuardianPatientRequest = $NewPatient & {relationship: string};

/**
 * A LonelyPatient is just a Patient with no guardianships defined
 * in the API response
 */
export class LonelyPatient extends TrackedModel {
  uid: string;
  fullName: string;
  firstName: string;
  lastName: string;
  nickName: string;
  phone: string;
  dob: Date;
  preferredName: string;
  preferredFirstName: string;
  pronouns: string;
  genderAssignedAtBirth: string;
  invitations: Invitation[];
  medicalHistoryVersion: number;
  paymentInformation: Optional<PaymentInformation>;
  secondaryPayment: Optional<PaymentInformation>;
  creditCards: CreditCard[];
  pcp: Optional<ProviderDetails>;
  careTeam: CareTeamMembership[];
  blockedForNonPayment: boolean;
  hasScp: boolean;
  updatedAt: Date;

  static template = () => ({
    uid: $str,
    fullName: $str,
    firstName: $str,
    lastName: $str,
    nickName: $str,
    phone: $phone,
    dob: $date,
    preferredName: $str,
    pronouns: $str,
    genderAssignedAtBirth: $str,
    invitations: $arr(Invitation.fromJSON),
    preferredFirstName: $str,
    medicalHistoryVersion: $num,
    paymentInformation: $opt(PaymentInformation.fromJSON),
    secondaryPayment: $opt(PaymentInformation.fromJSON),
    creditCards: $arr(CreditCard.fromJSON),
    pcp: $opt(ProviderDetails.fromJSON),
    careTeam: $arr(CareTeamMembership.fromJSON),
    blockedForNonPayment: $bool,
    hasScp: $bool,
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<LonelyPatient>): LonelyPatient {
    return new LonelyPatient(getParser(LonelyPatient.template)(json));
  }

  constructor(props: LonelyPatient) {
    super(props);

    this.uid = props.uid;
    this.blockedForNonPayment = props.blockedForNonPayment ?? false;
    this.dob = new Date(props.dob);
    this.careTeam = props.careTeam;
    this.firstName = props.firstName;
    this.fullName = props.fullName;
    this.hasScp = props.hasScp ?? false;
    this.lastName = props.lastName;
    this.invitations = props.invitations;
    this.medicalHistoryVersion = props.medicalHistoryVersion;
    this.nickName = props.nickName;
    this.paymentInformation = props.paymentInformation;
    this.secondaryPayment = props.secondaryPayment;
    this.creditCards = props.creditCards;
    this.pcp = props.pcp;
    this.phone = props.phone;
    this.preferredName = props.preferredName;
    this.preferredFirstName = props.preferredFirstName;
    this.pronouns = props.pronouns;
    this.genderAssignedAtBirth = props.genderAssignedAtBirth;
    this.updatedAt = props.updatedAt;
  }
}

type Getters =
  | 'financialRelationship'
  | 'guardianRelationships'
  | 'unapprovedGuardians'
  | 'selfRelationship';
export class Patient extends LonelyPatient {
  relationships: PatientRelationship[];

  static template = () => ({
    relationships: $arr(PatientRelationship.fromJSON),
    ...LonelyPatient.template(),
  });

  static fromJSON(json: Omit<JSONType<Patient>, Getters>): Patient {
    return new Patient(getParser(Patient.template)(json));
  }

  constructor(props: Omit<Patient, Getters>) {
    super(props);
    this.relationships = props.relationships;
  }

  get guardianRelationships(): ReadonlyArray<PatientRelationship> {
    return this.relationships.filter(g => g.isGuardian);
  }

  get financialRelationship(): Optional<PatientRelationship> {
    return Optional.of(
      this.relationships.find(g => g.isFinanciallyResponsible),
    );
  }

  get selfRelationship(): Optional<PatientRelationship> {
    return Optional.of(this.relationships.find(g => g.isSelf));
  }
}
