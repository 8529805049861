import {JSONType} from '../app-types';
import {Modifications, modificationsFromJson} from './ModificationRecord';
import {$num, getParser} from './ResponseParser';
import {ImmunizationStatus, Model} from '.';

export type MedicalHistoryDetails = {
  surgeries: string;
  environment: string;
  allergies: string;
  past_or_ongoing_health_issues: string;
  medical_history: string;
  immunizations_up_to_date: ImmunizationStatus;
  immunization_comments: string;
  medications: string;
  other: string;
};

export const medicalHistoryDetailsFromJson = (
  json: JSONType<Partial<MedicalHistoryDetails>>,
): MedicalHistoryDetails => ({
  surgeries: json.surgeries || '',
  environment: json.environment || '',
  allergies: json.allergies || '',
  past_or_ongoing_health_issues: json.past_or_ongoing_health_issues || '',
  medical_history: json.medical_history || '',
  immunizations_up_to_date: (json.immunizations_up_to_date ||
    ImmunizationStatus.NotSure) as ImmunizationStatus,
  immunization_comments: json.immunization_comments || '',
  medications: json.medications || '',
  other: json.other || '',
});

export class MedicalHistory extends Model {
  versionNumber: number;
  details: MedicalHistoryDetails;
  modifications: Modifications<MedicalHistoryDetails>;

  static template = () => ({
    versionNumber: $num,
    details: medicalHistoryDetailsFromJson,
    modifications: modificationsFromJson<MedicalHistoryDetails>(),
    ...Model.template(),
  });

  static fromJSON(json?: JSONType<MedicalHistory>) {
    if (json) {
      return new MedicalHistory(getParser(MedicalHistory.template)(json));
    } else {
      return new MedicalHistory({
        id: 0,
        versionNumber: 1,
        details: medicalHistoryDetailsFromJson({}),
        updatedAt: new Date(),
        modifications: modificationsFromJson<MedicalHistoryDetails>()(
          {} as JSONType<Modifications<MedicalHistoryDetails>>,
        ),
      });
    }
  }

  constructor(props: MedicalHistory) {
    super(props);
    this.versionNumber = props.versionNumber;
    this.details = props.details;
    this.modifications = props.modifications;
  }
}
