/* Taken from https://stackoverflow.com/a/7627603/261122 */

import {ReactNode} from 'react';

export const makeSafeForCSS = (children: ReactNode) => {
  if (!children) {
    return '';
  }

  let name = children.toString();
  if (Array.isArray(children)) {
    name = children.filter(c => typeof c === 'string').join('');
  }

  return name.trim().replace(/[^a-zA-Z0-9]/g, s => {
    const c = s.charCodeAt(0);
    if (c === 32) return '-';
    return '__' + ('000' + c.toString(16)).slice(-4);
  });
};
