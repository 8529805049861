import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {grey, lightGrey} from '@src/components/ui/theme';

export const CardContents = styled.div`
  width: 100%;
`;

export const EditIcon = styled(ClickableIcon)`
  color: ${lightGrey};
  &:hover {
    color: ${grey};
  }
`;

export const DownloadIcon = styled(FontAwesomeIcon)`
  color: ${lightGrey};
  &:hover {
    color: ${grey};
    cursor: pointer;
  }
`;
