import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {ProviderDetails} from './ProviderDetails';
import {TrackedModel} from './TrackedModel';
import {$opt, getParser} from './ResponseParser';
import {Patient} from '.';

export class CareTeamMembership extends TrackedModel {
  patient: Optional<Patient>;
  providerDetails: Optional<ProviderDetails>;

  static template = () => ({
    patient: $opt(Patient.fromJSON),
    providerDetails: $opt(ProviderDetails.fromJSON),
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<CareTeamMembership>) {
    return new CareTeamMembership(getParser(CareTeamMembership.template)(json));
  }

  constructor(data: CareTeamMembership) {
    super(data);
    this.patient = data.patient;
    this.providerDetails = data.providerDetails;
  }
}
