import {NetworkQualityStats, Participant} from 'twilio-video';
import {checkForInstability} from './checkForInstability';

export type NetworkQualityLevel = number | null;

export type NetworkQualityData = {
  networkQualityStats: NetworkQualityStats | null;
  networkQualityLevel: number | null;
};

export type NetworkQualityDataSetters = {
  setNetworkQualityStats: (v: NetworkQualityStats | null) => unknown;
  setNetworkQualityLevel: (v: number | null) => unknown;
};

export type HandleNetworkQualityChangeProps = {
  networkQualityData: NetworkQualityData;
  networkQualityDataSetters: NetworkQualityDataSetters;
  participant: Participant;
  visitId: number;
  isLocal?: boolean;
  testCall?: boolean;
};

// Defaults to false for non-testing purposes.
// Switch this on to stop reacting to Twilio events during testing.
const testMode = false;

export function handleNetworkQualityChange({
  networkQualityData: {networkQualityLevel, networkQualityStats},
  networkQualityDataSetters: {setNetworkQualityStats, setNetworkQualityLevel},
  participant,
  visitId,
  isLocal,
  testCall,
}: HandleNetworkQualityChangeProps) {
  // This allows us to only let functions from testingHelper.ts update results
  // while ignoring Twilio's events to help control testing.
  if (testMode && !testCall) {
    return;
  }

  setNetworkQualityStats(networkQualityStats);
  setNetworkQualityLevel(networkQualityLevel);

  if (isLocal) {
    checkForInstability({
      participant,
      visitId,
      networkQualityData: {networkQualityLevel, networkQualityStats},
    });
  }
}
