import {ReactElement} from 'react';
import {ApplicationEvents, sendEvent} from '../../../events';

export interface ToastOptions {
  permanent?: boolean;
  size?: 'normal' | 'big';
}

export const flashWarn = (
  message: string | ReactElement<{}>,
  {permanent = false, size = 'normal'}: ToastOptions = {},
): void => {
  sendEvent(ApplicationEvents.Toast, {
    type: 'warning',
    message,
    permanent,
    size,
  });
};

export const flashNotify = (
  message: string | ReactElement<{}>,
  {permanent = true, size = 'normal'}: ToastOptions = {},
): void => {
  sendEvent(ApplicationEvents.Toast, {type: 'info', message, permanent, size});
};

export const flashSuccess = (
  message: string | ReactElement<{}>,
  {permanent = true, size = 'normal'}: ToastOptions = {},
): void => {
  sendEvent(ApplicationEvents.Toast, {
    type: 'success',
    message,
    permanent,
    size,
  });
};

export const flashError = (
  message: string | ReactElement<{}>,
  {permanent = false, size = 'normal'}: ToastOptions = {},
): void => {
  sendEvent(ApplicationEvents.Toast, {type: 'error', message, permanent, size});
};

export const clearFlashes = (): void => {
  sendEvent(ApplicationEvents.Toast, {type: 'clear', message: ''});
};
