import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {ClientConfiguration} from './ClientConfiguration';
import {$arr, $opt, $str, getParser} from './ResponseParser';
import {CallPool, Model} from '.';

export type $NewInvoiceTarget = {
  customerName: string;
  salesforceId: string;
};

export class InvoiceTarget extends Model {
  customerName: string;
  salesforceId: string;
  clientConfigurations: Optional<ClientConfiguration[]>;
  callPools: Optional<CallPool[]>;

  static template = () => ({
    customerName: $str,
    salesforceId: $str,
    clientConfigurations: $opt($arr(ClientConfiguration.fromJSON)),
    callPools: $opt($arr(CallPool.fromJSON)),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<InvoiceTarget>) {
    return new InvoiceTarget(getParser(InvoiceTarget.template)(json));
  }

  constructor(props: InvoiceTarget) {
    super(props);
    this.customerName = props.customerName;
    this.salesforceId = props.salesforceId;
    this.clientConfigurations = Optional.of(props.clientConfigurations);
    this.callPools = Optional.of(props.callPools);
  }
}
