import * as React from 'react';
import {Grid} from '@material-ui/core';
import {InProgressFile} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import LinearIndeterminate from '@src/components/ui/atoms/progressBarsAndIndicators/linear/LinearIndeterminate';

export const FileBeingUploaded = ({file}: {file: InProgressFile}) => (
  <Grid container xs={12} direction="column" style={{marginBottom: '1rem'}}>
    <Grid item xs={4} style={{marginBottom: '0.25rem'}}>
      {file.file.name}
    </Grid>
    <Grid item xs={8}>
      <LinearIndeterminate />
    </Grid>
  </Grid>
);
