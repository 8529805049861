import {Room} from 'twilio-video';
import {isNothing} from '../../../../typeTests';
import {
  SetParticipants,
  attachRoomEventListeners,
  onParticipantConnected,
  attachWindowEventListeners,
  noRoomError,
} from '@src/util/videoChat';

export type OnRoomConnectedProps = {
  room: Room | null;
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  setNotification: (n: JSX.Element | null) => unknown;
  setShowReloadingModal: (v: boolean) => unknown;
  setIsDisconnecting: (v: boolean) => unknown;
};

export function onRoomConnected({
  room,
  setParticipants,
  setWaitingParticipants,
  setNotification,
  setShowReloadingModal,
  setIsDisconnecting,
}: OnRoomConnectedProps) {
  if (isNothing(room)) {
    noRoomError();
    return;
  } else {
    attachRoomEventListeners({
      room,
      setParticipants,
      setWaitingParticipants,
      setNotification,
      setShowReloadingModal,
    });

    synchronizeParticipantsState({
      setParticipants,
      setWaitingParticipants,
      room,
    });

    attachWindowEventListeners({room, setIsDisconnecting});
  }
}

// If a participant connects to the room in the brief moments before the room's
// event listeners have been set up, this makes sure that the ExamRoom's
// component state is synchronized with Twilio.
export function synchronizeParticipantsState({
  room,
  setParticipants,
  setWaitingParticipants,
}: Omit<
  OnRoomConnectedProps,
  | 'setShowReloadingModal'
  | 'setRoom'
  | 'setIsDisconnecting'
  | 'devices'
  | 'setNotification'
  | 'networkQualityData'
  | 'networkQualityDataSetters'
  | 'visitId'
  | 'setIsUnstable'
  | 'isUnstable'
>) {
  room?.participants.forEach(p => {
    onParticipantConnected({
      setWaitingParticipants,
      setParticipants,
      participant: p,
    });
  });
}
