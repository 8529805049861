import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import {format} from 'date-fns';
import Typography from '@material-ui/core/Typography';
import {Downloader} from '@src/components/ui/organisms/uploaders/files/Downloader';
import {useStyles} from '@src/components/shared/patient/files/FilesOverview/util/styles';
import {AppFile, Professional, UserType} from '@src/models';
import {fullName} from '@src/util/users/getDemographics';
import {useApi} from '@src/api/useApi';
import {isEmpty} from '@src/util/typeTests';

export function File({
  file,
  omitActionButtons,
}: {
  file: AppFile;
  // TODO
  // `omitActionButtons` is a bit of a hack so I can get a hotfix out
  // without having to do a bunch of refactoring.
  // See PR for https://nea-fast.atlassian.net/browse/RCC-472.
  // In the future, all three buttons (download, edit, delete)
  // should go in this generic component.
  omitActionButtons?: boolean;
}) {
  const classes = useStyles();
  const api = useApi();

  const [downloadUrl, setDownloadUrl] = useState('');

  const onDownload = (fileToDownload: AppFile) => {
    api
      .file(fileToDownload.id)
      .getDownload()
      .then(download => {
        setDownloadUrl(download.signedUrl);
      });
  };

  const {uploader, updatedAt} = file;
  const qual = Optional.of(uploader)
    .cast<Professional>(u => u.userType === UserType.Professional)
    .map(p => p.providerDetails)
    .map(pd => pd.qualification)
    .orNull();

  const name = fullName(uploader);

  const comment = file.comment.orElse('');
  const fileName = file.filename;

  return (
    <>
      <ListItem button className={classes.nested}>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'file']} size="lg" />
        </ListItemIcon>

        <ListItemText
          primary={`${fileName} ${isEmpty(comment) ? '' : `(${comment})`}`}
          className={classes.text}
          secondary={
            <>
              {!updatedAt && 'Uploaded at: Unknown'}
              {updatedAt && (
                <>
                  <span>
                    Uploaded at:{' '}
                    <Typography variant="body2" component="span">
                      {format(updatedAt, 'M/d/yyyy')}
                    </Typography>
                  </span>

                  <Typography
                    variant="body2"
                    component="span"
                    style={{display: 'block'}}
                  >
                    By: {name ? name : 'Refyne Connected Care System'}
                    {qual && `, ${qual}`}
                  </Typography>
                </>
              )}
            </>
          }
        />
        {!omitActionButtons && (
          <ListItemSecondaryAction>
            <IconButton onClick={() => onDownload(file)}>
              <FontAwesomeIcon icon={['fas', 'download']} size="sm" />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {downloadUrl.length > 0 && (
        <Downloader downloadUrl={downloadUrl} setDownloadUrl={setDownloadUrl} />
      )}
    </>
  );
}
