export function getDrawerWidth() {
  return document.getElementById('side-drawer')?.clientHeight || 0;
}

function getContentDrawerElement() {
  return (
    document.getElementById('content-drawer-open') ??
    document.getElementById('content-drawer-closed')
  );
}

function getContentDrawerMargin({
  contentDrawerElement,
}: {
  contentDrawerElement: HTMLElement | null;
}) {
  const margin = contentDrawerElement
    ? window.getComputedStyle(contentDrawerElement)?.marginLeft
    : 0;

  return parseInt(margin || '0');
}

function getSidebarGalleryWidth() {
  return document.getElementById('sidebar-gallery')?.clientHeight || 0;
}

function getDocumentWidth() {
  return document.body.getBoundingClientRect().width;
}

export function calculateAvailableWidth() {
  // Calculate available width for video gallery.
  const drawerWidth = getDrawerWidth();
  const contentDrawerElement = getContentDrawerElement();
  const contentDrawerMargin = getContentDrawerMargin({contentDrawerElement});
  const documentWidth = getDocumentWidth();
  const sidebarGalleryWidth = getSidebarGalleryWidth();

  return (
    documentWidth - drawerWidth - contentDrawerMargin - sidebarGalleryWidth
  );
}
