import {Optional} from '@ahanapediatrics/ahana-fp';
import {useEffect, useRef} from 'react';

export const usePrevious = <T>(value: T | Optional<T>): Optional<T> => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value instanceof Optional ? value.orNothing() : value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return Optional.of(ref.current);
};
