import {useEffect, useRef} from 'react';

export const useInterval = (fn: Function, timeout: number) => {
  const tId = useRef<number>(0);
  useEffect(() => {
    tId.current = setInterval(fn, timeout);
    return () => {
      clearInterval(tId.current);
    };
  }, [fn, timeout]);
  return tId;
};
