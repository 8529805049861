import {JSONType} from '../app-types';
import {TrackedModel} from './TrackedModel';
import {$num, getParser} from './ResponseParser';

export class ProviderCallPoolMembership extends TrackedModel {
  callPoolId: number;
  providerDetailsId: number;

  static template = () => ({
    callPoolId: $num,
    providerDetailsId: $num,
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<ProviderCallPoolMembership>) {
    return new ProviderCallPoolMembership(
      getParser(ProviderCallPoolMembership.template)(json),
    );
  }

  constructor(data: ProviderCallPoolMembership) {
    super(data);
    this.callPoolId = data.callPoolId;
    this.providerDetailsId = data.providerDetailsId;
  }
}
