import {Optional} from '@ahanapediatrics/ahana-fp';
import {$arr, $num, $opt, getParser} from './ResponseParser';
import {Model} from './Model';
import {MultipleChoiceField} from './MultipleChoiceField';
import {JSONType} from '@src/app-types';

export class MultipleChoiceResponse extends Model {
  formMultipleChoiceOptionIds: Optional<number[]>;
  formMultipleChoiceFieldId: number;
  assignedFormId: number;
  multipleChoiceField: Optional<MultipleChoiceField>;

  static template = () => ({
    formMultipleChoiceOptionIds: $opt($arr($num)),
    formMultipleChoiceFieldId: $num,
    assignedFormId: $num,
    multipleChoiceField: $opt(MultipleChoiceField.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<MultipleChoiceResponse>) {
    return new MultipleChoiceResponse(
      getParser(MultipleChoiceResponse.template)(json),
    );
  }

  constructor(props: MultipleChoiceResponse) {
    super(props);

    this.formMultipleChoiceOptionIds = Optional.of(
      props.formMultipleChoiceOptionIds,
    );
    this.formMultipleChoiceFieldId = props.formMultipleChoiceFieldId;
    this.assignedFormId = props.assignedFormId;
    this.multipleChoiceField = Optional.of(props.multipleChoiceField);
  }
}
