import {Optional} from '@ahanapediatrics/ahana-fp';
import {Patient, User, Guardian, UserType} from '@src/models';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

export const userIsPatient = (
  patient: Optional<Patient>,
  u: Optional<User>,
) => {
  const userPersonId = u
    .cast<Guardian>(us => us.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .map(r => r.id)
    .orElse(0 as NonProfessionalId);

  const userRelationship = patient
    .map(p => p.relationships)
    .orElse([])
    .find(rel => {
      return rel.personId === userPersonId;
    });

  return userRelationship?.isSelf ?? false;
};
