import {LocalParticipant, Room} from 'twilio-video';
import {Track} from './types';
import {isStoppableTrack, isVideoTrack} from './typeGuards';

export function disableLocalParticipantVideo({
  localParticipant,
}: {
  localParticipant?: LocalParticipant;
}) {
  if (!localParticipant) return;
  localParticipant.videoTracks.forEach(function(videoTrackPublication) {
    videoTrackPublication.track.disable();
  });
}

export function enableLocalParticipantVideo({
  localParticipant,
}: {
  localParticipant?: LocalParticipant;
}) {
  if (!localParticipant) return;

  localParticipant.videoTracks.forEach(function(videoTrackPublication) {
    videoTrackPublication.track.enable();
    localParticipant.publishTrack(videoTrackPublication.track);
  });
}

export function detectIsVideoDisabled({
  videoTrack,
}: {
  videoTrack: Track;
}): boolean {
  return Boolean(videoTrack && !videoTrack?.isEnabled);
}

export type StopLocalVideoTracksProps = {
  room: Room | null;
};

export function stopLocalVideoTracks({room}: StopLocalVideoTracksProps) {
  room?.localParticipant.videoTracks.forEach(function(trackPublication) {
    if (
      isStoppableTrack(trackPublication.track) &&
      isVideoTrack(trackPublication.track)
    ) {
      trackPublication.track.stop();
      trackPublication.unpublish();
      trackPublication.removeAllListeners();
    }
  });
}
