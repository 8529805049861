import {Opaque} from 'ts-essentials';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$opt, getParser, $str, $opaque, $enum} from './ResponseParser';
import {Model} from '.';

export enum EndpointType {
  Ahana = 'ahana',
  Agnes = 'agnes',
  AMDTablet = 'amd',
}

export type EndpointId = Opaque<number, 'EndpointId'>;

export type $NewEndpoint = {
  name: string;
  nonProfessionalInstructions: string;
  professionalInstructions: string;
  url?: string;
};

export class Endpoint extends Model {
  id!: EndpointId;
  name: string;
  nonProfessionalInstructions: string;
  professionalInstructions: string;
  type: EndpointType;
  url: Optional<string>;

  static template = () => ({
    name: $str,
    nonProfessionalInstructions: $str,
    professionalInstructions: $str,
    type: $enum(EndpointType),
    url: $opt($str),
    ...Model.template(),
    id: $opaque<EndpointId>(),
  });

  static fromJSON(json: JSONType<Endpoint>) {
    return new Endpoint(getParser(Endpoint.template)(json));
  }

  constructor(props: Endpoint) {
    super(props);
    this.name = props.name;
    this.nonProfessionalInstructions = props.nonProfessionalInstructions;
    this.professionalInstructions = props.professionalInstructions;
    this.type = props.type;
    this.url = props.url;
  }
}
