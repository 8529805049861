import {useHistory} from 'react-router';
import {useCallback} from 'react';

export const useReturnPath = () => {
  const history = useHistory();

  return useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const returnPath = searchParams.get('return');
    history.push(returnPath || '/');
  }, [history]);
};
