import {AppAPI} from '@src/api/AppAPI';

export type GetAccessTokenProps = {
  api: AppAPI;
  visitId: number;
};

export function getAccessToken({api, visitId}: GetAccessTokenProps) {
  return api.visit(visitId).getAccessToken();
}
