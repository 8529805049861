import {Optional} from '@ahanapediatrics/ahana-fp';
import {$date, $num, $opt, getParser} from './ResponseParser';
import {Model} from './Model';
import {DateField} from './DateField';
import {JSONType} from '@src/app-types';

export class DateResponse extends Model {
  value: Optional<Date>;
  formDateFieldId: number;
  assignedFormId: number;
  dateField: Optional<DateField>;

  static template = () => ({
    value: $opt($date),
    formDateFieldId: $num,
    assignedFormId: $num,
    dateField: $opt(DateField.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<DateResponse>) {
    return new DateResponse(getParser(DateResponse.template)(json));
  }

  constructor(props: DateResponse) {
    super(props);

    this.value = props.value;
    this.formDateFieldId = props.formDateFieldId;
    this.assignedFormId = props.assignedFormId;
    this.dateField = Optional.of(props.dateField);
  }
}
