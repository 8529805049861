import {Optional} from '@ahanapediatrics/ahana-fp';
import update from 'immutability-helper';
import {ActionType, getType} from 'typesafe-actions';
import * as operations from '../actions/operations';

export type OperationsAction = ActionType<typeof operations>;
export type OperationsState = {
  arrivalTime: Optional<number>;
  surveySkipped: boolean;
  visitOccurred: boolean;
};

const baseState: OperationsState = {
  arrivalTime: Optional.empty<number>(),
  surveySkipped: false,
  visitOccurred: false,
};

export default (
  state: OperationsState = {...baseState},
  action: OperationsAction,
) => {
  switch (action.type) {
    case getType(operations.setArrivalTime):
      return update(state, {arrivalTime: {$set: Optional.of(action.payload)}});
    case getType(operations.setSurveySkipped):
      return update(state, {surveySkipped: {$set: action.payload}});
    case getType(operations.setVisitOccurred):
      return update(state, {visitOccurred: {$set: action.payload}});
    case getType(operations.clearOperations):
      return {...baseState};
  }
  return state;
};
