import {JSONType} from '../app-types';
import {$num, $date, $str, getParser} from './ResponseParser';
import {User, Model} from '.';

export class Addendum extends Model {
  content: string;
  author: User;
  createdAt: Date;

  static template = () => ({
    id: $num,
    updatedAt: $date,
    content: $str,
    author: User.fromJSON,
    createdAt: $date,
  });

  static fromJSON(json: JSONType<Addendum>) {
    return new Addendum(getParser(Addendum.template)(json));
  }

  constructor(data: Addendum) {
    super(data);
    this.content = data.content;
    this.author = data.author;
    this.createdAt = data.createdAt;
  }
}
