import {Optional} from '@ahanapediatrics/ahana-fp';
import {$bool, $date, $num, $opt, $str, getParser} from './ResponseParser';
import {Model} from './Model';
import {JSONType} from '@src/app-types';

export class NumericField extends Model {
  createdAt: Date;
  name: string;
  description: string;
  ordinal: number;
  required: boolean;
  validation: Optional<string>;
  minValue: number;
  maxValue: number;
  blankFormId: number;

  static template = () => ({
    createdAt: $date,
    name: $str,
    description: $str,
    ordinal: $num,
    required: $bool,
    minValue: $num,
    maxValue: $num,
    blankFormId: $num,
    validation: $opt($str),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<NumericField>) {
    return new NumericField(getParser(NumericField.template)(json));
  }

  constructor(props: NumericField) {
    super(props);

    this.createdAt = props.createdAt;
    this.name = props.name;
    this.description = props.description;
    this.ordinal = props.ordinal;
    this.required = props.required;
    this.validation = props.validation;
    this.minValue = props.minValue;
    this.maxValue = props.maxValue;
    this.blankFormId = props.blankFormId;
  }
}
