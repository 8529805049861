import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$str, $enum, getParser, $opt} from './ResponseParser';
import {TrackedModel, User} from '.';

export enum SCPChangeRequestStatus {
  // noinspection JSUnusedGlobalSymbols
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
  Obsolete = 'obsolete',
}

/**
 * Approve: change status to approved and apply to the SCP property.
 * Reject: keep the change request record in the database, but change status to rejected.
 * ReRequeast: change status from Obsolete to Pending.
 */
export enum SCPChangeRequestStatusAction {
  Approve = 'approve',
  Reject = 'reject',
  ReRequest = 'reRequest',
}

export class SCPChangeRequest extends TrackedModel {
  status: string;
  property: string;
  newValue: string;
  requester: User;
  qualification: Optional<string>;

  static template = () => ({
    status: $enum(SCPChangeRequestStatus),
    property: $str,
    newValue: $str,
    requester: User.fromJSON,
    qualification: $opt($str),
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<SCPChangeRequest>) {
    return new SCPChangeRequest(getParser(SCPChangeRequest.template)(json));
  }

  constructor(props: SCPChangeRequest) {
    super(props);
    this.status = props.status;
    this.property = props.property;
    this.newValue = props.newValue;
    this.requester = props.requester;
    this.qualification = props.qualification;
  }
}
