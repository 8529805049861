import {Room, Participant} from 'twilio-video';
import {
  handleNetworkQualityChange,
  NetworkQualityDataSetters,
} from '../../shared/participants/utils/networkQuality/networkQuality';
import {
  attachParticipantConnected,
  attachParticipantDisconnected,
  attachParticipantReconnecting,
  SetParticipants,
} from '@src/util/videoChat';

export type AttachRoomEventListenersProps = {
  room: Room;
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  setNotification: (n: JSX.Element | null) => unknown;
  setShowReloadingModal: (v: boolean) => unknown;
};

export function attachRoomEventListeners({
  room,
  setParticipants,
  setNotification,
  setShowReloadingModal,
  setWaitingParticipants,
}: AttachRoomEventListenersProps) {
  attachParticipantConnected({
    room,
    setParticipants,
    setNotification,
    setWaitingParticipants,
  });

  attachParticipantDisconnected({
    room,
    setParticipants,
    setWaitingParticipants,
  });

  attachParticipantReconnecting({
    room,
    setNotification,
    setShowReloadingModal,
  });
}

export type AttachNetworkQualityEventListenersProps = {
  participant: Participant;
  networkQualityDataSetters: NetworkQualityDataSetters;
  visitId: number;
  isLocal?: boolean;
};

export function attachNetworkQualityEventListener({
  participant,
  networkQualityDataSetters,
  visitId,
  isLocal,
}: AttachNetworkQualityEventListenersProps) {
  participant.on(
    'networkQualityLevelChanged',
    (networkQualityLevel, networkQualityStats) => {
      handleNetworkQualityChange({
        visitId,
        networkQualityData: {
          networkQualityLevel: networkQualityLevel as number,
          networkQualityStats,
        },
        networkQualityDataSetters,
        participant: participant,
        isLocal,
      });
    },
  );
}
