import {$date, $num, $str, getParser} from './ResponseParser';
import {Model} from './Model';
import {JSONType} from '@src/app-types';

export class StaticContent extends Model {
  createdAt: Date;
  content: string;
  ordinal: number;
  blankFormId: number;

  static template = () => ({
    createdAt: $date,
    content: $str,
    ordinal: $num,
    blankFormId: $num,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<StaticContent>) {
    return new StaticContent(getParser(StaticContent.template)(json));
  }

  constructor(props: StaticContent) {
    super(props);

    this.createdAt = props.createdAt;
    this.content = props.content;
    this.ordinal = props.ordinal;
    this.blankFormId = props.blankFormId;
  }
}
