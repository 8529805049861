function getLocalParticipantControlsHeight() {
  return document.getElementById('bottom-navigation')?.clientHeight || 0;
}

function getHeaderHeight() {
  return document.getElementById('header')?.clientHeight || 0;
}

function getDocumentHeight() {
  return document.body.getBoundingClientRect().height;
}

export function calculateAvailableHeight() {
  // Calculate available height for video gallery.

  const localParticipantControlsHeight = getLocalParticipantControlsHeight();
  const documentHeight = getDocumentHeight();
  const headerHeight = getHeaderHeight();

  return documentHeight - localParticipantControlsHeight - headerHeight;
}
