import {JSONType} from '../app-types';
import {$str, $phone, getParser, $nullable} from './ResponseParser';
import {Model} from '.';

export class Practice extends Model {
  name: string;
  address: string;
  phone: string | null;
  fax: string | null;
  url: string | null;

  static template = () => ({
    name: $str,
    address: $str,
    phone: $nullable($phone),
    fax: $nullable($phone),
    url: $nullable($str),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<Practice>) {
    return new Practice(getParser(Practice.template)(json));
  }

  constructor(props: Practice) {
    super(props);

    this.name = props.name;
    this.address = props.address;
    this.phone = props.phone;
    this.fax = props.fax;
    this.url = props.url;
  }
}
