import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {getParser, $opt} from './ResponseParser';
import {ResponsiblePerson, User, UserType} from '.';

export class Guardian extends User {
  userType = UserType.Guardian;
  responsiblePersonDetails: Optional<ResponsiblePerson>;

  constructor(props: Guardian | User) {
    super(props);

    this.responsiblePersonDetails = props.responsiblePersonDetails;
  }

  static template = () => ({
    ...User.template(),
    responsiblePersonDetails: $opt(ResponsiblePerson.fromJSON),
  });

  static fromJSON(json: JSONType<Guardian>) {
    return new Guardian(getParser(Guardian.template)(json));
  }

  static fromUser(u: User) {
    if (u.userType !== UserType.Guardian) {
      throw new Error('Cannot create a Guardian from this user');
    }

    return new Guardian(u);
  }
}
