import {Room} from 'twilio-video';

type OnLeaveWindow = {
  room: Room;
  setIsDisconnecting: (v: boolean) => unknown;
};

export function onLeaveWindow({setIsDisconnecting, room}: OnLeaveWindow) {
  setIsDisconnecting(true);
  room.disconnect();
  window.localStorage.removeItem('redirect');
}
