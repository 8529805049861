import {
  Guardian,
  Invitation,
  Patient,
  PatientRelationship,
  Visit,
} from '../models';
import {AppAPI, coll, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {
  BasicQueryException,
  HttpResponseException,
  PatientCreationException,
  PatientUpdateException,
  ResourceNotFoundException,
} from './exceptions';
import {Resource} from './Resource';
import {UserId} from '@src/models/User';
import {GuardianPatientRequest} from '@src/models/Patient';

class GuardianAPI extends Resource {
  constructor(id: UserId | undefined, api: AppAPI) {
    super(id, api, 'guardians');
  }

  /**
   * Get the details of the current Guardian
   * @returns {Promise.<*>}
   */
  @requiresId
  @requiresAuth
  get(): Promise<Guardian> {
    return this.do<Guardian>(
      'get',
      `${this.id}`,
      null,
      'Something went wrong trying to get self',
    ).then(single(Guardian.fromJSON));
  }

  /**
   * Get the Guardians's invitations
   * @returns
   */
  @requiresId
  @requiresAuth
  getInvitations(): Promise<Invitation[]> {
    return this.do<Invitation[]>('get', `/${this.id}/invitations`)
      .then(coll(Invitation.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong trying to get a Guardian's Invitations: ${
            response instanceof HttpResponseException
              ? response.message
              : 'No response from server'
          }`,
        );
      });
  }

  /**
   * Get the Guardians's current Patients
   * @returns {Promise.<*>}
   */
  @requiresId
  @requiresAuth
  getPatients(): Promise<Array<Patient>> {
    return this.do<Patient[]>('get', `/${this.id}/patients`)
      .then(coll(Patient.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong trying to get a Guardian's Patients: ${
            response instanceof HttpResponseException
              ? response.message
              : 'No response from server'
          }`,
        );
      });
  }

  /**
   * Get the Guardians's current PatientRelationships
   * @returns {Promise.<*>}
   */
  @requiresId
  @requiresAuth
  getPatientRelationships(): Promise<Array<PatientRelationship>> {
    return this.do<PatientRelationship[]>('get', `/${this.id}/guardianships`)
      .then(coll(PatientRelationship.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong trying to get a Guardian's Patient Relationships: ${
            response instanceof HttpResponseException
              ? response.message
              : 'No response from server'
          }`,
        );
      });
  }

  @requiresId
  @requiresAuth
  updateDetails(request: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  }): Promise<Guardian> {
    return this.do<Guardian>(
      'patch',
      `/${this.id}/responsiblePerson`,
      request,
    ).then(single(Guardian.fromJSON));
  }

  /**
   * Search for the Guardians's current PatientRelationship where isSelf is true
   * @returns {Promise.<*>}
   */
  @requiresId
  @requiresAuth
  findSelfRelationship(): Promise<PatientRelationship | null> {
    return this.do<PatientRelationship>('get', `/${this.id}/selfRelationship`)
      .then(single(PatientRelationship.fromJSON))
      .catch(response => {
        if (response instanceof ResourceNotFoundException) {
          return null;
        }

        throw new BasicQueryException(
          `Something went wrong trying to get a Guardian's self relationship: ${
            response instanceof HttpResponseException
              ? response.message
              : 'No response from server'
          }`,
        );
      });
  }

  /**
   * Get the Guardian's current visits
   * @returns
   */
  @requiresId
  @requiresAuth
  getVisits(): Promise<Array<Visit>> {
    return this.do<Visit[]>('get', `/${this.id}/visits`)
      .then(coll(Visit.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong trying to get a Guardian's Visits: ${
            response instanceof HttpResponseException
              ? response.message
              : 'No response from server'
          }`,
        );
      });
  }

  /**
   * Get all Guardians
   */
  @requiresAuth
  getAll(): Promise<Array<Guardian>> {
    return this.do<Guardian[]>('get', '/')
      .then(coll(Guardian.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong trying to get all Guardians: ${
            response instanceof HttpResponseException
              ? response.message
              : 'No response from server'
          }`,
        );
      });
  }

  /**
   * Create a Patient for the Guardian
   * @returns {Promise.<Array.<*>>}
   */
  @requiresId
  @requiresAuth
  createPatient(data: GuardianPatientRequest): Promise<Patient> {
    return this.do<Patient>('post', `/${this.id}/patients`, data)
      .then(single(Patient.fromJSON))
      .catch(error => {
        console.error(error);
        throw new PatientCreationException(
          `Something went wrong trying to add a Guardian: ${
            error instanceof HttpResponseException
              ? error.message
              : 'No response from server'
          }`,
          error.details,
        );
      });
  }

  @requiresAuth
  @requiresId
  updatePatient(
    patientId: number,
    data: GuardianPatientRequest,
  ): Promise<Patient> {
    if (!patientId) {
      throw new PatientUpdateException(
        'Invalid patient ID passed to `updatePatient` in the GuardianAPI',
      );
    }
    return this.do<Patient>('put', `/${this.id}/patients/${patientId}`, data)
      .then(single(Patient.fromJSON))
      .catch(error => {
        throw new PatientUpdateException(
          `Something went wrong trying to update a Patient: ${
            error instanceof HttpResponseException
              ? error.message
              : 'No response from server'
          }`,
          error.details,
        );
      });
  }
}

export default GuardianAPI;
