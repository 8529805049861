import {isSupported, RemoteParticipant} from 'twilio-video';

export function isWaitingForRemote(participants: RemoteParticipant[]) {
  return participants.length <= 0;
}

export function notReadyToTransmit(participants: RemoteParticipant[]) {
  return !isSupported || isWaitingForRemote(participants);
}

export function doNotTransmit({
  participants,
  isDisconnecting,
}: {
  participants: RemoteParticipant[];
  isDisconnecting: boolean;
}) {
  return isDisconnecting || notReadyToTransmit(participants);
}
