import {useMemo} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {useUser} from './useUser';
import {userIsPatient} from '@src/util/users/userIsPatient';
import {Patient} from '@src/models';
import {conjugate} from '@src/util/stringManipulation/languageHelpers';

export const useConjugate = (patient: Patient | Optional<Patient>) => {
  const op = Optional.of(patient);
  const [user] = useUser();
  const isSelf = userIsPatient(op, user.getOptional());

  const c = useMemo(() => conjugate(isSelf), [isSelf]);

  return c;
};
