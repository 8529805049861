import {Button as MuiButton} from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import {makeSafeForCSS} from '../../../../util/ui/makeSafeForCss';
import {getMuiSize, getMuiVariant} from './functions';
import {ButtonProps} from './types';
import {useStyles} from './styles';

type MuiColor = 'primary' | 'inherit' | 'secondary' | 'default' | undefined;

type Props = ButtonProps;

export const Button = ({children, className = '', ...props}: Props) => {
  const {
    onClick,
    disabled = false,
    bStyle,
    bSize,
    fullWidth = false,
    block = false,
    isInRow = false,
    style = {},
    ...rest
  } = props;
  const classes = useStyles();
  let labelClass = children ? makeSafeForCSS(children) : 'no-label';

  const buttonClass =
    bStyle === 'outlined' || bStyle === 'text'
      ? classes[bStyle]
      : classes.contained;

  const rowClass = isInRow ? classes.isInRow : '';

  const colorClass =
    bStyle === 'warning' || bStyle === 'danger' || bStyle === 'success'
      ? classes[bStyle]
      : '';

  const classList = classnames([
    labelClass,
    className,
    buttonClass,
    colorClass,
    rowClass,
  ]);

  let color: MuiColor = 'primary';
  const variant = getMuiVariant(bStyle ?? 'contained');
  const size = getMuiSize(bSize ?? 'normal');

  const fw = fullWidth || block;

  return (
    <MuiButton
      {...rest}
      color={color}
      variant={variant}
      size={size}
      fullWidth={fw}
      disabled={disabled}
      onClick={onClick}
      className={classList}
      style={style}
    >
      {children}
    </MuiButton>
  );
};
