import {CircularProgress} from '@material-ui/core';
import * as React from 'react';
import classnames from 'classnames';
import inflect from '../../../util/stringManipulation/inflect';
import {getMuiSize, getMuiVariant} from './Button/functions';
import {ButtonProps} from './Button/types';
import {useStyles, useAsyncStyles} from './Button/styles';
import {Button} from './Button/index';

type Props = ButtonProps & {
  actionInProgress: boolean;
  actionWord: string;
  actionInProgressWord?: string;
  active?: boolean;
};

export function MuiAsyncActionButton({
  bSize = 'medium',
  bStyle = 'contained',
  active = false,
  block = false,
  className = '',
  disabled = false,
  onClick,
  actionWord,
  actionInProgress,
  actionInProgressWord,
  isInRow = false,
  fullWidth = false,
}: Props) {
  const classes = useStyles();
  const asyncClasses = useAsyncStyles();

  const buttonClass =
    bStyle === 'outlined' ? classes.outlined : classes.contained;

  const colorClass =
    bStyle === 'warning' || bStyle === 'danger' || bStyle === 'success'
      ? classes[bStyle]
      : '';

  const classList = classnames([className, buttonClass, colorClass]);

  const variant = getMuiVariant(bStyle);
  const size = getMuiSize(bSize);
  const fw = fullWidth || block;

  return (
    <Button
      onClick={onClick}
      disabled={disabled || actionInProgress}
      className={classList}
      bStyle={variant}
      bSize={size}
      fullWidth={fw}
      isInRow={isInRow}
    >
      {actionInProgress
        ? actionInProgressWord || inflect(actionWord)
        : actionWord}
      {actionInProgress && (
        <CircularProgress
          size={18}
          className={
            actionInProgress
              ? asyncClasses.inProgress
              : asyncClasses.notInProgress
          }
        />
      )}
    </Button>
  );
}
