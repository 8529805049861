import {Optional} from '@ahanapediatrics/ahana-fp';
import {Opaque} from 'ts-essentials';
import {JSONType} from '../app-types';
import {
  $str,
  $phone,
  $bool,
  $opt,
  getParser,
  $nullable,
  $opaque,
  $arr,
  $ifMissing,
} from './ResponseParser';
import {UserId} from './User';
import {Demographics} from './Demographics';
import {Practice, TrackedModel, Identifier} from '.';

export type ProviderDetailsId = Opaque<number, 'ProviderDetailsId'>;

export class ProviderDetails extends TrackedModel implements Demographics {
  id!: ProviderDetailsId;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  email: Optional<string>;
  speciality!: string | null;
  qualification!: string | null;

  practiceName: string | null;
  practicePhone: string | null;
  practiceFax: string | null;

  searchable!: boolean;
  seesPatients!: boolean;
  practice: Optional<Practice>;
  providerId: UserId | null;
  identifiers: Identifier[];

  static template = () => ({
    firstName: $nullable($str),
    lastName: $nullable($str),
    fullName: $nullable($str),
    email: $opt($str),
    practiceName: $nullable($str),
    practicePhone: $nullable($phone),
    practiceFax: $nullable($phone),
    speciality: $nullable($str),
    qualification: $nullable($str),
    searchable: $bool,
    seesPatients: $bool,
    practice: $opt(Practice.fromJSON),
    providerId: $nullable($opaque<UserId>()),
    identifiers: $ifMissing($arr(Identifier.fromJSON), []),
    ...TrackedModel.template(),
    id: $opaque<ProviderDetailsId>(),
  });

  static fromJSON(json: JSONType<ProviderDetails>): ProviderDetails {
    return new ProviderDetails(getParser(ProviderDetails.template)(json));
  }

  constructor(props: ProviderDetails) {
    super(props);

    this.practice = props.practice;
    this.providerId = props.providerId;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.fullName = props.fullName;
    this.email = props.email;
    this.practiceName = props.practiceName;
    this.practicePhone = props.practicePhone;
    this.practiceFax = props.practiceFax;
    this.speciality = props.speciality;
    this.qualification = props.qualification;
    this.searchable = props.searchable;
    this.seesPatients = props.seesPatients;
    this.identifiers = props.identifiers;
  }
}

export const getEmptyProviderDetails = () =>
  new ProviderDetails({
    id: 0 as ProviderDetailsId,
    firstName: '',
    lastName: '',
    fullName: '',
    email: Optional.empty(),
    practiceName: null,
    practicePhone: null,
    practiceFax: null,
    speciality: '',
    qualification: '',
    searchable: false,
    seesPatients: false,
    practice: Optional.empty(),
    providerId: null,
    lastUpdatedBy: Optional.empty(),
    updatedAt: new Date(),
    identifiers: [],
  });
