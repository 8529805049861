import {ConnectionToken} from '../models';
import {AppAPI, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {Resource} from './Resource';

class ConnectionTokenAPI extends Resource {
  constructor(uid: string | undefined, api: AppAPI) {
    super(uid, api, 'connectionTokens');
  }

  @requiresId
  get() {
    return this.do<ConnectionToken>(
      'get',
      `/${this.id}`,
      null,
      'Something went wrong trying to create a connection token',
      true,
    ).then(single(ConnectionToken.fromJSON));
  }

  @requiresAuth
  create(): Promise<ConnectionToken> {
    return this.do<ConnectionToken>(
      'post',
      '/',
      null,
      'Something went wrong trying to create a connection token',
    ).then(single(ConnectionToken.fromJSON));
  }
}

export default ConnectionTokenAPI;
