import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {ProviderDetails} from './ProviderDetails';
import {$date, $opt, getParser} from './ResponseParser';
import {LonelyVisit, TrackedModel} from '.';

export class ExternalVisitLog extends TrackedModel {
  readonly startTime: Date;
  readonly endTime: Date;
  readonly visit: Optional<LonelyVisit>;
  readonly providerDetails: Optional<ProviderDetails>;

  static template = () => ({
    startTime: $date,
    endTime: $date,
    visit: $opt(LonelyVisit.fromJSON),
    providerDetails: $opt(ProviderDetails.fromJSON),
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<ExternalVisitLog>) {
    return new ExternalVisitLog(getParser(ExternalVisitLog.template)(json));
  }

  constructor(props: ExternalVisitLog) {
    super(props);
    this.startTime = props.startTime;
    this.endTime = props.endTime;
    this.visit = props.visit;
    this.providerDetails = props.providerDetails;
  }
}
