export function setGalleryDimensions({
  gallery,
  videoTagWidth,
  videoTagHeight,
  cols,
  rows,
  mainScreenContainer,
  mainScreenElementHeight,
}: {
  gallery: HTMLElement | null;
  videoTagWidth: number;
  videoTagHeight: number;
  mainScreenElementHeight: number;
  mainScreenContainer: HTMLElement | null;
  cols: number;
  rows: number;
}): void {
  gallery?.style.setProperty('--width', videoTagWidth + 'px');
  gallery?.style.setProperty('--height', videoTagHeight + 'px');
  gallery?.style.setProperty('--cols', `${cols}`);
  gallery?.style.setProperty('--rows', rows === 1 ? null : `${rows}`);

  mainScreenContainer?.style.setProperty(
    '--mainScreenElementHeight',
    mainScreenElementHeight + 'px',
  );
}
