import {ToastDetails} from './components/shared/notifications/NotificationListener';

export enum ApplicationEvents {
  ReadyForNotifications = 'ReadyForNotifications',
  RequestHelp = 'RequestHelp',
  MenuItemClicked = 'MenuItemClicked',
  StorageAccessError = 'StorageAccessError',
  Toast = 'Toast',
  GetAccessTokenFailed = 'GetAccessTokenFailed',
}

export enum APIEvents {
  ApiReady = 'ApiReady',
  ApiFailed = 'ApiFailed',
  HttpResponseException = 'HttpResponseException',
  HttpRequestException = 'HttpRequestException',
  ApplicationException = 'ApplicationException',
  VisitUpdate = 'VisitUpdate',
  SafeVisitUpdate = 'SafeVisitUpdate',
  SCPUpdate = 'SCPUpdate',
  PatientUpdate = 'PatientUpdate',
}

export enum VideoChatEvents {
  PresentSCP = 'PresentSCP',
  StopPresentingSCP = 'StopPresentingSCP',
  UnstableNetwork = 'UnstableNetwork',
  StableNetwork = 'StableNetwork',
}

function sendEvent(
  type:
    | APIEvents.ApiReady
    | APIEvents.ApiFailed
    | ApplicationEvents.ReadyForNotifications
    | ApplicationEvents.RequestHelp
    | ApplicationEvents.MenuItemClicked
    | ApplicationEvents.StorageAccessError
    | ApplicationEvents.GetAccessTokenFailed
    | VideoChatEvents.PresentSCP
    | VideoChatEvents.StopPresentingSCP
    | VideoChatEvents.UnstableNetwork
    | VideoChatEvents.StableNetwork,
): void;
function sendEvent(
  type: ApplicationEvents.GetAccessTokenFailed,
  details: {auth0Id: string},
): void;

function sendEvent(
  type: APIEvents.VisitUpdate,
  details: {visitId: number},
): void;
function sendEvent(
  type: APIEvents.PatientUpdate,
  details: {patientId: number},
): void;
function sendEvent(type: ApplicationEvents.Toast, details: ToastDetails): void;
function sendEvent<T>(
  type: ApplicationEvents | APIEvents | VideoChatEvents,
  detail?: T,
) {
  const newEvent = new CustomEvent<T>(type, {
    detail,
  });
  window.dispatchEvent(newEvent);
}

export {sendEvent};
