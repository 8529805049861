import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';

type Props = FontAwesomeIconProps & {
  onClick: React.MouseEventHandler;
};

const ClickableIconStyled = styled(FontAwesomeIcon)<{
  onClick: React.MouseEventHandler;
}>`
  cursor: pointer;
`;

export const ClickableIcon: React.FunctionComponent<Props> = ({
  className,
  onClick,
  ...faProps
}) => (
  <ClickableIconStyled {...faProps} onClick={onClick} className={className} />
);
