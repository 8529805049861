import {Optional} from '@ahanapediatrics/ahana-fp';
import {$num, $opt, $str, getParser} from './ResponseParser';
import {Model} from './Model';
import {TextField} from './TextField';
import {JSONType} from '@src/app-types';

export class TextResponse extends Model {
  value: Optional<string>;
  formTextFieldId: number;
  assignedFormId: number;
  textField: Optional<TextField>;

  static template = () => ({
    value: $opt($str),
    formTextFieldId: $num,
    assignedFormId: $num,
    textField: $opt(TextField.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<TextResponse>) {
    return new TextResponse(getParser(TextResponse.template)(json));
  }

  constructor(props: TextResponse) {
    super(props);

    this.value = props.value;
    this.formTextFieldId = props.formTextFieldId;
    this.assignedFormId = props.assignedFormId;
    this.textField = Optional.of(props.textField);
  }
}
