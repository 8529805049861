import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useRef, useCallback} from 'react';
import {AppAPI} from './AppAPI';
import {getAccessTokenFailed} from '@src/util/applicationEvents/getAccessTokenFailed';
import {isVideoChatPath} from '@src/util/videoChat';

export function useApi(tokenGetter?: () => Promise<string>) {
  const {getAccessTokenSilently} = useAuth0();

  const api = useRef(AppAPI.getInstance());

  useEffect(() => {
    api.current.setTokenGetter(tokenGetter ?? getAccessTokenSilently);
  }, [tokenGetter, getAccessTokenSilently]);

  /*
   * Check the access_token every minute to keep the Auth0
   * session alive
   */
  const tokenRefresher = useCallback(async () => {
    try {
      if (tokenGetter) {
        await tokenGetter();
      } else {
        await getAccessTokenSilently();
      }
    } catch (e) {
      console.warn('Could not get Auth0 access token: ', e);
      if (
        isVideoChatPath() ||
        window.location.pathname.includes('first-time')
      ) {
        return;
      } else {
        getAccessTokenFailed();
      }
    }
  }, [tokenGetter, getAccessTokenSilently]);

  useEffect(() => {
    const intervalId = setInterval(tokenRefresher, 60_000);
    return () => clearInterval(intervalId);
  });

  return api.current;
}
