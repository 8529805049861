import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid, IconButton, Typography, useMediaQuery} from '@material-ui/core';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, {ReactNode} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: 700,
      padding: 0,
      color: theme.palette.primary.main,
    },

    headingText: {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      marginBottom: '2rem',
    },
    rowContainer: {
      marginTop: '2em',
      marginBottom: '2em',
      color: theme.palette.secondary.main,
      fontWeight: 700,
    },
    icon: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    iconButton: {
      padding: 0,
      marginLeft: 'auto',
    },
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(2),
    },
    dialogContent: {
      padding: theme.spacing(2),
    },
    dialogActions: {
      margin: 0,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

type Props = {
  children: ReactNode;
  fullScreen?: boolean;
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'];
  modalActions?: ReactNode;
  onClose?: () => void;
  show: boolean;
  style?: Object;
  title: string | ReactNode;
};

export function Modal({
  children,
  fullScreen,
  fullWidth,
  maxWidth,
  modalActions,
  onClose,
  show,
  style = {},
  title,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const full = fullScreen ?? mediaQuery;

  const DialogTitle = () => {
    return (
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={10}>
            <Typography variant="h6">{title}</Typography>
          </Grid>

          {onClose && (
            <Grid item xs={2}>
              <IconButton
                className={clsx(classes.closeButton)}
                aria-label="close"
                onClick={onClose}
              >
                <FontAwesomeIcon icon={['fas', 'times']} fixedWidth />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </MuiDialogTitle>
    );
  };

  return (
    <Dialog
      scroll="paper"
      open={show}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={full}
      style={style}
    >
      <DialogTitle />

      <MuiDialogContent className={classes.dialogContent} dividers>
        {children}
      </MuiDialogContent>
      {modalActions && <MuiDialogActions>{modalActions}</MuiDialogActions>}
    </Dialog>
  );
}
