import React, {useState, useEffect} from 'react';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {useUser} from '@src/hooks';
import {UserType} from '@src/models';
import {
  setHipaaAcknowledgmentCookie,
  getHipaaAcknowledgmentCookie,
} from '@src/util/files/hipaaAcknowledgmentCookie';

export function Downloader({
  downloadUrl,
  setDownloadUrl,
}: {
  downloadUrl: string;
  setDownloadUrl: (v: string) => unknown;
}) {
  const [user, userType] = useUser();
  const [hipaaAcknowledged, setHipaaAcknowledged] = useState(false);
  const [needsWarning, setNeedsWarning] = useState(true);

  useEffect(() => {
    if (user.isLoaded()) {
      getHipaaAcknowledgmentCookie(user.getOptional());
    }
  }, [user]);

  useEffect(() => {
    const isAcknowledged = getHipaaAcknowledgmentCookie(user.getOptional());
    setNeedsWarning(userType === UserType.Professional && !isAcknowledged);
  }, [downloadUrl, user, userType]);

  if (downloadUrl.length <= 0) {
    return null;
  }

  return (
    <>
      {needsWarning && (
        <ConfirmModal
          onConfirm={async () => {
            setHipaaAcknowledgmentCookie(user.getOptional());
            setHipaaAcknowledged(true);
            setNeedsWarning(false);
          }}
          text="This file may contain personal health information (PHI). Healthcare Providers must treat this as HIPAA-protected PHI, secure it
          appropriately during use, and dispose of it appropriately after use."
          show={!hipaaAcknowledged}
          onHide={() => {}}
          onCancel={() => setDownloadUrl('')}
          cancelText="Cancel"
          confirmText="Agree and Download"
          titleText="HIPAA Warning"
        />
      )}

      {!needsWarning && (
        <iframe
          title={downloadUrl}
          src={downloadUrl}
          style={{display: 'none'}}
        />
      )}
    </>
  );
}
