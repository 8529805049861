import {useEffect} from 'react';

export function useWindowEvent<K extends keyof WindowEventMap>(
  eventName: K | string,
  handler: EventListener,
) {
  useEffect(() => {
    window.addEventListener(eventName, handler);

    return () => {
      window.removeEventListener(eventName, handler);
    };
  }, [eventName, handler]);
}
