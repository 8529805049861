import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useEffect, useCallback, useState, useRef, useMemo} from 'react';
import {Paged, PaginationOptions} from '@src/api/AppAPI';

type Options = {
  requestGate?: () => boolean;
  pageSize?: number;
};

type HookReturn<T> = [
  AsyncData<T>,
  () => Promise<unknown>,
  boolean,
  (vals: readonly T[]) => unknown,
  () => void,
];

const defaultOptions = Object.freeze({});

/**
 * This hook streamlines access to resources from the backend
 *
 * Using this hook will ensure that the resources are loaded on the component's first
 * mount
 *
 * The returned array is:
 *
 *  - the resources collection: an AsyncData<T>
 *  - a next function that gets the next page
 *  - a boolean indicating if there are more pages
 *  - an update function for modifying the collection - this really should only be used for updates and deletes
 *  - a reset function that resets the resources collection
 *
 * @param getter a function that requests resources from the backend
 * @param deps the dependencies of the `getter` function
 * @param options.requestGate a function that returns false to prevent a request. It should be a stable callback
 *
 */
// eslint-disable-next-line import/no-unused-modules
export function usePagedResources<T>(
  getter: (options: PaginationOptions) => Promise<Paged<T>>,
  deps: ReadonlyArray<unknown>,
  options: Options = defaultOptions,
): HookReturn<T> {
  const [res, setRes] = useState<AsyncData<T>>(AsyncData.notAsked());
  const [moreAvailable, setMoreAvailable] = useState(true);
  const [totalCount, setTotalCount] = useState(Number.MAX_SAFE_INTEGER);
  const cursor = useRef(0);

  const {requestGate, pageSize} = useMemo(
    () => ({
      requestGate: options.requestGate ?? (() => true),
      pageSize: options.pageSize ?? 10,
    }),
    [options],
  );

  useEffect(() => {
    if (res.getAllOptional().orElse([]).length >= totalCount) {
      setMoreAvailable(false);
    }
  }, [res, totalCount]);

  const next = useCallback(async () => {
    setRes(r => r.loadMore());
    try {
      const {count, rows} = await getter({pageSize, start: cursor.current});
      setTotalCount(count);
      cursor.current = cursor.current + pageSize;
      setRes(r => r.append(rows));
    } catch (e) {
      setRes(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, pageSize]);

  const resetResources = useCallback(() => {
    setRes(AsyncData.notAsked());
    setMoreAvailable(true);
    setTotalCount(Number.MAX_SAFE_INTEGER);
    cursor.current = 0;
  }, [setMoreAvailable, setRes]);

  const updateResources = useCallback((v: readonly T[]) => {
    setRes(r => {
      if (r.isLoading()) {
        return AsyncData.loaded(v).loadMore();
      } else {
        return AsyncData.loaded(v);
      }
    });
  }, []);

  useEffect(() => {
    resetResources();
    next();
  }, [next, requestGate, resetResources]);

  return [res, next, moreAvailable, updateResources, resetResources];
}
