import {addBreadcrumb, captureException, Severity} from '@sentry/browser';
import {AppAPI} from '@src/api/AppAPI';
import {VisitDocumentation} from '@src/models';

export const loadDocumentation = (api: AppAPI) => (
  visitId: number,
  setDocumentation: (d: VisitDocumentation) => unknown,
) =>
  api
    .visit(visitId)
    .getDocumentation()
    .then(d => {
      setDocumentation(d);
      return d;
    })
    .catch(docError => {
      addBreadcrumb({
        category: 'exam-room',
        message: 'Failed to load documentation',
        level: Severity.Error,
      });
      captureException(docError);
      throw docError;
    });
