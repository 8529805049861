import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, {FocusEventHandler, forwardRef} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
    error: {
      label: {
        color: theme.palette.error.main,
      },
    },
  }),
);
type Props = {
  disabled?: boolean;
  hidden?: boolean;
  name: string;
  onChange?: (checked: boolean) => unknown;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  title?: string;
  required?: boolean;
  value?: boolean;
  error?: string;
  style?: Object;
};

export const MuiCheckBoxInput = forwardRef<HTMLButtonElement, Props>(
  (
    {
      disabled = false,
      hidden = false,
      onChange = () => {},
      onBlur = () => {},
      required = false,
      title = '',
      value = false,
      error,
      style,
    },
    ref,
  ) => {
    const classes = useStyles();

    if (hidden) return null;

    return (
      <FormGroup row style={{fontSize: 16, ...style}} onBlur={onBlur}>
        <FormControl required={required} error={!!error}></FormControl>
        <FormControlLabel
          control={
            <Checkbox
              ref={ref}
              required={required}
              checked={value}
              onChange={e => onChange(e.target.checked)}
              name={name}
              disabled={disabled}
            />
          }
          label={title}
        />
        {error && (
          <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        )}
      </FormGroup>
    );
  },
);
