import {AppAPI, coll, single} from './AppAPI';
import {requiresAuth} from './decorators';
import {
  ApplicationException,
  HttpResponseException,
  TargetInvoiceCreationException,
} from './exceptions';
import {Resource} from './Resource';
import {InvoiceTarget} from '@src/models';
import {$NewInvoiceTarget} from '@src/models/InvoiceTarget';

class InvoiceTargetAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'invoiceTargets');
  }

  /**
   * Get all Invoice Targets
   * @returns
   */
  @requiresAuth
  getAll(): Promise<InvoiceTarget[]> {
    return this.do<InvoiceTarget[]>(
      'get',
      '/',
      null,
      'Something went wrong trying to get all InvoiceTargets',
    ).then(coll(t => InvoiceTarget.fromJSON(t)));
  }

  /**
   * Create an `InvoiceTarget`
   * @param data The `InvoiceTarget` data
   * @returns {Promise.<*>} Promise that resolves to the newly created `InvoiceTarget`
   */
  @requiresAuth
  create(data: $NewInvoiceTarget): Promise<InvoiceTarget> {
    return this.do<InvoiceTarget>('post', '/', data)
      .then(single(InvoiceTarget.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new TargetInvoiceCreationException(
            `Something went wrong trying to create an Invoice Target: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }
}

export default InvoiceTargetAPI;
