import {JSONType} from '../app-types';
import {User} from '.';

export type ModRecord = {
  who: User;
  when: Date;
};

export type Modifications<T> = {
  [property in keyof T]: ModRecord;
};

export const modificationsFromJson = <T>() => (
  json: JSONType<Modifications<T>>,
): Modifications<T> => {
  return Object.entries(json ?? {}).reduce(
    (mods, [prop, val]) => ({
      ...mods,
      [prop]: {
        who: User.fromJSON(val.who),
        when: new Date(val.when),
      },
    }),
    {} as Modifications<T>,
  );
};
