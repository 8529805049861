import styled from 'styled-components';
import {
  primary,
  primaryDark,
  primaryText,
  sm,
  xs,
} from '@src/components/ui/theme';

export const MediaUploaderContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${xs};
  color: ${primaryText};
  padding: ${sm};
  background-color: ${primary};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${primaryDark};
    transition: 0.5s;
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
  }
`;
