import {KeyboardDatePicker} from '@material-ui/pickers';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import React from 'react';

type Props = {
  id?: string;
  name: string;
  error?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  value?: ParsableDate;
  onChange?: (d: MaterialUiPickersDate) => unknown;
  minDate?: Date;
  maxDate?: Date;
  inputVariant?: 'standard' | 'outlined' | 'filled';
};

export const DateInput = ({
  id,
  name,
  label,
  onChange = () => {},
  value,
  error,
  disabled = false,
  required = false,
  maxDate,
  minDate,
  inputVariant = 'outlined',
}: Props) => {
  return (
    <KeyboardDatePicker
      id={id}
      name={name}
      clearable
      disabled={disabled}
      required={required}
      label={label}
      value={value}
      placeholder="mm/dd/yyyy"
      onChange={date => onChange(date)}
      format="MM/dd/yyyy"
      fullWidth
      margin="normal"
      error={!!error}
      helperText={error ?? ''}
      maxDate={maxDate}
      minDate={minDate}
      inputVariant={inputVariant}
    />
  );
};
