import {DeepPartial} from 'ts-essentials';
import {
  AppFile,
  Contact,
  ContactRequest,
  SharedCarePlan,
  SCPChangeRequestStatusAction,
  SCPChangeRequest,
  SCPRetraction,
} from '../models';
import {AppAPI, asIs, coll, PaginationOptions, single, singleH} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {
  ApplicationException,
  BasicQueryException,
  HttpResponseException,
} from './exceptions';
import {Resource} from './Resource';
import {DetailsRequest} from './ProviderDetailsAPI';

export class SCPAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'scps');
  }

  @requiresAuth
  @requiresId
  get(): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>('get', `/${this.id}`)
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to get a Shared Care Plan: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  updateScp(updates: Partial<SharedCarePlan>): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>('patch', `/${this.id}`, updates)
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to update an Shared Care Plan: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  updateOwner(data: DeepPartial<DetailsRequest>): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'patch',
      `/${this.id}/ownership`,
      data,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to update an Shared Care Plan's owner: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  acceptOwnership(): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'patch',
      `/${this.id}/action/acceptOwnership`,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to accept the Shared Care Plan's ownership: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  retractSCP(request: {reasoning: string}): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'post',
      `/${this.id}/retractions`,
      request,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to retract the Shared Care Plan: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  unretractSCP(retractionId: number): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'delete',
      `/${this.id}/retraction/${retractionId}`,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to unretract the Shared Care Plan: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  getActiveRetraction(): Promise<SCPRetraction> {
    return this.do<SCPRetraction>(
      'get',
      `/${this.id}/retractions`,
      null,
      "Something went wrong trying to get an Shared Care Plan's retraction",
    ).then(single(SCPRetraction.fromJSON));
  }

  @requiresAuth
  @requiresId
  changeRequestStatusAction(
    changeRequestId: number,
    statusAction: SCPChangeRequestStatusAction,
  ): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'patch',
      `/${this.id}/changeRequest/${changeRequestId}/${statusAction}`,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to update a change request's status: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  deleteChangeRequest(changeRequestId: number): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'delete',
      `/${this.id}/changeRequest/${changeRequestId}`,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to delete a Change Request: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  updateApprovalExpiration(approvalExpiration: Date): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'patch',
      `/${this.id}/approvalExpiration`,
      approvalExpiration,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to update a Shared Care Plan approval expiration date: ${error.status}`,
            error.text(),
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  createContact(contact: ContactRequest): Promise<Contact> {
    return this.do<Contact>('post', `/${this.id}/contacts`, contact)
      .then(single(Contact.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a Contact: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  updateChangeRequest(
    changeRequestId: number,
    data: {newValue: string},
  ): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'patch',
      `/${this.id}/changeRequest/${changeRequestId}`,
      data,
    )
      .then(singleH(SharedCarePlan.fromHATEOAS))
      .catch(error => {
        if (error instanceof Response) {
          throw new BasicQueryException(
            `Something went wrong trying to update the change request: ${error.status}`,

            error.text(),
          );
        }

        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  updateContact(contact: ContactRequest): Promise<Contact> {
    return this.do<Contact>(
      'put',
      `/${this.id}/contacts/${contact.id}`,
      contact,
    )
      .then(single(Contact.fromJSON))
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to get a Contact: ${error.message}`,
            error.details,
          );
        }
        throw new ApplicationException(error);
      });
  }

  @requiresAuth
  @requiresId
  deleteContact(contactId: number): Promise<void> {
    return this.do<void>('delete', `/${this.id}/contacts/${contactId}`)
      .then(asIs)
      .catch(error => {
        if (error instanceof HttpResponseException) {
          throw new BasicQueryException(
            `Something went wrong trying to delete a Contact: ${error.message}`,
            error.details,
          );
        }
        console.error(error);
        throw new ApplicationException(error);
      });
  }

  @requiresId
  @requiresAuth
  getFiles(): Promise<AppFile[]> {
    return this.do<AppFile[]>(
      'get',
      `/${this.id}/files`,
      null,
      'Something went wrong trying get files',
    ).then(coll(AppFile.fromJSON));
  }

  @requiresId
  @requiresAuth
  getChangeRequestsLog(
    options: PaginationOptions,
  ): Promise<SCPChangeRequest[]> {
    const {pageSize = 10, start = 0} = options;

    return this.do<SCPChangeRequest[]>(
      'get',
      `/${this.id}/changeRequests/?pageSize=${pageSize}&start=${start}`,
      null,
      'Something went wrong trying to get the changes log',
    ).then(coll(SCPChangeRequest.fromJSON));
  }

  @requiresId
  @requiresAuth
  addFile(fileInfo: AppFile): Promise<SharedCarePlan> {
    return this.doHateoas<SharedCarePlan>(
      'post',
      `/${this.id}/files`,
      fileInfo,
      'Something went wrong trying add a file',
    ).then(singleH(SharedCarePlan.fromHATEOAS));
  }
}
