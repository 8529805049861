import {Room, Participant, RemoteParticipant} from 'twilio-video';

export type IsParticipantAnonymousProps = {
  participant: Participant | null;
};

export function isParticipantAnonymous({
  participant,
}: IsParticipantAnonymousProps): boolean {
  if (!participant) {
    return false;
  }

  return participant.identity.includes('anonymous');
}

export type IsLocalParticipantAdmittedProps = {
  room: Room | null;
};

export function isLocalParticipantAdmitted({
  room,
}: IsLocalParticipantAdmittedProps): boolean {
  if (!room) {
    return false;
  }

  const participant = room?.localParticipant;
  const isAnonymous = isParticipantAnonymous({
    participant: participant || null,
  });

  return !(isAnonymous && room?.localParticipant.dataTracks?.size);
}

export type DoesParticipantRequirePermissionProps = {
  participant: Participant | RemoteParticipant | null;
};

export function doesParticipantRequirePermission({
  participant,
}: DoesParticipantRequirePermissionProps): boolean {
  if (!participant) {
    return false;
  }

  const isAnonymous = isParticipantAnonymous({participant});
  const videoTracksLength = participant?.videoTracks?.size ?? 0;
  const audioTracksLength = participant?.audioTracks?.size ?? 0;

  return isAnonymous && videoTracksLength === 0 && audioTracksLength === 0;
}
