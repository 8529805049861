import {Room} from 'twilio-video';
import {doesParticipantRequirePermission} from '@src/util/videoChat';

type IsRoomConnectedProps = {
  room: Room | null;
};

export function isRoomConnected({room}: IsRoomConnectedProps): boolean {
  return Boolean(room && room.localParticipant.state === 'connected');
}

type HasVisitorsWaitingProps = {
  room: Room | null;
};

export function hasVisitorsWaiting({room}: HasVisitorsWaitingProps): boolean {
  if (room) {
    for (let [, participant] of [...room?.participants]) {
      if (doesParticipantRequirePermission({participant})) {
        return true;
      }
    }
  }

  return false;
}
