import update from 'immutability-helper';
import {InProgressFile} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';

export class UploadingFileManager {
  private readonly onUpdate: (files: InProgressFile[]) => void;
  private fileList: InProgressFile[];

  constructor(
    onUpdate: (files: InProgressFile[]) => void,
    initialList: InProgressFile[] = [],
  ) {
    this.onUpdate = onUpdate;
    this.fileList = initialList;
  }

  public addNewUploadingFile = (file: File) => {
    this.fileList = update(this.fileList, {
      $push: [{file, progress: 0, completed: false, contentType: file.type}],
    });
    this.onUpdate(this.fileList);
  };

  public updateFile = (uploadingFile: File, progress: number) => {
    const listedFileIndex = this.fileList.findIndex(
      f => f.file.name === uploadingFile.name,
    );
    if (listedFileIndex < 0 && progress > 0) {
      throw new Error('File not found in list');
    } else {
      this.fileList = update(this.fileList, {
        [listedFileIndex]: {
          $set: {
            file: uploadingFile,
            progress,
            completed: progress === 100,
          },
        },
      });
      this.onUpdate(this.fileList);
    }
  };

  public completeFile = (file: File) => {
    const listedFileIndex = this.fileList.findIndex(
      f => f.file.name === file.name,
    );
    this.fileList = update(this.fileList, {
      $splice: [[listedFileIndex, 1]],
    });
    this.onUpdate(this.fileList);
  };

  public errorFile = (message: string) => (file: File) => {
    const listedFileIndex = this.fileList.findIndex(
      f => f.file.name === file.name,
    );
    if (listedFileIndex < 0) {
      throw new Error('File not found in list');
    } else {
      const f = this.fileList[listedFileIndex];
      this.fileList = update(this.fileList, {
        [listedFileIndex]: {
          $set: {
            file,
            error: message,
            progress: f.progress,
            completed: f.completed,
          },
        },
      });
      this.onUpdate(this.fileList);
    }
  };
}
