import {Optional} from '@ahanapediatrics/ahana-fp';
import {ProviderCallPoolMembership} from './ProviderCallPoolMembership';
import {
  $arr,
  $bool,
  $date,
  $num,
  $obj,
  $opt,
  $str,
  getParser,
} from './ResponseParser';
import {Endpoint} from './Endpoint';
import {Model} from '.';
import {JSONType} from '@src/app-types';

export type $NewCallPoolRequest = {
  rules: CallPoolRules;
  invoiceTargetId: number;
  clientConfigurationIds: number[];
};

export type PaymentControl = 'Required' | 'Optional' | 'None';
type PaymentRules = {
  insurance: PaymentControl;
  creditCard: PaymentControl;
};

export const defaultPaymentRules: PaymentRules = Object.freeze({
  insurance: 'None',
  creditCard: 'None',
});

export type CallPoolRules = {
  [rule: string]: boolean | number | string | object | object[] | undefined;
  payments?: PaymentRules;
};

type ProcessingProvider = 'stripe';

export type PaymentProcessorInfo =
  | {
      publicKey: string;
      privateKey: string;
      processingProvider: ProcessingProvider;
    }
  | DefaultPaymentProcessorInfo;

type DefaultPaymentProcessorInfo = {processingProvider: 'none'};

export class CallPool extends Model {
  approved: boolean;
  children: Optional<CallPool[]>;
  description: string;
  documentInAhana: boolean;
  endpoints: Endpoint[];
  invoiceTargetId: Optional<number>;
  nameForGuardians: string;
  nameForProviders: string;
  parentPool: Optional<CallPool>;
  private: boolean;
  professionalOnly: boolean;
  rules: CallPoolRules;
  paymentProcessorInfo: PaymentProcessorInfo;
  providerMemberships: Optional<ProviderCallPoolMembership[]>;

  static template = () => ({
    approved: $bool,
    children: $opt($arr(CallPool.fromJSON)),
    description: $str,
    documentInAhana: $bool,
    endpoints: $arr(Endpoint.fromJSON),
    id: $num,
    invoiceTargetId: $opt($num),
    nameForGuardians: $str,
    nameForProviders: $str,
    parentPool: $opt(CallPool.fromJSON),
    private: $bool,
    professionalOnly: $bool,
    rules: $obj<CallPoolRules>(),
    updatedAt: $date,
    paymentProcessorInfo: $obj<PaymentProcessorInfo>(),
    providerMemberships: $opt($arr(ProviderCallPoolMembership.fromJSON)),
  });

  static fromJSON(json: JSONType<CallPool>): CallPool {
    return new CallPool(getParser(CallPool.template)(json));
  }

  constructor(props: CallPool) {
    super(props);

    this.approved = props.approved;
    this.children = props.children;
    this.description = props.description;
    this.documentInAhana = props.documentInAhana;
    this.endpoints = props.endpoints;
    this.invoiceTargetId = props.invoiceTargetId;
    this.nameForGuardians = props.nameForGuardians;
    this.nameForProviders = props.nameForProviders;
    this.parentPool = props.parentPool;
    this.rules = props.rules ?? {};
    this.private = props.private;
    this.professionalOnly = props.professionalOnly;
    this.paymentProcessorInfo = props.paymentProcessorInfo;
    this.providerMemberships = props.providerMemberships;
  }
}
