import {Optional} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Grid} from '@material-ui/core';
import {Button, SelectInput} from '../../../../ui/form';
import {ParagraphText} from '../../../../ui/layout/text/body/ParagraphText';
import {MuiHeading} from '../../../../ui/layout/MuiHeading';
import {startSound, stopSound} from './functions';
import {Devices} from './MediaDeviceManager';
import {WorkingReporter} from './WorkingReporter';
import {canSetAudioSink} from '@src/util/browserTools/audioVideo';

type Props = {
  audio: HTMLAudioElement;
  audioSampleState: AudioSampleState;
  devices: Optional<Devices>;
  selectedAudioOutput: Optional<MediaDeviceInfo>;
  selectAudioOutput: (d: Optional<string>) => unknown;
  setAudioPlaying: (state: AudioSampleState) => unknown;
  isWorking: Optional<boolean>;
  onWorking?: (b: boolean) => unknown;
};

export enum AudioSampleState {
  Starting = 'starting',
  Playing = 'playing',
  Stopped = 'stopped',
}

const audioActions = {
  [AudioSampleState.Stopped]: 'Play',
  [AudioSampleState.Playing]: 'Stop',
  [AudioSampleState.Starting]: 'Loading',
};

export const AudioOutConfigSection = ({
  audio,
  audioSampleState,
  devices,
  isWorking,
  onWorking,
  selectedAudioOutput,
  selectAudioOutput,
  setAudioPlaying,
}: Props) => {
  const options = devices
    .map(d =>
      d.audioOutputs.map(a => ({
        value: a.deviceId,
        label: a.label || `Output ${a.deviceId}`,
      })),
    )
    .orElse([]);

  return (
    <Grid container direction="row">
      <MuiHeading
        variant="h6"
        color="primary"
        style={{marginBottom: '1rem', width: '100%'}}
      >
        Can you hear the bell?{' '}
      </MuiHeading>

      <ParagraphText>
        Finally, check that you&apos;ll be able to hear the other side. Play the
        audio sample and make sure it&apos;s audible.
        <br />
        <br />
        If you can&apos;t hear it, be sure to check your volume and check that
        you&apos;ve got the right output selected (headphones or speakers, as
        appropriate).
      </ParagraphText>
      <Grid item xs={12} style={{marginBottom: '3rem'}}>
        <Grid item style={{marginBottom: '2rem'}}>
          <Button
            disabled={audioSampleState === AudioSampleState.Starting}
            onClick={() => {
              const action =
                audioSampleState === AudioSampleState.Playing
                  ? stopSound
                  : startSound;
              action(audio, setAudioPlaying);
            }}
          >
            {audioActions[audioSampleState]} audio sample
            {audioSampleState === AudioSampleState.Starting && (
              <FontAwesomeIcon spin={true} icon={'spinner'} />
            )}
          </Button>
        </Grid>
        <Grid item style={{marginBottom: '2rem'}}>
          {audio && canSetAudioSink(audio) && (
            <SelectInput
              title="Audio Output"
              name="audioOutput"
              options={options}
              placeholder="Please select an audio output"
              value={selectedAudioOutput.map(a => a.deviceId).orElse('')}
              onChange={e => {
                selectAudioOutput(Optional.of(e.target.value));
              }}
            />
          )}
        </Grid>

        {onWorking && (
          <WorkingReporter isWorking={isWorking} reportWorking={onWorking} />
        )}
      </Grid>
    </Grid>
  );
};
