import {Optional} from '@ahanapediatrics/ahana-fp';
import {
  $arr,
  $bool,
  $date,
  $num,
  $opt,
  $str,
  getParser,
} from './ResponseParser';
import {Model} from './Model';
import {MultipleChoiceOption} from './MultipleChoiceOption';
import {JSONType} from '@src/app-types';

export class MultipleChoiceField extends Model {
  allowMultipleResponses: boolean;
  blankFormId: number;
  createdAt: Date;
  description: string;
  multipleChoiceOptions: Optional<MultipleChoiceOption[]>;
  name: string;
  ordinal: number;
  required: boolean;

  static template = () => ({
    allowMultipleResponses: $bool,
    blankFormId: $num,
    createdAt: $date,
    description: $str,
    multipleChoiceOptions: $opt($arr(MultipleChoiceOption.fromJSON)),
    name: $str,
    ordinal: $num,
    required: $bool,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<MultipleChoiceField>) {
    return new MultipleChoiceField(
      getParser(MultipleChoiceField.template)(json),
    );
  }

  constructor(props: MultipleChoiceField) {
    super(props);

    this.allowMultipleResponses = props.allowMultipleResponses;
    this.blankFormId = props.blankFormId;
    this.createdAt = props.createdAt;
    this.description = props.description;
    this.multipleChoiceOptions = Optional.of(props.multipleChoiceOptions);
    this.name = props.name;
    this.ordinal = props.ordinal;
    this.required = props.required;
  }
}
