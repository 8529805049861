import React, {FunctionComponent, useState, useCallback} from 'react';
import styled from 'styled-components';
import {Downloader} from '@src/components/ui/organisms/uploaders/files/Downloader';
import {useApi} from '@src/api/useApi';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {xs} from '@src/components/ui/theme';
import {
  DownloadIcon,
  EditIcon,
} from '@src/components/shared/PatientDashboard/InfoCard';
import {AppFile} from '@src/models';
import {File} from '@src/components/ui/organisms/uploaders/files/Folder/File';

const CompletedFile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionIcons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  svg {
    margin: 0 ${xs};
  }
`;

type Props = {
  FileEditIcon?: FunctionComponent<{
    file: AppFile;
    onSave: (file: AppFile) => unknown;
  }>;
  onUpdateFile?: (file: AppFile) => unknown;
  onDeleteFile?: (file: AppFile) => unknown;
  file: AppFile;
};

export const CompletedUpload = ({
  FileEditIcon,
  onUpdateFile,
  onDeleteFile,
  file,
}: Props) => {
  const api = useApi();

  const [downloadUrl, setDownloadUrl] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onDownload = useCallback(() => {
    api
      .file(file.id)
      .getDownload()
      .then(download => {
        setDownloadUrl(download.signedUrl);
      });
  }, [api, file]);

  const onConfirmDeletion = useCallback(
    () =>
      api
        .file(file.id)
        .delete()
        .then(() => {
          if (onDeleteFile) {
            onDeleteFile(file);
          }
        }),
    [api, onDeleteFile, file],
  );

  const hideConfirmationModal = useCallback(
    () => setShowConfirmationModal(false),
    [],
  );

  return (
    <CompletedFile>
      <File file={file} omitActionButtons />
      <ActionIcons>
        {FileEditIcon && onUpdateFile && (
          <FileEditIcon file={file} onSave={onUpdateFile} />
        )}
        {onDeleteFile && (
          <EditIcon
            icon="trash"
            onClick={() => {
              setShowConfirmationModal(true);
            }}
          />
        )}

        <DownloadIcon icon="download" onClick={onDownload} />
      </ActionIcons>
      {showConfirmationModal && onDeleteFile && (
        <ConfirmModal
          show={showConfirmationModal}
          onCancel={hideConfirmationModal}
          onHide={hideConfirmationModal}
          confirmText="Delete Now"
          cancelText="Cancel"
          text="Are you sure you want to delete this file?"
          onConfirm={onConfirmDeletion}
        />
      )}

      {downloadUrl.length > 0 && (
        <Downloader downloadUrl={downloadUrl} setDownloadUrl={setDownloadUrl} />
      )}
    </CompletedFile>
  );
};
