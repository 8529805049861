import {JSONType} from '../app-types';
import {$str, $date, getParser} from './ResponseParser';
import {Model} from '.';

export class Certification extends Model {
  number: string;
  type: string;
  start: Date;
  end: Date;

  static template = () => ({
    number: $str,
    type: $str,
    start: $date,
    end: $date,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<Certification>) {
    return new Certification(getParser(Certification.template)(json));
  }

  constructor(props: Certification) {
    super(props);
    this.number = props.number;
    this.type = props.type;
    this.start = props.start;
    this.end = props.end;
  }
}
