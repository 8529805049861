import {Optional} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import styled from 'styled-components';
import {lg} from '../../../../ui/theme';
import {YesOrNo} from '../../../../ui/form/YesOrNo';

const WorkingReporterStyled = styled.div`
  margin-bottom: ${lg};
  text-align: center;
  width: 100%;
`;

type Props = {
  disabled?: boolean;
  isWorking: Optional<boolean>;
  reportWorking: (working: boolean) => unknown;
};

export const WorkingReporter = ({
  disabled = false,
  isWorking,
  reportWorking,
}: Props) => {
  return (
    <WorkingReporterStyled>
      <YesOrNo
        setValue={v => {
          reportWorking(v);
        }}
        value={isWorking}
        disabled={disabled}
      />
    </WorkingReporterStyled>
  );
};
