/* tslint:disable @typescript-eslint/no-explicit-any no-invalid-this */
import {AppAPI} from './AppAPI';
import {InvalidMethodCall} from './exceptions';
import {Resource} from './Resource';

/**
 * Used to indicate that an API call needs authorization
 * @param target
 * @param key
 * @param descriptor
 */
const requiresAuth = (
  target: AppAPI | Resource,
  key: string,
  descriptor: PropertyDescriptor,
) => {
  return;
};

/**
 * Used to indicate that an API call needs an ID set
 * @param target
 * @param key
 * @param descriptor
 */
const requiresId = (
  target: AppAPI | Resource,
  key: string,
  descriptor: PropertyDescriptor,
) => {
  const original = descriptor.value;
  if (typeof original !== 'function') {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  descriptor.value = function idDecorator(...args: any[]) {
    //@ts-ignore
    const id = this.id;
    if (id) {
      return original.apply(this, args);
    }
    const message = `Accessed an API method '${key}' and ID was ${id ??
      '<undefined>'}; Class is ${target.constructor.name ?? '<unknown>'}`;
    console.error(message);
    throw new InvalidMethodCall(message);
  };
};

export {requiresAuth, requiresId};
