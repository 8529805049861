import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$str, getParser, $num, $opt, $bool} from './ResponseParser';
import {Model, User, LonelyPatient} from '.';
import {getKeys} from '@src/util/objectManipulation/getKeys';

export class Invitation extends Model {
  email!: string;
  patientId!: number;
  patient!: Optional<LonelyPatient>;
  creator!: User;
  isSelf!: boolean;
  relationshipName!: string;

  static template = () => ({
    email: $str,
    patientId: $num,
    patient: $opt(LonelyPatient.fromJSON),
    creator: User.fromJSON,
    isSelf: $bool,
    relationshipName: $str,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<Invitation>) {
    return new Invitation(getParser(Invitation.template)(json));
  }

  constructor(props: Invitation) {
    super(props);
    getKeys(Invitation.template()).forEach(k => {
      if (k !== 'id' && k !== 'updatedAt') {
        //@ts-expect-error
        this[k] = props[k];
      }
    });
  }
}
