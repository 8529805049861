export const maybeStoreRedirectLink = (): void => {
  const path = window.location.pathname;

  if (
    typeof path === 'string' &&
    (path.includes('exam-room') || path.includes('waiting-room'))
  ) {
    window.localStorage.setItem('redirect', window.location.pathname);
  }
};

export const removeRedirectLink = (): void => {
  window.localStorage.removeItem('redirect');
};
