import {KeyboardTimePicker} from '@material-ui/pickers';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import React from 'react';

type Props = {
  name: string;
  error?: string;
  title: string;
  required?: boolean;
  disabled?: boolean;
  value?: ParsableDate;
  onChange?: (d: MaterialUiPickersDate) => unknown;
  minDate?: Date;
  maxDate?: Date;
};

export const TimeInput = ({
  name,
  title = '',
  disabled = false,
  required = false,
  error = '',
  onChange = () => {},
  value,
}: Props) => {
  return (
    <KeyboardTimePicker
      clearable
      required={required}
      name={name}
      placeholder="--:-- --"
      label={title}
      value={value}
      inputVariant="outlined"
      fullWidth
      onChange={onChange}
      disabled={disabled}
      error={!!error}
      helperText={error ?? ''}
      margin="normal"
    />
  );
};
