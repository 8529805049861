import {toSentenceCase} from './toTitleCase';

type RefOptions = {alternate?: boolean; first?: boolean};

export type RefFunction = (
  type: 'nom' | 'Nom' | 'poss' | 'Poss',
  options?: RefOptions,
) => string;

/**
 * A helper function to generate the appropriate reference noun or pronoun for a patient
 * @param patientName
 * @param isUser
 *        first          alternate
 *      | true  | false | true  | false
 * Nom  | I     | You   | They  | Name
 * Poss | My    | Your  | Their | Name's
 */
export const reference = (
  patientName: string,
  isUser?: boolean,
): RefFunction => (
  type,
  {alternate, first} = {alternate: false, first: false},
) => {
  let word = '';
  if (['Nom', 'nom'].includes(type)) {
    if (isUser) {
      word = first ? 'i' : 'you';
    } else {
      word = alternate ? 'they' : patientName;
    }
  } else {
    if (isUser) {
      word = first ? 'my' : 'your';
    } else {
      word = alternate ? 'their' : `${patientName}'s`;
    }
  }

  if (type === 'Nom' || type === 'Poss') {
    return toSentenceCase(word);
  }
  return word;
};

/**
 * A helper function to conjugate verbs based on user
 * @param patientName
 * @param isUser
 */
export const conjugate = (isUser?: boolean) => (
  verb: string,
  person?: number,
) => {
  const match = verb.toLowerCase();

  const conjugations: Record<string, [string, string, string]> = {
    be: ['am', 'are', 'is'],
    do: ['do', 'do', 'does'],
    have: ['have', 'have', 'has'],
  };

  const conj = conjugations[match] ?? [verb, verb, `${verb}s`];

  let word = '';

  if (person && person >= 1 && person <= 3) {
    word = conj[person - 1];
  } else {
    word = conj[isUser ? 1 : 2];
  }

  if (match !== verb) {
    return toSentenceCase(word);
  }
  return word;
};
