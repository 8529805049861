import {Room, RemoteParticipant} from 'twilio-video';

import {Optional} from '@ahanapediatrics/ahana-fp';
import {captureMessage, addBreadcrumb, Severity} from '@sentry/browser';
import {LonelyVisit} from '@src/models';

type ReconnectToTwilioRoomProps = {
  room?: Room | null;
  visit: Optional<LonelyVisit>;
  participants: RemoteParticipant[];
};

export const reconnectToTwilioRoom = async ({
  visit,
  room,
  participants,
}: ReconnectToTwilioRoomProps) => {
  addBreadcrumb({
    category: 'exam-room',
    message: 'Reconnect Info',
    data: {
      room,
      visitIsPresent: visit.isPresent(),
      remoteParticipantsTracks: JSON.stringify(
        participants.map(p => ({
          audioTracks: p.audioTracks,
          videoTracks: p.videoTracks,
          identity: p.identity,
        })),
      ),
      localParticipantTracks: JSON.stringify({
        audioTracks: room?.localParticipant.audioTracks,
        videoTracks: room?.localParticipant.videoTracks,
      }),
    },
    level: Severity.Info,
  });

  captureMessage('Reconnecting to Room');

  location.reload();
};
