import {useCallback, useRef} from 'react';
import {getReturnPath} from '.';
import {UserType, VisitState} from '@src/models';

export const useDestination = (
  userType: UserType,
  visitId: number = 0,
  visitStatus: VisitState = VisitState.UNKNOWN,
): [
  (typeOfDeparture: 'permanent' | 'temporary') => string,
  (d: string) => void,
] => {
  const departureDestination = useRef<string | null>(null);

  const get = useCallback(
    (typeOfDeparture: 'permanent' | 'temporary') =>
      departureDestination.current ??
      getReturnPath(userType, typeOfDeparture, visitId, visitStatus),
    [userType, visitId, visitStatus],
  );

  const set = useCallback((d: string) => {
    departureDestination.current = d ?? '';
  }, []);

  return [get, set];
};
