import {Optional} from '@ahanapediatrics/ahana-fp';
import Cookies from 'cookies-js';
import {User, UserType} from '@src/models';
import {UserId} from '@src/models/User';

// 900s is 15 min
const SUSPENSION_INTERVAL = 900;

export function setHipaaAcknowledgmentCookie(user: Optional<User>): void {
  const aUser = user.orNull();
  const userId = aUser?.id;

  if (user.map(u => u.userType).orNull() !== UserType.Professional) {
    return;
  }

  Cookies.set('hipaaAcknowledged', userId, {expires: SUSPENSION_INTERVAL});

  setTimeout(() => {
    removeHipaaAcknowledgmentCookie();
  }, SUSPENSION_INTERVAL * 1000);
}

export function removeHipaaAcknowledgmentCookie(): void {
  Cookies.set('hipaaAcknowledged', '');
}

export function getHipaaAcknowledgmentCookie(user: Optional<User>): boolean {
  const currentUserId = user.property('id', 0 as UserId);
  const cookieUserId = Cookies.get('hipaaAcknowledged') ?? '-1';
  return currentUserId === +cookieUserId;
}
