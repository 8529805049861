export type SigningResult<T> = T;

export type InProgressFile = {
  /**
   * The file that is being uploaded.
   *
   * Strictly, only the name is required, but this component is designed to receive
   * a File object
   */
  file: {
    name: string;
  };
  /**
   * The upload progress in whole percentage points
   */
  progress: number;
  /**
   * Whether or not the upload has completed.
   */
  completed: boolean;
  /**
   * The MIME type of the uploading file
   */
  contentType?: string;
  /**
   * If an error has occurred during the upload, setting this to a non-empty
   * string will cause the component to indicate an error.
   */
  error?: string;
};

export type UploadedFile<T> = {
  file: T;
  progress: 100;
  completed: true;
  contentType?: string;
  error?: '';
};

export type S3UploaderProps<T> = {
  isInline?: boolean;
  className?: string;
  onAddNewFile: (file: File) => void;
  onUpdateFile: (uploadingFile: File, progress: number) => void;
  onCompleteFile: (file: File) => void;
  onUploadFail: (error: Error, file: File) => void;
  onAttachFileToResource: (
    result: SigningResult<T>,
    file: File,
  ) => Promise<unknown>;
  getSignature: (file: File) => Promise<string>;
  buttonText?: string;
};

export const updateProgress = <T>(
  onUpdateFile: S3UploaderProps<T>['onUpdateFile'],
) => (progress: number, _message: string, uploadingFile: File) => {
  onUpdateFile(uploadingFile, progress);
};

export const onFinishUpload = <T>(
  onCompleteFile: S3UploaderProps<T>['onCompleteFile'],
  onAttachFileToResource: S3UploaderProps<T>['onAttachFileToResource'],
) => async (result: SigningResult<T>, file: File) => {
  await onAttachFileToResource(result, file);
  onCompleteFile(file);
};
