import {
  boolean,
  date,
  number,
  object,
  string,
  ObjectSchemaDefinition,
  ArraySchema,
  array,
  Schema,
} from 'yup';

/**
 * This is a set of simple Yup schemas that can be used to make larger ones
 */

/**
 * A basic number
 */
export const yNumber = number();

/**
 * A basic boolean
 */
export const yBoolean = boolean();

/**
 * A required boolean
 */
export const rqBoolean = (message: string) => yBoolean.required(message);

/**
 * A basic string
 */
export const yString = string()
  .trim()
  .ensure()
  .default('');

/**
 * A required string
 * @param message the message to show on validation failure
 */
export const rqString = (message: string) => yString.required(message);

/**
 * A basic date
 * @param message the message to show when the date is in a bad format
 */
export const yDate = (message: string) => date().typeError(message);

/**
 * A basic object
 * @param fields
 */
export const yObject = <U extends object>(fields: ObjectSchemaDefinition<U>) =>
  object(fields);

export const yArray = <T>(x: Schema<T> | undefined): ArraySchema<T> => array(x);
