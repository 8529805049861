import {useEffect, useCallback, useRef} from 'react';
import ConfigService from '../ConfigService';
import {sharingSCPInVisit} from '../util/sharedCarePlan/isSharedCarePlanPath';

const releaseInfo = ConfigService.getReleaseInfo();

const PAGES_WITHOUT_TIMEOUT = [
  'external-visit',
  'public',
  'exam-room',
  'waiting-room',
  'first-time',
  'documentation',
];

export const ignoreTimeout = (pathname: string) => {
  // Logging for QA.
  console.log('>>>>Timeout triggered');

  const shouldIgnore =
    PAGES_WITHOUT_TIMEOUT.some(p => pathname.startsWith(`/${p}`)) ||
    sharingSCPInVisit();

  // Logging for QA.
  if (shouldIgnore) {
    console.log('>>>>Ignoring timeout');
  } else {
    console.log('>>>Proceeding with timeout');
  }

  return shouldIgnore;
};

export function useInactivityTracker(onTimeout: () => unknown) {
  const timeoutWarningId = useRef<number>();

  const resetUserTimeout = useCallback(() => {
    if (timeoutWarningId.current) {
      clearTimeout(timeoutWarningId.current);
    }
    timeoutWarningId.current = window.setTimeout(() => {
      onTimeout();
    }, releaseInfo.USER_TIMEOUT);
  }, [onTimeout]);

  useEffect(() => {
    resetUserTimeout();
    [
      'mousemove',
      'mousedown',
      'touchstart',
      'click',
      'scroll',
      'keypress',
    ].forEach(eventName => {
      window.addEventListener(eventName, resetUserTimeout);
    });

    return () => {
      [
        'mousemove',
        'mousedown',
        'touchstart',
        'click',
        'scroll',
        'keypress',
      ].forEach(eventName => {
        window.removeEventListener(eventName, resetUserTimeout);
      });
    };
  }, [resetUserTimeout]);
}
