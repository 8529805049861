import {AppAPI, coll, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {Resource} from './Resource';
import {OnCallPeriod, OnCallPeriodType, ProviderDetails} from '@src/models';
import {UserId} from '@src/models/User';

export interface UpdateOnCallPeriodRequest {
  start: Date;
  end: Date;
  selectedCallPools: number[];
}

export interface CreateUnpopulatedPeriodRequest {
  start: Date;
  end: Date;
  type: OnCallPeriodType;
  selectedCallPools: number[];
}

export interface CreatePopulatedPeriodRequest
  extends CreateUnpopulatedPeriodRequest {
  providerId: UserId;
}

class OnCallAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'onCallPeriods');
  }

  @requiresAuth
  @requiresId
  update(request: UpdateOnCallPeriodRequest) {
    return this.do<OnCallPeriod>(
      'put',
      `/${this.id}`,
      request,
      'Something went wrong trying to create an on-call period',
    ).then(single(OnCallPeriod.fromJSON));
  }

  @requiresAuth
  createUnpopulated(rq: CreateUnpopulatedPeriodRequest) {
    return this.do<OnCallPeriod>(
      'post',
      `/unpopulated`,
      rq,
      'Something went wrong trying to create an unpopulated period',
    ).then(single(OnCallPeriod.fromJSON));
  }

  @requiresAuth
  createPopulated(rq: CreatePopulatedPeriodRequest) {
    return this.do<OnCallPeriod>(
      'post',
      `/populated`,
      rq,
      'Something went wrong trying to create an unpopulated period',
    ).then(single(OnCallPeriod.fromJSON));
  }

  @requiresAuth
  @requiresId
  updateProviders(providerIds: UserId[]) {
    return this.do<OnCallPeriod>(
      'put',
      `/${this.id}/providers`,
      {providerIds},
      'Something went wrong trying update Providers for an on call period',
    ).then(single(OnCallPeriod.fromJSON));
  }

  @requiresAuth
  @requiresId
  removeProvider(providerId: UserId) {
    return this.do<OnCallPeriod>(
      'delete',
      `/${this.id}/provider/${providerId}`,
      null,
      'Something went wrong trying delete a provider from an on-call period',
    ).then(single(OnCallPeriod.fromJSON));
  }

  @requiresAuth
  @requiresId
  deleteOnCallPeriod() {
    return this.do<OnCallPeriod>(
      'delete',
      `/${this.id}`,
      null,
      'Something went wrong trying delete an on call period',
    ).then(() => {});
  }

  /**
   * @returns de-duplicated list of `ProviderDetails` combined from
   * each group associated with an `OnCallPeriod`.
   */

  getGroupsProviders() {
    return this.do<ProviderDetails[]>(
      'get',
      `/${this.id}/groupsProviders`,
      null,
      `Something went wrong trying get ProviderDetails for groups associated with ${this.id}`,
    ).then(coll(ProviderDetails.fromJSON));
  }

  @requiresId
  getCurrentPopulatedRoster() {
    return this.do<OnCallPeriod[]>(
      'get',
      `/${this.id}/populated`,
      null,
      `Something went wrong trying get current populated roster for for call pool ${this.id}`,
    ).then(coll(OnCallPeriod.fromJSON));
  }

  @requiresId
  getCurrentRoster() {
    return this.do<OnCallPeriod[]>(
      'get',
      `/${this.id}`,
      null,
      `Something went wrong trying get current roster for for call pool ${this.id}`,
    ).then(coll(OnCallPeriod.fromJSON));
  }

  @requiresAuth
  @requiresId
  getDateRange(start: Date, end: Date) {
    const params = new URLSearchParams();
    params.set('start', start.toISOString());
    params.set('end', end.toISOString());

    return this.do<OnCallPeriod[]>(
      'get',
      `/${this.id}/range`,
      params,
      `Something went wrong trying get current roster for for call pool ${this.id}`,
    ).then(coll(OnCallPeriod.fromJSON));
  }
}

export default OnCallAPI;
