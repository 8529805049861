import {Participant} from 'twilio-video';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {VideoChatEvents, sendEvent} from '../../../../../../../events';
import {NetworkQualityData} from './networkQuality';

const BUFFER_SIZE_LIMIT = 4;
const ACCEPTABLE_QUALITY = 3;
const MINIMUM_STABLE_PROPORTION = 0.5;
const RATE = 1;
const PER = 1000;

type CheckForInstabilityProps = {
  networkQualityData: NetworkQualityData;
  participant: Participant;
  visitId: number;
};

let logBuffer: {
  participant: Participant;
  visitId: number;
  networkQualityData: NetworkQualityData;
}[] = [];

let allowance = RATE;
let lastCheck = window.performance.now();

/**
 *  Whenever the network changes for a local participant, checkForInstability runs.
 *  It adds logging data to the buffer each time, but throttles if there are more updates
 *  in an amount of time than is allowed which is calculated based on `rate` and `per`.
 *
 *  The buffer is loaded with BUFFER_SIZE_LIMIT number of network updates. Once it is full,
 *  we check the proportion of good to bad signals (quantified by ACCEPTABLE_QUALITY)
 *  to see if the proportion MINIMUM_STABLE_PROPORTION of the buffer meets ACCEPTABLE_QUALITY.
 *
 *  Then we send an event to update any components that need the network stability information.
 *  If the connection is unstable, we dump the buffer into the Sentry logs.
 */

export function checkForInstability({
  participant,
  visitId,
  networkQualityData,
}: CheckForInstabilityProps) {
  updateLogBuffer({visitId, participant, networkQualityData});

  const current = window.performance.now();
  const timePassed = current - lastCheck;

  updateLastCheck(current);
  updateAllowance(timePassed);
  const shouldThrottle = allowance > RATE;

  if (shouldThrottle) {
    allowance = RATE;
  }

  if (allowance >= 1.0) {
    if (logBuffer.length >= BUFFER_SIZE_LIMIT) {
      handleLogBufferFull();
    }

    allowance -= 1.0;
  }
}

function updateLastCheck(current: number) {
  lastCheck = current;
}

function updateAllowance(timePassed: number) {
  allowance += timePassed * (RATE / PER);
}

function updateLogBuffer({
  participant,
  visitId,
  networkQualityData,
}: {
  networkQualityData: NetworkQualityData;
  participant: Participant;
  visitId: number;
}) {
  if (logBuffer.length >= BUFFER_SIZE_LIMIT) {
    logBuffer.pop();
  }

  logBuffer.unshift({
    participant,
    visitId,
    networkQualityData,
  });
}

function handleLogBufferFull() {
  if (calculateIsStable({minimumStableProportion: MINIMUM_STABLE_PROPORTION})) {
    sendEvent(VideoChatEvents.StableNetwork);
  } else {
    addBreadcrumb({
      category: 'bad-connection-warning',
      message: 'Visit connection for local participant is unstable',
      data: {
        logBuffer,
      },
      level: Severity.Warning,
    });

    sendEvent(VideoChatEvents.UnstableNetwork);
  }

  resetLogBuffer();
}

function calculateIsStable({
  minimumStableProportion,
}: {
  minimumStableProportion: number;
}): boolean {
  /**
   * Once buffer.length === BUFFER_SIZE_LIMIT, we check that
   * MINIMUM_STABLE_PROPORTION * buffer.length entries must have a networkQualityLevel >=
   * ACCEPTABLE_QUALITY threshold for the network quality to be considered "stable".
   */

  const stable = logBuffer.filter(item => {
    const {
      networkQualityData: {networkQualityLevel},
    } = item;

    return (
      networkQualityLevel !== null && networkQualityLevel >= ACCEPTABLE_QUALITY
    );
  });

  const minimumStableCount = Math.round(
    BUFFER_SIZE_LIMIT * minimumStableProportion,
  );

  return stable.length >= minimumStableCount;
}

function resetLogBuffer() {
  logBuffer.length = 0;
}
