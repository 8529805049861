import {format} from 'date-fns';
import {AppAPI, coll, asIs} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {Resource} from './Resource';
import {StoredReport} from '@src/models/StoredReport';

class StoredReportAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'storedReports');
  }

  @requiresAuth
  getForProfessional() {
    return this.do<StoredReport[]>(
      'get',
      `/`,
      null,
      'Something went wrong trying to get StoredReports',
    ).then(coll(StoredReport.fromJSON));
  }

  @requiresAuth
  @requiresId
  validate() {
    return this.do<void[]>(
      'head',
      `/${this.id}`,
      null,
      'Something went wrong trying to get StoredReports',
    ).then(asIs);
  }

  async getReportUrl(data: {
    callPoolIds: number[];
    start: Date;
    end: Date;
  }): Promise<string> {
    const {callPoolIds, start, end} = data;

    const apiBase = await this.apiBase;

    const url = new URL(`${apiBase}/${this.id}/report.csv`);

    url.searchParams.append('callPoolIds', callPoolIds.join(','));
    url.searchParams.append('end', format(end, 'yyyy-MM-dd'));
    url.searchParams.append('start', format(start, 'yyyy-MM-dd'));

    return url.toString();
  }
}

export default StoredReportAPI;
