import {JSONType} from './app-types';
import {HATEOAS} from './api/HATEOAS';
import {Guardian, Professional, User, UserType} from './models';

const isGuardian = (u: User | JSONType<User>): u is JSONType<Guardian> =>
  u.userType === UserType.Guardian;
const isProfessional = (u: User | HATEOAS<User>): u is HATEOAS<Professional> =>
  u.userType === UserType.Professional;

export class UserFactory {
  static fromJSON<T extends User>(user: HATEOAS<T>): User {
    if (!user) {
      throw new Error('No JSON provided');
    }
    if (isGuardian(user)) {
      return Guardian.fromJSON(user as JSONType<Guardian>) as User;
    }
    if (isProfessional(user)) {
      return Professional.fromHATEOAS(user as HATEOAS<Professional>) as User;
    }

    return User.fromJSON(user);
  }
}
