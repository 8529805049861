import {VisitState, UserType} from '@src/models';
import {EJECT_STATES} from '@src/util/visits';

export const getReturnPath = (
  userType: UserType,
  typeOfDeparture: 'permanent' | 'temporary',
  visitId: number = 0,
  visitStatus: VisitState = VisitState.UNKNOWN,
) => {
  if (userType === UserType.Unknown) {
    return pathForAnonVisitor(typeOfDeparture, visitId, visitStatus);
  }

  return pathForAuthorizedVisitor(
    userType,
    typeOfDeparture,
    visitId,
    visitStatus,
  );
};

export function pathForAnonVisitor(
  typeOfDeparture: 'permanent' | 'temporary',
  visitId: number = 0,
  visitStatus: VisitState = VisitState.UNKNOWN,
) {
  let returnPath = `/dead-end/error/${visitId}`;

  const isEjectState = EJECT_STATES.includes(visitStatus);

  if (typeOfDeparture === 'temporary' && !isEjectState) {
    returnPath = `/dead-end/visitLeft/${visitId}`;
  }

  if (isEjectState) {
    returnPath = `/dead-end/visitOver/${visitId}`;
  }

  return returnPath;
}

export function pathForAuthorizedVisitor(
  userType: UserType,
  typeOfDeparture: 'permanent' | 'temporary',
  visitId: number = 0,
  visitStatus: VisitState = VisitState.UNKNOWN,
) {
  let returnPath = `/dead-end/error/${visitId}`;

  if (
    userType === UserType.Guardian &&
    (visitStatus === VisitState.DOCUMENTING || typeOfDeparture === 'permanent')
  ) {
    returnPath = `/guardianSurvey/${visitId}`;
  } else if (typeOfDeparture === 'temporary') {
    returnPath = `/dead-end/visitLeft/${visitId}`;
  } else {
    const searchParams = new URLSearchParams(window.location.search);
    returnPath =
      searchParams.get('return') ||
      (userType === UserType.Guardian ? '/' : '/on-call');
  }

  return returnPath;
}
