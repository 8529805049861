import {JSONType} from '../app-types';
import {$str, getParser} from './ResponseParser';
import {Model} from '.';

export type $NewLegalDocument = {
  name: string;
  description: string;
  invoiceTargetId: number;
  callPoolIds: number[];
  url: string;
};

export type SimpleDocument = {
  url: string;
  name: string;
};

export class LegalDocument extends Model {
  url: string;
  name: string;
  description: string;

  static template = () => ({
    url: $str,
    name: $str,
    description: $str,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<LegalDocument>) {
    return new LegalDocument(getParser(LegalDocument.template)(json));
  }

  constructor(data: LegalDocument) {
    super(data);
    this.url = data.url;
    this.name = data.name;
    this.description = data.description;
  }
}
