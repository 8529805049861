import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$str, $opt, getParser, $num} from './ResponseParser';
// eslint-disable-next-line import/no-duplicates
import {Patient} from '.';
// eslint-disable-next-line import/no-duplicates
import {Model} from '.';

export class CreditCard extends Model {
  last4: string;
  customerId: string;
  patient: Optional<Patient>;
  callPoolId: number;

  static template = () => ({
    last4: $str,
    customerId: $str,
    patient: $opt(Patient.fromJSON),
    callPoolId: $num,
    ...Model.template(),
  });

  static fromJSON(json: JSONType<CreditCard>) {
    return new CreditCard(getParser(CreditCard.template)(json));
  }

  constructor(props: CreditCard) {
    super(props);

    this.last4 = props.last4;
    this.customerId = props.customerId;
    this.patient = props.patient;
    this.callPoolId = props.callPoolId;
  }
}
