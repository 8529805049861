import {Links} from '../api/HATEOAS';
import {$num, $date} from './ResponseParser';

export class Model {
  readonly id: number;
  readonly updatedAt: Date;
  links?: Links;

  static template = () => ({
    id: $num,
    updatedAt: $date,
  });

  constructor(model: Model) {
    this.id = model.id;
    this.updatedAt = model.updatedAt;
    this.links = model.links ?? {};
  }
}
