import {AppAPI, coll} from './AppAPI';
import {requiresAuth} from './decorators';
import {Resource} from './Resource';
import {Practice} from '@src/models';

class PracticeAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'practices');
  }

  /**
   * Get all Practices
   */
  @requiresAuth
  getAll(): Promise<Practice[]> {
    return this.do<Practice[]>(
      'get',
      '/',
      null,
      'Something went wrong trying to get all Practices',
    ).then(coll(p => Practice.fromJSON(p)));
  }
}

export default PracticeAPI;
