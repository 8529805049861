import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import inflect from '../../../util/stringManipulation/inflect';
import {makeSafeForCSS} from '../../../util/ui/makeSafeForCss';
import {Button} from './Button';
import {ButtonProps} from './Button/types';

const ButtonSpinner = styled(props => {
  const {inProgress, ...rest} = props;
  return <FontAwesomeIcon {...rest} />;
})<{
  inProgress: boolean;
}>`
  display: ${props => (props.inProgress ? 'inline-block' : 'none')};
`;

type Props = ButtonProps & {
  actionInProgress: boolean;
  actionWord: string;
  actionInProgressWord?: string;
  active?: boolean;
};

export function AsyncActionButton({
  bSize = 'normal',
  bStyle = 'primary',
  active = false,
  block = false,
  className = '',
  disabled = false,
  onClick,
  actionWord,
  actionInProgress,
  actionInProgressWord,
  isInRow,
}: Props) {
  let labelClass = makeSafeForCSS(actionWord);
  return (
    <Button
      className={classnames([className, labelClass])}
      bSize={bSize}
      bStyle={bStyle}
      block={block}
      onClick={onClick}
      disabled={disabled || actionInProgress}
      isInRow={isInRow}
    >
      {actionInProgress
        ? actionInProgressWord || inflect(actionWord)
        : actionWord}{' '}
      <ButtonSpinner spin icon={'spinner'} inProgress={actionInProgress} />
    </Button>
  );
}
