import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$opt, $str, $enum, getParser, $date} from './ResponseParser';
import {TrackedModel, ProviderDetails} from '.';

export enum SCPRetractionStatus {
  // noinspection JSUnusedGlobalSymbols
  Retracted = 'retracted',
  Unretracted = 'unretracted',
}

export enum SCPRetractionAction {
  Unretract = 'unretract',
}

export class SCPRetraction extends TrackedModel {
  createdAt: Date;
  ownerDetails: Optional<ProviderDetails>;
  requesterDetails: Optional<ProviderDetails>;
  reasoning: string;
  status: string;

  static template = () => ({
    createdAt: $date,
    ownerDetails: $opt(ProviderDetails.fromJSON),
    requesterDetails: $opt(ProviderDetails.fromJSON),
    reasoning: $str,
    status: $enum(SCPRetractionStatus),
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<SCPRetraction>) {
    return new SCPRetraction(getParser(SCPRetraction.template)(json));
  }

  constructor(props: SCPRetraction) {
    super(props);
    this.createdAt = props.createdAt;
    this.ownerDetails = props.ownerDetails;
    this.requesterDetails = props.requesterDetails;
    this.reasoning = props.reasoning;
    this.status = props.status;
  }
}
