import {Grid, makeStyles, CircularProgress} from '@material-ui/core';
import {CSSProperties} from '@material-ui/styles';
import clsx from 'clsx';
import * as React from 'react';
import {h2} from '../../theme';

export type PageLoadingProps = {
  active: boolean;
  fullSize?: boolean;
  message?: string;
  className?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
};

const useStyles = makeStyles(() => ({
  fullSize: {
    height: '100%',
    width: '100%',
  },
  active: {
    textAlign: 'center',
  },
  loadingMessage: {
    fontSize: h2,
  },
}));

/**
 *
 * A loading indicator that is centered and formatted
 * to take up a page or a medium to large vertical/horizontal section.
 */
export function PageLoading({
  active,
  message = 'Loading...',
  className = '',
  children = null,
  fullSize = false,
  style = {},
}: PageLoadingProps) {
  const classes = useStyles();

  return active ? (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={clsx('loading', className, classes.active, {
        [classes.fullSize]: fullSize,
      })}
      style={{height: '75vh', ...style}}
    >
      <Grid item className={classes.loadingMessage}>
        <CircularProgress />
        <div>{message}</div>
      </Grid>
    </Grid>
  ) : (
    <>{children}</>
  );
}

export default PageLoading;
