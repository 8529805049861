import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';

import {$bool, $str, $opt, getParser} from './ResponseParser';
import {LonelyPatient, User, LegalDocument, Model} from '.';

export class DocumentSignature extends Model {
  intentToSign: boolean;
  consentToSignElectronically: boolean;
  ipAddressOfSigner: string;
  patient: Optional<LonelyPatient>;
  document: LegalDocument;
  user: Optional<User>;

  static template = () => ({
    intentToSign: $bool,
    consentToSignElectronically: $bool,
    ipAddressOfSigner: $str,
    patient: $opt(LonelyPatient.fromJSON),
    document: LegalDocument.fromJSON,
    user: $opt(User.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<DocumentSignature>): DocumentSignature {
    return new DocumentSignature(getParser(DocumentSignature.template)(json));
  }

  constructor(data: DocumentSignature) {
    super(data);
    this.intentToSign = data.intentToSign;
    this.consentToSignElectronically = data.consentToSignElectronically;
    this.ipAddressOfSigner = data.ipAddressOfSigner;
    this.patient = data.patient;
    this.document = data.document;
    this.user = data.user;
  }
}
