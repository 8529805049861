import {Room} from 'twilio-video';
import {isStoppableTrack} from './typeGuards';

export type StopTracksProps = {
  room: Room | null;
};

export function stopTracks({room}: StopTracksProps) {
  room?.localParticipant.tracks.forEach(function(trackPublication) {
    if (isStoppableTrack(trackPublication.track)) {
      trackPublication.track.stop();
    }
  });
}
