import {Optional} from '@ahanapediatrics/ahana-fp';
import {Room} from 'twilio-video';
import {getDeviceIds, saveDeviceIds} from '../../../shared/devices/deviceIds';
import {connectToRoom} from './connect';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';
import {createLocalTwilioTracksFromInputs} from '@src/util/videoChat/twilio/shared';
import {MediaFailure} from '@src/util/videoChat/userMedia/streams';

export type SetUpRoomProps = {
  visitId: number;
  token: string;
  audioIn: Optional<AudioInputInfo>;
  videoIn: Optional<VideoInputInfo>;
  audioOut: Optional<AudioOutputInfo>;
  onMediaFailure: (v: Optional<MediaFailure>) => unknown;
  setRoom: (v: Room) => unknown;
};

export async function setUpRoom({
  visitId,
  token,
  audioIn,
  audioOut,
  videoIn,
  onMediaFailure,
  setRoom,
}: SetUpRoomProps) {
  const choices = getDeviceIds({visitId, videoIn, audioOut, audioIn});

  const {audioInId, audioOutId, videoInId} = choices;

  const localTracks = await createLocalTwilioTracksFromInputs(onMediaFailure)({
    audioInId,
    videoInId,
    audioOutId,
  });

  setRoom(
    await connectToRoom({
      visitId,
      token,
      localTracks,
    }),
  );

  saveDeviceIds({visitId, audioInId, audioOutId, videoInId});
}
