import {AppAPI, asIs, coll, PaginationOptions, single} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {BasicQueryException} from './exceptions';
import {Resource} from './Resource';
import {
  CallPool,
  ClientConfiguration,
  LegalDocument,
  LonelyVisit,
  ProviderDetails,
} from '@src/models';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {GroupManager} from '@src/models/GroupManager';

/** REQUEST TYPES */

export type GetAllOptions = {
  includeVisitsUnsupported?: boolean;
  customerCode?: string;
  includeUnapproved?: boolean;
};

export type PayloadForProviderGroupDetails = {
  nameForProviders: string;
  nameForGuardians: string;
  description: string;
  visitsUnsupported?: boolean;
};

/** REQUEST TYPES */

export class CallPoolAPI extends Resource {
  constructor(uid: string | undefined, api: AppAPI) {
    super(uid, api, 'callPools');
  }

  @requiresAuth
  getOpenScheduledPools() {
    return this.do<CallPool[]>(
      'get',
      `/openScheduledPools`,
      null,
      'Something went wrong trying to get open scheduled call pools',
    ).then(coll(CallPool.fromJSON));
  }

  @requiresAuth
  getAll(request?: {
    includeVisitsUnsupported?: boolean;
    includeUnapproved?: boolean;
    customerCode?: string;
  }) {
    const {
      includeUnapproved = false,
      includeVisitsUnsupported = false,
      customerCode = '',
    } = request ?? {};
    const params = new URLSearchParams();
    params.set(
      'includeVisitsUnsupported',
      `${includeVisitsUnsupported ?? false}`,
    );

    params.set('includeUnapproved', `${includeUnapproved ?? false}`);
    params.set('customerCode', customerCode);

    return this.do<CallPool[]>(
      'get',
      `/`,
      params,
      'Something went wrong trying to get all call pools',
    ).then(coll(CallPool.fromJSON));
  }

  @requiresId
  @requiresAuth
  get() {
    return this.do<CallPool>(
      'get',
      `/${this.id}`,
      null,
      'Something went wrong trying to get a call pool',
    ).then(single(CallPool.fromJSON));
  }

  @requiresId
  @requiresAuth
  getDocuments() {
    return this.do<LegalDocument[]>(
      'get',
      `/${this.id}/documents`,
      null,
      'Something went wrong trying to get `LegalDocuments` for a call pool',
    ).then(coll(LegalDocument.fromJSON));
  }

  /**
   *
   * An addable document is one that belongs to the CallPool's customer but
   * has not been added to the CallPool yet.
   */
  @requiresId
  @requiresAuth
  getAddableDocuments() {
    return this.do<LegalDocument[]>(
      'get',
      `/${this.id}/addableDocuments`,
      null,
      'Something went wrong trying to get addable `LegalDocuments` for a call pool',
    ).then(coll(LegalDocument.fromJSON));
  }

  /**
   *
   * An addable `ClientConfiguration` is one that belongs to the `CallPool`'s `InvoiceTarget` but
   * has not been added to the `CallPool` yet.
   */
  @requiresId
  @requiresAuth
  getAddableClientConfigurations() {
    return this.do<ClientConfiguration[]>(
      'get',
      `/${this.id}/addableClientConfigurations`,
      null,
      'Something went wrong trying to get addable `ClientConfigurations` for a call pool',
    ).then(coll(ClientConfiguration.fromJSON));
  }

  @requiresId
  @requiresAuth
  addClientConfiguration(clientConfigurationId: number) {
    return this.do<{added: boolean}>(
      'put',
      `/${this.id}/clientConfigurations/${clientConfigurationId}`,
      null,
      'Something went wrong trying to add a `ClientConfiguration` to a `CallPool`',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  addDocument(documentId: number) {
    return this.do<{added: boolean}>(
      'put',
      `/${this.id}/documents/${documentId}`,
      null,
      'Something went wrong trying to add a `LegalDocument` to a CallPool',
    ).then(asIs);
  }

  /**
   * This method returns JUST the providers (i.e. not professionals who are Front Desk or otherwise)
   */
  @requiresId
  @requiresAuth
  getMembers() {
    return this.do<ProviderDetails[]>(
      'get',
      `/${this.id}/members`,
      null,
      'Something went wrong trying to get providers for a call pool',
    ).then(coll(ProviderDetails.fromJSON));
  }

  /**
   * Get unreviewed vists for a specific call pool
   */
  @requiresId
  @requiresAuth
  getUnreviewedNotesForCallPool(
    options: PaginationOptions,
  ): Promise<LonelyVisit[]> {
    const {pageSize = 10, start = 0} = options;
    return this.basicGetter<LonelyVisit>(
      `visits/unreviewed?pageSize=${pageSize}&start=${start}`,
      LonelyVisit.fromJSON,
    ).catch(response => {
      throw new BasicQueryException(
        `Something went wrong trying to get a Provider's unreviewed visits for call pool ${this.id}: ${response.status}`,
      );
    });
  }

  @requiresId
  create(rules: {}) {
    return this.do<CallPool>(
      'post',
      `/`,
      rules,
      'Something went wrong trying to get documents for a call pool',
    ).then(single(CallPool.fromJSON));
  }

  @requiresId
  @requiresAuth
  addMember(detailsId: ProviderDetailsId) {
    return this.do<{added: boolean}>(
      'put',
      `/${this.id}/members/${detailsId}`,
      null,
      'Something went wrong trying to add a Provider to a CallPool',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  removeMember(detailsId: ProviderDetailsId) {
    return this.do<{removed: boolean}>(
      'delete',
      `/${this.id}/members/${detailsId}`,
      null,
      'Something went wrong trying to delete a Provider from a CallPool',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  updateDetails(details: PayloadForProviderGroupDetails) {
    return this.do<CallPool>(
      'patch',
      `/${this.id}`,
      details,
      'Something went wrong trying to update a call pool',
    ).then(single(CallPool.fromJSON));
  }

  /** --------- Manager Routes --------- */

  @requiresId
  @requiresAuth
  getManagers() {
    return this.do<GroupManager[]>(
      'get',
      `/${this.id}/managers`,
      null,
      `Something went wrong trying to get managers for Provider Group with id ${this.id}`,
    ).then(coll(GroupManager.fromJSON));
  }

  @requiresId
  @requiresAuth
  addManager(detailsId: ProviderDetailsId) {
    return this.do<{added: boolean}>(
      'put',
      `/${this.id}/managers/${detailsId}`,
      null,
      `Something went wrong trying to add ProviderDetails with id ${detailsId} as manager to Provider Group with id ${this.id}`,
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  removeManager(detailsId: ProviderDetailsId) {
    return this.do<{removed: boolean}>(
      'delete',
      `/${this.id}/managers/${detailsId}`,
      null,
      `Something went wrong trying to delete ProviderDetails with id ${detailsId} as manager from Provider Group ${this.id}`,
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  addEndpoint(endpointId: number) {
    return this.do<{added: boolean}>(
      'put',
      `/${this.id}/endpoints/${endpointId}`,
      null,
      'Something went wrong trying to add an Endpoint to a Group',
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  removeEndpoint(endpointId: number) {
    return this.do<{removed: boolean}>(
      'delete',
      `/${this.id}/endpoints/${endpointId}`,
      null,
      `Something went wrong trying to delete Endpoint with id ${endpointId} from Provider Group ${this.id}`,
    ).then(asIs);
  }

  @requiresId
  @requiresAuth
  removeClientConfiguration(clientConfigurationId: number) {
    return this.do<{removed: boolean}>(
      'delete',
      `/${this.id}/clientConfigurations/${clientConfigurationId}`,
      null,
      `Something went wrong trying to delete ClientConfiguration with id ${clientConfigurationId} from Provider Group ${this.id}`,
    ).then(asIs);
  }
}
