export const canonicalizeNumber = (phoneNumber: string | null = '') => {
  const cleaned = (phoneNumber ?? '')
    .trim()
    .replace(/[^0-9]/g, '')
    .substr(0, 11);

  if (cleaned.length < 10) {
    return '';
  }

  return cleaned.substr(
    cleaned.length === 11 && cleaned[0] === '1' ? 1 : 0,
    10,
  );
};
