import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {
  $str,
  $bool,
  $opt,
  $date,
  $obj,
  $phone,
  getParser,
} from './ResponseParser';
import {TrackedModel} from '.';
import {StreetAddress} from '@src/components/ui/form/AddressInput';

export type ContactRequest = JSONType<Omit<Contact, 'lastUpdatedBy'>>;
export class Contact extends TrackedModel {
  name: string;
  role: string;
  secondNumber: string;
  phone: string;
  isPrimary: boolean;
  isEmergency: boolean;
  dob: Optional<Date>;
  address: StreetAddress;

  static template = () => ({
    name: $str,
    role: $str,
    secondNumber: $str,
    phone: $phone,
    isPrimary: $bool,
    isEmergency: $bool,
    dob: $opt($date),
    address: $obj<StreetAddress>(),
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<Contact>) {
    return new Contact(getParser(Contact.template)(json));
  }

  constructor(props: Contact) {
    super(props);

    this.name = props.name;
    this.role = props.role;
    this.secondNumber = props.secondNumber;
    this.phone = props.phone;
    this.isPrimary = props.isPrimary;
    this.isEmergency = props.isEmergency;
    this.dob = props.dob;
    this.address = props.address;
  }
}
