import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useCallback, useState} from 'react';

/**
 * This hook helps remove some of the boilerplate around asyncronous data.
 *
 * The state is AsyncData<T>
 *
 * The setter has the following behaviour based on the parameter:
 *
 *  - if undefined, sets the state to Loading
 *  - if an error, sets the state to Errored
 *  - otherwise, sets the state the Loaded
 *
 * There's also a `reset` function that can reset the value to `NotAsked`.
 *
 * There's no need to wrap the input value in an array or to explicitly
 * wrap it in an AsyncData call, hence a lot less boilerplate
 *
 * @example
 *
 * ```
 * const [foo, setFoo, reset] = useAsync<Foo>(); // foo is in NotAsked state
 *
 * const getFoo = () => {
 *   setFoo(); // foo is in Loading state
 *   api.requestFoo()
 *     .then(setFoo)
 *     .catch(setFoo);
 *   // foo is now Loaded or Errored
 * }
 */
export type SetState<T> = (v?: T | readonly T[] | Error) => void;

export const useAsync = <T>(
  initialState?: AsyncData<T>,
): [AsyncData<T>, SetState<T>, () => void] => {
  const [state, _setState] = useState<AsyncData<T>>(
    initialState ?? AsyncData.notAsked(),
  );

  const setState = useCallback((newVal?: T | readonly T[] | Error) => {
    if (typeof newVal === 'undefined') {
      _setState(AsyncData.loading());
    } else if (newVal instanceof Error) {
      _setState(AsyncData.errored(newVal));
    } else if (Array.isArray(newVal)) {
      _setState(AsyncData.loaded(newVal));
    } else {
      _setState(AsyncData.loaded([newVal as T]));
    }
  }, []);

  const reset = useCallback(() => _setState(AsyncData.notAsked()), []);

  return [state, setState, reset];
};
