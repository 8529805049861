import {Optional} from '@ahanapediatrics/ahana-fp';
import {createAction} from 'typesafe-actions';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '../reducers/media';

/**
 * Select an audio input for video conferencing
 * @param payload
 */
export const setAudioInput = createAction('SET_AUDIO_INPUT')<
  Optional<AudioInputInfo>
>();

/**
 * Select an audio output for video conferencing
 * @param payload
 */
export const setAudioOutput = createAction('SET_AUDIO_OUTPUT')<
  Optional<AudioOutputInfo>
>();

/**
 * Select an video input for video conferencing
 * @param payload
 */
export const setVideoInput = createAction('SET_VIDEO_INPUT')<
  Optional<VideoInputInfo>
>();

export const setH264Supported = createAction('SET_H264_SUPPORTED')<boolean>();

export const setOtCanPublish = createAction('SET_OT_CAN_PUBLISH')<boolean>();

export const setOtIsPublishing = createAction('SET_OT_IS_PUBLISHING')<
  boolean
>();

export const setHairChecked = createAction('SET_HAIR_CHECKED')<number>();
