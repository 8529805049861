import {Optional} from '@ahanapediatrics/ahana-fp';
import {CallPool, ProviderDetails} from '../models';
import {AppAPI, single, coll} from './AppAPI';
import {requiresAuth, requiresId} from './decorators';
import {Resource} from './Resource';
import {BasicQueryException} from './exceptions';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {UserId} from '@src/models/User';

export interface DetailsRequest {
  id?: ProviderDetailsId;
  email?: string;
  firstName?: string;
  lastName?: string;
  providerId?: UserId;
  practiceId?: number;
  practiceName?: string;
  practicePhone?: string;
  practiceFax?: string;
  searchable?: boolean;
  speciality?: string;
  qualification?: string;
  seesPatients?: boolean;
}

export const convertDetailsToRequest = (
  details: Optional<ProviderDetails>,
): Optional<DetailsRequest> => {
  if (!details.isPresent()) {
    return Optional.of({
      providerId: undefined,
      firstName: '',
      lastName: '',
      email: '',
      speciality: '',
      qualification: '',
    });
  }

  return details.map(pd => {
    const request: DetailsRequest = {
      providerId: pd.providerId ?? undefined,
      firstName: pd.firstName ?? '',
      lastName: pd.lastName ?? '',
      email: pd.email.orElse(''),
      speciality: pd.speciality ?? '',
      qualification: pd.qualification ?? '',
      seesPatients: pd.seesPatients,

      searchable: pd.seesPatients,
    };

    if (pd.id) {
      request.id = pd.id;
    }

    if (pd.practice.isPresent()) {
      const px = pd.practice.get();
      request.practiceId = px.id;
      request.practiceName = px.name;
      request.practicePhone = px.phone ?? '';
      request.practiceFax = px.fax ?? '';
    } else {
      request.practicePhone = pd.practicePhone ?? '';
      request.practiceName = pd.practiceName ?? '';
      request.practiceFax = pd.practiceFax ?? '';
    }

    return request;
  });
};

class ProviderDetailsAPI extends Resource {
  constructor(id: ProviderDetailsId | undefined, api: AppAPI) {
    super(id, api, 'providerDetails');
  }

  @requiresAuth
  findByEmail(email: string): Promise<ProviderDetails[]> {
    const params = new URLSearchParams();
    params.set('email', email);
    return this.do<ProviderDetails[]>('get', `/`, params).then(
      coll(ProviderDetails.fromJSON),
    );
  }

  @requiresAuth
  create(request: DetailsRequest): Promise<ProviderDetails> {
    return this.do<ProviderDetails>('post', `/`, request).then(
      single(ProviderDetails.fromJSON),
    );
  }

  @requiresAuth
  update(request: DetailsRequest): Promise<ProviderDetails> {
    return this.do<ProviderDetails>('put', `/${request.id}`, request).then(
      single(ProviderDetails.fromJSON),
    );
  }

  @requiresId
  @requiresAuth
  get(): Promise<ProviderDetails> {
    return this.do<ProviderDetails>('get', `/${this.id}`)
      .then(single(ProviderDetails.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching call pools: ${response}`,
        );
      });
  }

  /**
   * Useful for getting groups for someone without an underlying `Provider` association.
   * Example use-case: priming a group before `Providers` have signed up.
   */

  @requiresId
  @requiresAuth
  getMemberGroups({
    includeUnapproved,
  }: {
    includeUnapproved?: boolean;
  }): Promise<CallPool[]> {
    const params = new URLSearchParams();

    params.set('includeUnapproved', `${includeUnapproved ?? false}`);

    return this.do<CallPool[]>('get', `/${this.id}/callPools`, params)
      .then(coll(CallPool.fromJSON))
      .catch(response => {
        throw new BasicQueryException(
          `Something went wrong fetching call pools: ${response}`,
        );
      });
  }
}

export default ProviderDetailsAPI;
