import {Optional} from '@ahanapediatrics/ahana-fp';
import {isBefore, subHours} from 'date-fns';
import {JSONType} from '../app-types';
import {WithoutMethods} from '../WithoutMethods';
import {AppFile} from './AppFile';
import {Model} from './Model';
import {Patient, LonelyPatient} from './Patient';
import {VisitDocumentation} from './VisitDocumentation';
import {
  $str,
  $num,
  $opt,
  $arr,
  $obj,
  $bool,
  $date,
  $enum,
  getParser,
  $nullable,
} from './ResponseParser';
import {ProviderDetailsId} from './ProviderDetails';
import {
  CallPool,
  Endpoint,
  SharedCarePlan,
  ProviderDetails,
  AssignedForm,
} from '.';

export enum VisitState {
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  CREATED = 'created',
  DOCUMENTING = 'documenting',
  EXPIRED = 'expired',
  IN_PROGRESS = 'inProgress',
  UNKNOWN = 'unknown',
}

export enum VisitAction {
  BEGIN = 'begin',
  CANCEL = 'cancel',
  COMPLETE = 'complete',
  DOCUMENT = 'document',
  EXPIRE = 'expire',
}

export type $NewVisit = {
  blankFormIds?: number[];
  callPoolId?: number;
  chiefComplaint?: string;
  comments?: Array<Comment>;
  dontUseCreditCard: boolean;
  dontUseInsurance: boolean;
  endpointId?: Endpoint['id'];
  fallbackNumber?: string;
  healthcareNotes: string;
  onDemand: boolean;
  providerDetails?: {id: ProviderDetailsId};
  scpId?: number;
  start: Date;
  status?: VisitState;
};

export class SimpleVisit extends Model {
  readonly uid: string;
  assignedForms: Optional<Array<AssignedForm>>;
  callPoolId: number;
  callPool: Optional<CallPool>;
  chiefComplaint: string;
  createdAt: Date;
  fallbackNumber: string;
  files: Array<AppFile>;
  healthcareNotes: string | null;
  locationAffirmation: {
    state: string;
  } | null;
  notesDownloaded: boolean;
  providerDetails: Optional<ProviderDetails>;
  start: Date;
  status: VisitState;
  lastTransition: Date;
  isSessionComplete: boolean;
  isCancelable: boolean;
  isResolved: boolean;
  isExpirable: boolean;
  isCompletable: boolean;
  isLive: boolean;
  onDemand: boolean;
  dontUseInsurance: boolean;
  dontUseCreditCard: boolean;
  endpoint: Optional<Endpoint>;
  endpointId: Optional<number>;
  readonly visitDocumentation: Optional<VisitDocumentation>;

  static template = () => ({
    assignedForms: $opt($arr(AssignedForm.fromJSON)),
    uid: $str,
    callPoolId: $num,
    callPool: $opt(CallPool.fromJSON),
    chiefComplaint: $str,
    createdAt: $date,
    fallbackNumber: $str,
    files: $arr(AppFile.fromJSON),
    healthcareNotes: $nullable($str),
    locationAffirmation: $nullable($obj<{state: string}>()),
    notesDownloaded: $bool,
    providerDetails: $opt(ProviderDetails.fromJSON),
    start: $date,
    status: $enum(VisitState),
    lastTransition: $date,
    isSessionComplete: $bool,
    isCancelable: $bool,
    isResolved: $bool,
    isExpirable: $bool,
    isCompletable: $bool,
    isLive: $bool,
    onDemand: $bool,
    dontUseInsurance: $bool,
    dontUseCreditCard: $bool,
    endpoint: $opt(Endpoint.fromJSON),
    endpointId: $opt($num),
    visitDocumentation: $opt(VisitDocumentation.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<SimpleVisit>): SimpleVisit {
    return new SimpleVisit(getParser(SimpleVisit.template)(json));
  }

  constructor(props: WithoutMethods<SimpleVisit>) {
    super(props);
    this.assignedForms = Optional.of(props.assignedForms);
    this.uid = props.uid;
    this.callPoolId = props.callPoolId;
    this.callPool = props.callPool;
    this.chiefComplaint = props.chiefComplaint;
    this.createdAt = new Date(props.createdAt);
    this.fallbackNumber = props.fallbackNumber;
    this.files = props.files ? props.files.map(f => new AppFile(f)) : [];
    this.healthcareNotes = props.healthcareNotes;
    this.locationAffirmation = props.locationAffirmation;
    this.notesDownloaded = props.notesDownloaded;
    this.providerDetails = Optional.of(props.providerDetails);
    this.start = new Date(props.start);
    this.status = props.status;
    this.lastTransition = new Date(props.lastTransition);
    this.isCancelable = props.isCancelable;
    this.isSessionComplete = props.isSessionComplete;
    this.isResolved = props.isResolved;
    this.isExpirable = props.isExpirable;
    this.isCompletable = props.isCompletable;
    this.isLive = props.isLive;
    this.onDemand = props.onDemand;
    this.dontUseInsurance = props.dontUseInsurance;
    this.dontUseCreditCard = props.dontUseCreditCard;
    this.endpoint = Optional.of(props.endpoint);
    this.endpointId = props.endpointId;
    this.visitDocumentation = props.visitDocumentation;
  }

  wasAbandoned() {
    return (
      this.isCompletable &&
      isBefore(this.lastTransition, subHours(new Date(), 4))
    );
  }

  wasAborted() {
    return [VisitState.CANCELED, VisitState.EXPIRED].includes(this.status);
  }
}

export class LonelyVisit extends SimpleVisit {
  patient: LonelyPatient;

  static template = () => ({
    patient: LonelyPatient.fromJSON,
    ...SimpleVisit.template(),
  });

  static fromJSON(json: JSONType<LonelyVisit>): LonelyVisit {
    return new LonelyVisit(getParser(LonelyVisit.template)(json));
  }

  static fromSimpleVisit(patient: LonelyPatient) {
    return (simple: SimpleVisit) => new LonelyVisit({...simple, patient});
  }

  constructor(props: WithoutMethods<LonelyVisit>) {
    super(props);
    this.patient = new LonelyPatient(props.patient);
  }
}

export class Visit extends SimpleVisit {
  patient: Patient;
  scp: Optional<SharedCarePlan>;
  providerDetails: Optional<ProviderDetails>;

  static template = () => ({
    scp: $opt(SharedCarePlan.fromJSON),
    patient: Patient.fromJSON,

    ...SimpleVisit.template(),
  });

  static fromJSON(json: JSONType<Visit>): Visit {
    return new Visit(getParser(Visit.template)(json));
  }

  static fromSimpleVisit(patient: Patient, scp: Optional<SharedCarePlan>) {
    return (simple: SimpleVisit) => new Visit({...simple, patient, scp});
  }

  constructor(props: WithoutMethods<Visit>) {
    super(props);
    this.patient = new Patient(props.patient);
    this.scp = props.scp;
    this.providerDetails = props.providerDetails;
  }
}

export class SafeVisit {
  readonly uid: string;
  id: number;
  status: VisitState;

  static template = () => ({
    uid: $str,
    id: $num,
    status: $enum(VisitState),
  });

  static fromJSON(json: JSONType<SafeVisit>) {
    return new SafeVisit(getParser(SafeVisit.template)(json));
  }

  constructor(props: SafeVisit) {
    this.uid = props.uid;
    this.id = props.id;
    this.status = props.status;
  }
}
