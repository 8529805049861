import {JSONType} from '../app-types';
import {$str, $date, getParser} from './ResponseParser';

export class ConnectionToken {
  uid: string;
  readonly jwt: string;
  readonly expires: Date;
  readonly updatedAt: Date;

  static template = () => ({
    uid: $str,
    jwt: $str,
    expires: $date,
    updatedAt: $date,
  });

  static fromJSON(json: JSONType<ConnectionToken>) {
    return new ConnectionToken(getParser(ConnectionToken.template)(json));
  }

  constructor(props: ConnectionToken) {
    this.updatedAt = props.updatedAt;
    this.uid = props.uid;
    this.jwt = props.jwt;
    this.expires = props.expires;
  }
}
