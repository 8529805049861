import {
  LocalVideoTrack,
  Room,
  TrackPublication,
  Participant,
} from 'twilio-video';
import {getScreenshareStream} from '../../../userMedia/streams';
import {Tracks} from '../../shared';
import {flashError} from '../../../../../components/shared/notifications/flash';
import {attachStopScreenshareListener} from './eventListeners';
import {stopLocalVideoTracks} from './video';
import {ScreenShareException} from '@src/api/exceptions';
import {isFirefox} from '@src/util/browserTools';

type SetUpScreenSharingTrackProps = {
  room: Room;
  setVideoTracks: (fn: (vts: Tracks) => Tracks) => void;
};

export async function setUpScreensharingTrack({
  room,
  setVideoTracks,
}: SetUpScreenSharingTrackProps) {
  try {
    const stream = await getScreenshareStream();
    const track = stream.getTracks()[0];

    if (detectIsAnyoneScreensharing({room})) {
      track.stop();
      throw new ScreenShareException(
        'Sorry! Another person is already presenting their screen. Only one person can present their screen at a time.',
        {code: 'RoomAlreadyScreenSharing'},
      );
    }

    stopLocalVideoTracks({room});

    const createdScreenTrack: LocalVideoTrack = new LocalVideoTrack(track, {
      name: 'screen-share',
      logLevel: 'info',
    });

    attachStopScreenshareListener({
      screenTrack: createdScreenTrack,
      room,
    });

    room.localParticipant.publishTrack(createdScreenTrack);
    setVideoTracks(vts => [createdScreenTrack, ...vts].filter(t => !!t));
  } catch (error) {
    handleErrors(error);
  }
}

function handleErrors(error: Error) {
  console.error(`Screen share failed with the following error: ${error}`);

  const isNotAllowed = error.name === 'NotAllowedError';
  const didParticipantChangeMind = isNotAllowed && !isFirefox();

  if (didParticipantChangeMind) {
    return;
  }

  if (isNotAllowed && isFirefox()) {
    flashError(
      'It looks like your browser is set to block screen sharing. Please click on the flashing red camera button at the top left of your browser to allow screen sharing',
      {permanent: true},
    );
    return;
  }

  if (error instanceof ScreenShareException) {
    if (error.details.code === 'RoomAlreadyScreenSharing') {
      flashError(
        'Sorry! Another person is already presenting their screen. Only one person can present their screen at a time.',
        {permanent: true},
      );
      return;
    }

    if (error.details.code === 'NotSupported') {
      flashError(error.message, {permanent: true});
      return;
    }
  }

  flashError(
    'Something went wrong trying to present your screen. Please try again or contact support.',
    {permanent: true},
  );
}

export function findScreenSharingParticipant({
  room,
}: {
  room: Room;
}): [Participant, TrackPublication, 'remote' | 'local'] | [] {
  // Check if local participant is screen sharing.
  for (let [, trackPublication] of room?.localParticipant.tracks) {
    if (
      trackPublication.trackName === 'screen-share' ||
      trackPublication?.track.name === 'screen-share'
    ) {
      return [room?.localParticipant, trackPublication, 'local'];
    }
  }

  // Check if any remote participant is screen sharing.
  for (let [, participant] of [...room?.participants]) {
    for (let [, trackPublication] of participant.tracks) {
      if (trackPublication.trackName === 'screen-share') {
        return [participant, trackPublication, 'remote'];
      }
    }
  }

  return [];
}

export function detectIsAnyoneScreensharing({
  room,
}: {
  room: Room | null;
}): boolean {
  if (room) {
    const [participant] = findScreenSharingParticipant({
      room,
    });

    return Boolean(participant);
  }

  return false;
}

export function detectIsLocalScreensharing({
  room,
}: {
  room: Room | null;
}): boolean {
  if (room) {
    const [participant] = findScreenSharingParticipant({
      room,
    });

    return Boolean(
      participant && participant?.sid === room.localParticipant.sid,
    );
  }

  return false;
}
