/**
 * Returns the best estimate of the viewport height
 */
export const getViewportHeight = () => {
  if ('visualViewport' in window) {
    return window.visualViewport.height;
  }

  return Math.min(
    document.documentElement.clientHeight || Number.POSITIVE_INFINITY,
    window.innerHeight || Number.POSITIVE_INFINITY,
  );
};
