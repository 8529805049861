// @ts-ignore
import {Grid} from '@material-ui/core';
import S3Uploader from '@ohanapediatrics/react-s3-uploader';
import {addBreadcrumb, captureException, Severity} from '@sentry/browser';
import React from 'react';
import {useStyles} from '../../../form/Button/styles';
import {onFinishUpload, S3UploaderProps, updateProgress} from './types';
import {MediaUploaderContainer} from './layout';
import {useGetSignedUrl} from '@src/hooks/s3Uploading/useGetSignedUrl';

export function S3UploaderContainer<T>({
  className,
  onAddNewFile,
  onAttachFileToResource,
  onCompleteFile,
  onUpdateFile,
  onUploadFail,
  getSignature,
  buttonText,
  isInline = false,
}: S3UploaderProps<T>) {
  const classes = useStyles();
  const getSignedUrl = useGetSignedUrl({getSignature, onAddNewFile});

  const startUpload = (file: File, cb: () => void) => {
    addBreadcrumb({
      category: 'image-uploader',
      message: 'Start Upload',
      data: {
        file,
      },
      level: Severity.Info,
    });
    return getSignedUrl(file, cb);
  };

  const failUpload = (error: Error, file: File) => {
    addBreadcrumb({
      category: 'image-uploader',
      message: 'Upload Failed',
      data: {
        file,
      },
      level: Severity.Error,
    });
    captureException(error);
    return onUploadFail(error, file);
  };

  return (
    <Grid container direction="row" className={className}>
      <MediaUploaderContainer
        style={{width: 'fit-content'}}
        className={classes.contained}
      >
        <span style={{fontWeight: 500}}>{buttonText ?? 'Choose file...'}</span>
        <S3Uploader
          capture
          autoUpload
          contentDisposition="auto"
          isInline={(contentType: string) =>
            contentType.startsWith('image/') ||
            contentType.startsWith('video/') ||
            isInline
          }
          getSignedUrl={startUpload}
          onProgress={updateProgress(onUpdateFile)}
          onFinish={onFinishUpload(onCompleteFile, onAttachFileToResource)}
          onError={failUpload}
          uploadRequestHeaders={{}}
        />
      </MediaUploaderContainer>
    </Grid>
  );
}
