import {JSONType} from '../app-types';
import {$date, $enum, $arr, getParser} from './ResponseParser';
import {Professional} from './Professional';
import {CallPool, Model} from '.';

export enum OnCallPeriodType {
  // noinspection JSUnusedGlobalSymbols
  Primary = 'primary',
  Backup = 'backup',
  Voluntary = 'voluntary',
}

export class OnCallPeriod extends Model {
  start: Date;
  end: Date;
  type: OnCallPeriodType;
  providers: Professional[];
  callPools: CallPool[];

  static template = () => ({
    start: $date,
    end: $date,
    type: $enum(OnCallPeriodType),
    providers: $arr(Professional.fromJSON),
    callPools: $arr(CallPool.fromJSON),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<OnCallPeriod>) {
    return new OnCallPeriod(getParser(OnCallPeriod.template)(json));
  }

  constructor(props: OnCallPeriod) {
    super(props);

    this.start = props.start;
    this.end = props.end;
    this.type = props.type;
    this.providers = props.providers;
    this.callPools = props.callPools;
  }
}
