import {Optional} from '@ahanapediatrics/ahana-fp';
import {$arr, $bool, $opt, $str, getParser} from './ResponseParser';
import {Model} from './Model';
import {DateField} from './DateField';
import {MultipleChoiceField} from './MultipleChoiceField';
import {NumericField} from './NumericField';
import {TextField} from './TextField';
import {StaticContent} from './StaticContent';
import {JSONType} from '@src/app-types';

export class BlankForm extends Model {
  name: string;
  description: Optional<string>;
  signatureRequired: boolean;
  dateFields: Optional<DateField[]>;
  multipleChoiceFields: Optional<MultipleChoiceField[]>;
  textFields: Optional<TextField[]>;
  numericFields: Optional<NumericField[]>;
  staticContent: Optional<StaticContent[]>;

  static template = () => ({
    name: $str,
    description: $opt($str),
    signatureRequired: $bool,
    dateFields: $opt($arr(DateField.fromJSON)),
    multipleChoiceFields: $opt($arr(MultipleChoiceField.fromJSON)),
    textFields: $opt($arr(TextField.fromJSON)),
    numericFields: $opt($arr(NumericField.fromJSON)),
    staticContent: $opt($arr(StaticContent.fromJSON)),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<BlankForm>): BlankForm {
    return new BlankForm(getParser(BlankForm.template)(json));
  }

  constructor(props: BlankForm) {
    super(props);

    this.name = props.name;
    this.description = props.description;
    this.signatureRequired = props.signatureRequired;
    this.dateFields = Optional.of(props.dateFields);
    this.multipleChoiceFields = Optional.of(props.multipleChoiceFields);
    this.textFields = Optional.of(props.textFields);
    this.numericFields = Optional.of(props.numericFields);
    this.staticContent = Optional.of(props.staticContent);
  }
}
