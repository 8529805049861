import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$num, $opt, getParser, $str, $bool, $opaque} from './ResponseParser';
import {LonelyPatient} from './Patient';
import {NonProfessionalId} from './ResponsiblePerson';
import {ResponsiblePerson, User, Model} from '.';

export class PatientRelationship extends Model {
  personId: NonProfessionalId;
  patientId: number;
  relationship: string;
  person: ResponsiblePerson;
  isGuardian: boolean;
  isSelf: boolean;
  isFinanciallyResponsible: boolean;
  creator: Optional<User>;

  static template = () => ({
    personId: $opaque<NonProfessionalId>(),
    patientId: $num,
    relationship: $str,
    isGuardian: $bool,
    isSelf: $bool,
    isFinanciallyResponsible: $bool,
    person: ResponsiblePerson.fromJSON,
    creator: $opt(User.fromJSON),

    ...Model.template(),
  });

  static fromJSON(json: JSONType<PatientRelationship>) {
    return new PatientRelationship(
      getParser(PatientRelationship.template)(json),
    );
  }

  constructor(data: PatientRelationship) {
    super(data);
    this.personId = data.personId;
    this.patientId = data.patientId;
    this.relationship = data.relationship ?? '';
    this.person = data.person;
    this.creator = data.creator;
    this.isGuardian = data.isGuardian;
    this.isSelf = data.isSelf;
    this.isFinanciallyResponsible = data.isFinanciallyResponsible;
  }
}

export class Relationship extends PatientRelationship {
  patient: LonelyPatient;

  static template = () => ({
    patient: LonelyPatient.fromJSON,

    ...PatientRelationship.template(),
  });

  static fromJSON(json: JSONType<Relationship>) {
    return new Relationship(getParser(Relationship.template)(json));
  }

  constructor(data: Relationship) {
    super(data);
    this.patient = data.patient;
  }
}
