import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {ActionType, getType} from 'typesafe-actions';
import * as configuration from '../actions/configuration';

export type ConfigurationAction = ActionType<typeof configuration>;

type CustomerVisitType = 'onDemand' | 'scheduled';
export type CustomerConfig = {
  supportedVisitTypes?: CustomerVisitType[];
  logoutDestination?: string;
};

export type AppConfig = {
  guardianConsentDocs: number[];
  providerConsentDocs: number[];
  customerConfig?: CustomerConfig;
};
export type ConfigurationState = AsyncData<AppConfig>;

export default (
  state: ConfigurationState = AsyncData.notAsked<AppConfig>(),
  action: ConfigurationAction,
) => {
  switch (action.type) {
    case getType(configuration.setConfig):
      return AsyncData.loaded([action.payload]);
    case getType(configuration.setLoadingConfig):
      return AsyncData.loading<AppConfig>();
    default:
      return state;
  }
};
