import {capitalize} from 'lodash';
import {User, UserType, Guardian, ProviderDetails, Identifier} from '../models';
import {AppAPI, coll, single} from './AppAPI';
import {AppBaseException, ResourceConflictException} from './exceptions';
import {requiresAuth, requiresId} from './decorators';
import {Resource} from './Resource';
import {NAMES} from '@src/components/ui/layout/text/names';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

interface SurveyRequest {
  npsScore?: number | undefined;
  comments?: string | undefined;
  visitOccurred?: boolean;
}

const enum UserAPIExceptions {
  ConvertToUserException = 'ConvertToUserException',
}

class UserAPI extends Resource {
  constructor(id: number | undefined, api: AppAPI) {
    super(id, api, 'users');
  }

  /**
   * Get all Users
   * @returns
   */
  @requiresAuth
  getAll(): Promise<User[]> {
    return this.do<User[]>(
      'get',
      '/',
      null,
      'Something went wrong trying to get all Users',
    ).then(coll(u => User.fromJSON(u)));
  }

  /**
   * Transforms the caller into a Guardian
   */
  transformToGuardian() {
    return this.do<Guardian>(
      'post',
      `/userType`,
      {userType: UserType.Guardian},
      'Something went wrong trying to transform this User into a Guardian',
    ).then(single(Guardian.fromJSON));
  }

  @requiresAuth
  @requiresId
  getProviderDetails(): Promise<ProviderDetails> {
    return this.do<ProviderDetails>(
      'get',
      `/${this.id}/providerDetails`,
      null,
      "Something went wrong trying to get a user's basic demographics",
    ).then(single(ProviderDetails.fromJSON));
  }

  @requiresAuth
  async convertToUser({
    email,
    providerDetailsId,
  }: {
    providerDetailsId: ProviderDetailsId;
    email: string;
  }): Promise<Identifier> {
    return this.do<Identifier>(
      'post',
      `/${providerDetailsId}`,
      {email},
      `Something went wrong trying to create a ${capitalize(
        NAMES.user,
      )} from ProviderDetails`,
    )
      .then(single(Identifier.fromJSON))
      .catch(e => {
        if (e instanceof ResourceConflictException) {
          throw new ResourceConflictException();
        }

        throw new AppBaseException(UserAPIExceptions.ConvertToUserException, {
          details: e,
        });
      });
  }

  @requiresAuth
  @requiresId
  async submitSurvey(surveyRequest: SurveyRequest): Promise<void> {
    await this.do<{}>(
      'post',
      `/${this.id}/survey`,
      surveyRequest,
      'Something went wrong trying submit a survey',
    );
  }
}

export default UserAPI;
