import {JSONType} from '../app-types';
import {$bool, $str, $num, getParser} from './ResponseParser';
import {TrackedModel} from '.';

export type InsuranceType = 'primary' | 'secondary' | 'unknownDetails';

export class PaymentInformation extends TrackedModel {
  hasHealthInsurance: boolean;
  hasInsuranceAndUnknownDetails: boolean;
  insuranceProvider: string;
  insuranceId: string;
  insuranceGroup: string;
  hasMedicaid: boolean;
  patientId: number;
  isPrimary: boolean;

  static template = () => ({
    hasHealthInsurance: $bool,
    hasInsuranceAndUnknownDetails: $bool,
    insuranceProvider: $str,
    insuranceId: $str,
    insuranceGroup: $str,
    hasMedicaid: $bool,
    patientId: $num,
    isPrimary: $bool,
    ...TrackedModel.template(),
  });

  static fromJSON(json: JSONType<PaymentInformation>) {
    return new PaymentInformation(getParser(PaymentInformation.template)(json));
  }

  constructor(props: PaymentInformation) {
    super(props);
    this.hasHealthInsurance = props.hasHealthInsurance;
    this.hasInsuranceAndUnknownDetails = props.hasInsuranceAndUnknownDetails;
    this.insuranceProvider = props.insuranceProvider;
    this.insuranceId = props.insuranceId;
    this.insuranceGroup = props.insuranceGroup;
    this.hasMedicaid = props.hasMedicaid;
    this.patientId = props.patientId;
    this.isPrimary = props.isPrimary;
  }
}
