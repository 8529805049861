/// <reference types="webrtc" />
import {Either} from '@ahanapediatrics/ahana-fp';
import {addBreadcrumb, captureException, Severity} from '@sentry/browser';
import {isSupported} from 'twilio-video';
import 'webrtc-adapter';
import {Devices} from '../../../components/shared/VideoChat/CheckHairRoom/SessionConfigForm/MediaDeviceManager';
import {ScreenShareException} from '@src/api/exceptions';

export enum MediaFailure {
  None,
  DeviceNotFound,
  PermissionDenied,
  PermissionDismissed,
  DeviceInUse,
  NoDevicesFound,
  NotSupported,
  DeviceCannotBeStarted,
  Unknown,
}

export type PossibleStream = Either<MediaFailure, MediaStream>;

let mediaPermission: Promise<PossibleStream>;
export const getMediaPermission = async (): Promise<PossibleStream> => {
  if (!mediaPermission) {
    console.log('Getting user permission');
    mediaPermission = navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then(stream => Either.right<MediaFailure, MediaStream>(stream))
      .catch(e => handleWebRTCError('Getting Permissions')(e));
  }
  (await mediaPermission).mapRight(stream => {
    stream.getTracks().forEach(track => track.stop());
  });
  return mediaPermission;
};

/**
 * Get UserMedia according to the passed in constraints
 * @param constraints
 */
export const getUserMedia = (taskInHand: string) => async (
  constraints: MediaStreamConstraints,
): Promise<PossibleStream> => {
  if (!isSupported) {
    return Either.left(MediaFailure.NotSupported);
  }
  await getMediaPermission();
  console.debug(
    `Doing ${taskInHand} with contraints: ${JSON.stringify(constraints)}`,
  );
  try {
    return Either.right(await navigator.mediaDevices.getUserMedia(constraints));
  } catch (err) {
    return handleWebRTCError(taskInHand)(err);
  }
};

/**
 * Stops the passed in stream
 */
export const stopStream = (stream: MediaStream) => {
  if ('getVideoTracks' in stream) {
    stream.getVideoTracks().forEach(track => track.stop());
  }
};

const ErrorEncoder: Record<string, MediaFailure> = {
  OverconstrainedError: MediaFailure.DeviceNotFound,
  ConstraintNotSatisfiedError: MediaFailure.DeviceNotFound,
  NotFoundError: MediaFailure.DeviceNotFound,
  DevicesNotFoundError: MediaFailure.DeviceNotFound,
  NotReadableError: MediaFailure.DeviceInUse,
  NotSupportedError: MediaFailure.NotSupported,
  TrackStartError: MediaFailure.DeviceInUse,
  SourceUnavailableError: MediaFailure.DeviceInUse,
  NotAllowedError: MediaFailure.PermissionDenied,
  PermissionDeniedError: MediaFailure.PermissionDenied,
  PermissionDismissedError: MediaFailure.PermissionDismissed,
};

const handleWebRTCError = (taskInHand: string) => <T>(err: Error) => {
  addBreadcrumb({
    category: 'media',
    message: `Media Error while doing ${taskInHand}`,
    data: {
      err,
    },
    level: Severity.Info,
  });
  captureException(err);
  const {name} = err;
  console.log(`MediaError: ${name} while ${taskInHand}`);
  return Either.left<MediaFailure, T>(
    ErrorEncoder[name] || MediaFailure.Unknown,
  );
};

/**
 * Get a list of the currently available media devices
 * (e.g.: cameras, microphones and speakers)
 *
 * @throws Error if there's no way to enumerate the devices
 */
export const getMediaDevices = async (
  taskInHand: string,
): Promise<Either<MediaFailure, MediaDeviceInfo[]>> => {
  try {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      return Either.right(await navigator.mediaDevices.enumerateDevices());
    }
    return Either.left(MediaFailure.NotSupported);
  } catch (err) {
    return handleWebRTCError(taskInHand)(err);
  }
};

/**
 * Takes a list of MediaDeviceInfo objects and partitions it by `kind`
 *
 * @param devices
 */
const KINDS: Record<MediaDeviceInfo['kind'], keyof Devices> = {
  audiooutput: 'audioOutputs',
  audioinput: 'audioInputs',
  videoinput: 'videoInputs',
};
export const categorizeDevices = (devices: MediaDeviceInfo[]): Devices =>
  devices.reduce(
    (result, device) => {
      const category = KINDS[device.kind];
      return {
        ...result,
        [category]: [...result[category], device],
      };
    },
    {
      audioOutputs: [],
      audioInputs: [],
      videoInputs: [],
    },
  );

/*
 * Browsers have a limit on the number of running AudioContexts, so we close the old
 * one before creating the new one
 */
let context: AudioContext;
export const getAudioContext = (): AudioContext => {
  if (!context) {
    context = new AudioContext();
  }

  return context;
};

export async function getScreenshareStream() {
  const mediaDevices = navigator.mediaDevices;

  if (!('getDisplayMedia' in mediaDevices)) {
    throw new ScreenShareException(
      "Oops! This browser doesn't support screen sharing.",
      {code: 'NotSupported'},
    );
  }

  // @ts-ignore
  return mediaDevices?.getDisplayMedia({video: true});
}
