import {AppAPI, coll} from './AppAPI';
import {requiresAuth} from './decorators';
import {Resource} from './Resource';
import {BlankForm} from '@src/models/BlankForm';

class BlankFormsAPI extends Resource {
  constructor(api: AppAPI) {
    super(0, api, 'blankForms');
  }

  /**
   * Get the blank forms available to the current user
   */
  @requiresAuth
  getAll(): Promise<BlankForm[]> {
    return this.do<BlankForm[]>(
      'get',
      `/`,
      null,
      'Something went wrong trying to get blank forms',
    ).then(coll(BlankForm.fromJSON));
  }
}

export default BlankFormsAPI;
