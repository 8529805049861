import {Optional} from '@ahanapediatrics/ahana-fp';
import {JSONType} from '../app-types';
import {$arr, $opt, $str, getParser} from './ResponseParser';
import {Model} from '.';

export type SupportedVisitType = 'onDemand' | 'scheduled';

export type $NewClientConfiguration = {
  onDemand?: boolean;
  scheduled?: boolean;
  logoutDestination?: string;
  invoiceTargetId: number;
  customerCode: string;
};

export class ClientConfiguration extends Model {
  supportedVisitTypes: string[];
  customerCode: string;
  logoutDestination: Optional<string>;

  static template = () => ({
    supportedVisitTypes: $arr($str),
    customerCode: $str,
    logoutDestination: $opt($str),
    ...Model.template(),
  });

  static fromJSON(json: JSONType<ClientConfiguration>) {
    return new ClientConfiguration(
      getParser(ClientConfiguration.template)(json),
    );
  }

  constructor(props: ClientConfiguration) {
    super(props);
    this.supportedVisitTypes = props.supportedVisitTypes;
    this.customerCode = props.customerCode;
    this.logoutDestination = props.logoutDestination;
  }
}
