import {applyMiddleware, combineReducers, createStore, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import configuration, {
  ConfigurationAction,
  ConfigurationState,
} from './reducers/configuration';
import media, {MediaAction, MediaState} from './reducers/media';
import operations, {
  OperationsAction,
  OperationsState,
} from './reducers/operations';

export type ReduxState = {
  configuration: ConfigurationState;
  media: MediaState;
  operations: OperationsState;
};

const reducers = {
  configuration,
  media,
  operations,
};

type Action = ConfigurationAction | MediaAction | OperationsAction;

type AppStore = Store<ReduxState, Action>;

export const getStore = (): AppStore => {
  const appReducers = combineReducers<ReduxState>(reducers);
  const middleware = applyMiddleware(thunk);

  const composeEnhancers = composeWithDevTools({
    maxAge: 50,
  });

  console.info('Setting up Redux...');
  return createStore(appReducers, composeEnhancers(middleware));
};
