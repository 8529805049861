import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {TextField} from '@material-ui/core';
import clsx from 'clsx';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
  forwardRef,
} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputProps} from './Input';

export const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiFormLabel-root': {
      maxWidth: '95%',
    },
  },
  hidden: {
    display: 'none',
  },
}));

type Props = InputProps<string, HTMLInputElement | HTMLTextAreaElement> & {
  autoExpand?: boolean;
  autocomplete?: string;
  endAdornment?: ReactNode | null;
  hidden?: boolean;
  icon?: IconProp;
  lines?: number;
  maxLength?: number;
  noMargin?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  slimline?: boolean;
  startAdornment?: ReactNode | null;
  style?: Object;
  type?: string;
};

export const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoExpand,
      autocomplete = '',
      disabled = false,
      endAdornment,
      error,
      hidden = false,
      inputProps = {},
      instructions = '',
      lines = 1,
      name,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      onKeyDown = () => {},
      onKeyUp = () => {},
      placeholder = '',
      required = false,
      startAdornment,
      style,
      title = '',
      type = 'text',
      value = '',
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <TextField
        ref={ref}
        className={clsx(classes.input, {[classes.hidden]: hidden})}
        style={{fontSize: 16, ...style}}
        inputProps={inputProps}
        error={!!error}
        multiline={lines > 1 || autoExpand}
        fullWidth
        margin="normal"
        label={title}
        required={required}
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder || instructions}
        autoComplete={autocomplete}
        value={value}
        variant="outlined"
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        helperText={typeof error === 'string' ? error : ''}
        InputProps={{startAdornment, endAdornment}}
      />
    );
  },
);
